<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Login</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="row">
                <!-- <div class="col-md-5" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                <div class="col-md-5">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="text-left login">

                        <h4>Username</h4>
                        <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
                            <div *ngIf="username.errors.required">
                                Username is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" formControlName="username" />

                        <h4 class="mt-4">Password</h4>
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                            <div *ngIf="password.errors.required">
                                Password is required.
                            </div>
                        </div>    
                        <input type="password" class="w-100" formControlName="password" />

                        <div class="text-danger mt-4">{{message}}</div>

                        <p class="mt-4 mb-4">
                            <button type="submit" class="btn btn-success btn-round w-100 mb-1" [disabled]="!loginForm.valid">Login</button>
                            <button class="btn btn-primary btn-round w-100 mb-1" routerLink="/register">Create Login</button>
                            <button class="btn btn-secondary btn-round w-100" routerLink="/new-account">Open Water Account</button>
                        </p>

                        <a [routerLink]="[]" (click)="forgotPassword(forgotPasswordTemplate)">Forgot Password</a>
                    </div>
                </div>
                <div class="d-none d-md-block col-md-1"></div>
                <!-- <div class="col-md-6 img-fluid d-none d-md-block" [ngsReveal]="{ delay: 200, viewFactor: 0 }" -->
                <div class="col-md-6 img-fluid d-none d-md-block"
                    style="height: 400px; background-image: url('assets/Login.jpg'); background-position: center; background-size: cover;">
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #forgotPasswordTemplate>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="sendForgotPasswordEmail()">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Forgot Password</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="forgotPasswordModal.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body form-group">
            Enter your username and we'll email you a link to reset your password.
            <h4 class="mt-4">Username</h4>
            <div *ngIf="forgotPasswordUsername.invalid && (forgotPasswordUsername.dirty || forgotPasswordUsername.touched)" class="text-danger">
                <div *ngIf="forgotPasswordUsername.errors.required">
                    Username is required.
                </div>
            </div>
            <input type="text" class="w-100" required formControlName="username" />
        </div>
        <div class="modal-footer">
            <button class="btn btn-outline-secondary btn-round float-right" type="button"
                (click)="forgotPasswordModal.hide()">Cancel</button>
            <button type="submit" class="btn btn-success btn-round float-right" [disabled]="!forgotPasswordForm.valid">Submit</button>
        </div>
    </form>
</ng-template>