import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { AuthService, RoleEnum } from "src/services/auth/auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BsModalService } from "ngx-bootstrap/modal";
import { AddContactComponent } from "../add-contact/add-contact.component";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { BwMatchOtherValidator } from "src/app/bw-controls/bw-match-other-validator";
import { BwDialogService } from "src/app/bw-controls/bw-dialog.service";
import { BwBlankOrMinLengthValidator } from "src/app/bw-controls/bw-blank-or-minlength-validator";
import { ContactService } from "src/services/ApiClient.generated";
import { Subscription, timer } from "rxjs";
import { environment } from "@env/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  RoleEnum = RoleEnum;
  phoneNumbers;
  emails;
  granted: boolean = Notification.permission === 'granted';
  subscription: Subscription;
  readonly NotificationStorageKey: string = "NOTIFICATION_REGISTRATION";

  message: string;

  profileForm = this.fb.group({
    username: ["", [Validators.required, Validators.minLength(4)]],
    password: [
      "",
      {
        validators: [BwBlankOrMinLengthValidator(4)],
      },
    ],
    confirmPassword: [
      "",
      {
        validators: [BwMatchOtherValidator("password")],
      },
    ],
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    paperless: [""],
  });

  constructor(
    public auth: AuthService,
    public http: HttpClient,
    public bsModal: BsModalService,
    public fb: UntypedFormBuilder,
    public bwDialogService: BwDialogService,
    public contactService: ContactService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.profileForm.get("username").setValue(this.auth.username);
    this.profileForm.get("firstName").setValue(this.auth.firstName);
    this.profileForm.get("lastName").setValue(this.auth.lastName);

    if (!this.auth.isStaff) {
      this.profileForm.get("paperless").setValidators(Validators.required);
    }

    if (
      !this.auth.checkRole(RoleEnum.FrontOffice) &&
      !this.auth.checkRole(RoleEnum.Administrator)
    ) {
      this.loadContacts();
      this.loadProfile();
    }

    const self = this;
    this.subscription = timer(0, 2000)
      .subscribe(() => {
        switch(Notification.permission){
            case 'default':
            case 'denied':
                try{
                    self.unregisterWithServer();
                } catch(error){
                    console.error(error);
                }
                break;
            default:
                console.log(`granted: ${self.granted}`)
                self.handlePermissionGranted(true);
                break;
        }
      });
  }

  loadContacts() {
    const url = this.auth.baseUrl + "/contact";

    this.http.get(url).subscribe(
      (data: any) => {
        this.phoneNumbers = data.phoneNumbers;
        this.emails = data.emails;
      },
      (err) => {}
    );
  }

  loadProfile() {
    const url = this.auth.baseUrl + `/user/${this.auth.userId}`;

    this.http.get(url).subscribe(
      (data: any) => {
        this.paperless.setValue(data.paperless);
      },
      (err) => {}
    );
  }

  updateProfile() {
    const url = this.auth.baseUrl + "/user";
    const profile = {
      userId: this.auth.userId,
      username: this.profileForm.get("username").value,
      password: this.profileForm.get("password").value,
      firstName: this.profileForm.get("firstName").value,
      lastName: this.profileForm.get("lastName").value,
      paperless: this.profileForm.get("paperless")?.value,
    };

    this.http.put(url, profile).subscribe(
      () => {
        this.message = "Profile successfully updated!";
        this.auth.username = profile.username;
        this.auth.firstName = profile.firstName;
        this.auth.lastName = profile.lastName;
      },
      (err) => {
        this.message = "An unhandled error has occurred.";
        console.log(err);
      }
    );
  }

  deleteContact(contact) {
    this.bwDialogService
      .showConfirm(
        "Delete Contact?",
        "Are you sure you want to delete this contact?"
      )
      .subscribe((result) => {
        if (result) {
          const url = this.auth.baseUrl + `/contact/${contact.contactId}`;

          this.http.delete(url).subscribe(
            () => {
              this.loadContacts();
            },
            (err) => {
              this.message = "An unhandled error has occurred.";
              console.log(err);
            }
          );
        }
      });
  }

  addContact(contactType) {
    this.bsModal.show(AddContactComponent, {
      initialState: {
        contact: {
          contactType: contactType,
          accountId: this.auth.accountId,
        },
        refresh: () => {
          this.loadContacts();
        },
      },
    });
  }

  editContact(contact) {
    this.bsModal.show(AddContactComponent, {
      initialState: {
        contact: contact,
        refresh: () => {
          this.loadContacts();
        },
      },
    });
  }

  toggleDirectory(contact) {
    contact.inDirectory = !contact.inDirectory;
    this.contactService.updateContact(contact).subscribe();
  }

  unregisterWithServer = () => {
    const self = this;
    if(this.granted){
        this.handlePermissionGranted(false);
        if (window.localStorage.getItem(this.NotificationStorageKey)) {
          console.log(`Unregistering from notifications...`);
          const publicKey: string = window.localStorage.getItem(this.NotificationStorageKey);
          const httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          };
          this.http
            .post(
              `${environment.apiUrl}/webpush/unregister`,
              { 
                publicKey: publicKey,
                userId: this.auth.userId
              },
              httpOptions
            )
            .subscribe(() => {
              console.log("Unregistered from server");
              window.localStorage.removeItem(self.NotificationStorageKey);
            });
        }
    }
  }

  handlePermissionGranted(evt) {
    this.granted = evt;
  }

  get username() {
    return this.profileForm.get("username");
  }
  get password() {
    return this.profileForm.get("password");
  }
  get confirmPassword() {
    return this.profileForm.get("confirmPassword");
  }
  get firstName() {
    return this.profileForm.get("firstName");
  }
  get lastName() {
    return this.profileForm.get("lastName");
  }
  get paperless() {
    return this.profileForm.get("paperless");
  }
}
