<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Reset Password</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <form [formGroup]="registerForm" (ngSubmit)="resetPassword()">
            <div class="row">
                <!-- <div class="col-auto m-auto" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="col-auto m-auto">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                        <div class="text-left login">

                        <h4 class="mt-4">Password</h4>
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                            <div *ngIf="password.errors.required">
                                Password is required.
                            </div>
                            <div *ngIf="password.errors.minlength">
                                Password must be at least 4 characters long.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" required formControlName="password" autocomplete="new-password" />

                        <h4 class="mt-4">Confirm Password</h4>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="text-danger">
                            <div *ngIf="confirmPassword.errors.BwMatchOtherValidator">
                                Password must match.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="mb-4" required formControlName="confirmPassword" autocomplete="new-password" />
                    </div>

                    <div class="mt-4">
                        <div class="text-danger mb-3">{{message}}</div>
                        <button type="submit" class="btn btn-success btn-round" [disabled]="!registerForm.valid">Reset Password</button>
                        <button class="btn btn-secondary btn-round ml-2" routerLink="/login">Return to Login</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>