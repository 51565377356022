import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { AuthService } from '../auth/auth.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export interface IUser {
    userId: number;
    firstName: string;
    lastName: string;
    username: string;
}

export interface IUserList {
    userId: number;
    firstName: string;
    lastName: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        public global: GlobalService,
        public http: HttpClient,
        public auth: AuthService
    ) { }

    getUsers(): Promise<IUserList[]> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/user';

            this.http.get(url).subscribe((users: IUserList[]) => {
                resolve(users);
            }, err => {
                this.global.log(err.title, err.status, url);
                reject(err);
            });
        });
    }

    getUser(userId: number): Promise<IUser> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + `/user/${userId}`;

            this.http.get(url).subscribe((user: IUser) => {
                resolve(user);
            }, err => {
                this.global.log(err.title, err.status, url);
                reject(err);
            });
        });
    }

    getAdmins(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/user/getAdmins';
            this.http.get(url, { params }).subscribe((data: any) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        })
    }

    updateAdmin(admin): Promise<any> {
        const url = this.auth.baseUrl + '/user/updateAdmin';
        return new Promise((resolve, reject) => {
            this.http.put(url, admin).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    updateUser(user) {
        const url = this.auth.baseUrl + '/user';

        return this.http.put(url, user)
            .pipe(
                catchError(err => {
                    this.global.log(err.title, err.status, url);
                    return throwError(err);
                })
            );
    }

    createAdmin(admin): Promise<any> {
        const url = this.auth.baseUrl + '/user/CreateAdmin';
        return new Promise((resolve, reject) => {
            this.http.post(url, admin).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    createUser(user) {
        const url = this.auth.baseUrl + '/user';

        return this.http.post(url, user)
            .pipe(
                catchError(err => {
                    this.global.log(err.title, err.status, url);
                    return throwError(err);
                })
            );
    }

    registerUser(user) {
        const url = this.auth.baseUrl + '/user/registerUser';

        return this.http.post(url, user)
            .pipe(
                catchError(err => {
                    this.global.log(err.title, err.status, url);
                    return throwError(err);
                })
            );
    }

    resetPassword(data) {
        const url = this.auth.baseUrl + '/user/resetPassword';

        return this.http.put(url, data)
            .pipe(
                catchError(err => {
                    this.global.log(err.title, err.status, url);
                    return throwError(err);
                })
            );
    }

    deleteUser(userId: number) {
        return new Promise<void>((resolve, reject) => {
            const url = this.auth.baseUrl + `/user/${userId}`;

            this.http.delete(url).subscribe(data => {
                resolve();
            }, err => {
                this.global.log(err.title, err.status, url);
                reject(err);
            });
        });
    }
}
