import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BwDialogComponent } from './bw-dialog/bw-dialog.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BwDialogService } from './bw-dialog.service';
import { BwConfirmDialogComponent } from './bw-confirm-dialog/bw-confirm-dialog.component';
import { BwAlertDialogComponent } from './bw-alert-dialog/bw-alert-dialog.component';
import { BwInputDebounceComponent } from './bw-input-debounce/bw-input-debounce.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        BwDialogComponent,
        BwConfirmDialogComponent,
        BwAlertDialogComponent,
        BwInputDebounceComponent,
    ],
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        FormsModule
    ],
    exports: [
        BwDialogComponent,
        BwInputDebounceComponent,
    ]
})
export class BwControlsModule {
    static forRoot(): ModuleWithProviders<BwControlsModule> {
        return {
            ngModule: BwControlsModule,
            providers: [
                BwDialogService
            ]
        };
    }
}
