import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { SchedulesRunningDto, ScheduleService } from 'src/services/ApiClient.generated';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    dashboardInfo = {
        nextOrderDeadline: null,
        accountBalance: 0,
        upcomingCycles: 0
    };

    schedulesRunning: SchedulesRunningDto[];

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public scheduleService: ScheduleService
    ) { }

    getDashboardInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/dashboard';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });

            if (this.auth.isLarge) {
                this.scheduleService.getSchedulesRunning().subscribe(data => this.schedulesRunning = data);
            }    
        });
    }
    ngOnInit() {
        this.getDashboardInfo().then((dashboardInfo) => this.dashboardInfo = dashboardInfo).catch(err => console.log(err));
    }
}
