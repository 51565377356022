<section class="section bg-white image-with-text">
    <div class="container">
        <!-- <div class="row" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->
            <div class="row">
            <div class="col-12 col-md-5 mb-4">
                <img src="assets/Vote.jpg" class="img-fluid" />
            </div>
            <div class="col-12 col-md-6">
                <h1>Voting Information</h1>
                <p>
                    On June 7, 2011, the Board of Directors adopted an acreage-based system of voting for the annual
                    election of Directors. The acreage-based system replaced an individual system of voting. Under the
                    acreage-based system, all bona fide owners of land within the District may vote in the District’s
                    annual
                    election of Directors.
                </p>
                <p>
                    Every qualified landowner is entitled to one vote for each acre owned, up to a maximum of 1,280
                    votes,
                    and including fractional votes for lands of less than one-acre (such as condominium owners), not to
                    be
                    less than 0.1 vote. Corporations, partnerships, and other non-individual entities, upon proper
                    application, may designate someone to vote on their behalf. The District Election is held on the
                    second
                    Tuesday after the first Monday in November of each year.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="section bg-gray">
    <div class="container">
        <div class="heading" ngsReveal>
            <h1>Information about voter registration</h1>
            John P. Scremin<br>
            <i class="fas fa-phone mr-2"></i> 480-988-9586<br>
            <a href="mailto:election@rwcd.net"><i class="fas fa-envelope mr-2"></i>election&#64;rwcd.net</a>
        </div>
    </div>
</section>