import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NgsRevealModule, NgsRevealConfig } from 'ngx-scrollreveal';
import { NavComponent } from './nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HomeComponent } from './home/home.component';
import { CustomersComponent } from './customers/customers.component';
import { VotersComponent } from './voters/voters.component';
import { DevelopersComponent } from './developers/developers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FAQsComponent } from './faqs/faqs.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from 'src/services/auth/auth.service';
import { GlobalService } from 'src/services/global/global.service';
import { JwtInterceptorService } from 'src/services/jwt-interceptor/jwt-interceptor.service';
import { UserService } from 'src/services/user/user.service';
import { RegisterComponent } from './register/register.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BwControlsModule } from './bw-controls/bw-controls.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AccountModule } from './account/account.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MapComponent } from './map/map.component';
import { environment } from 'src/environments/environment';
import { API_BASE_URL, ArticleService, SettingService } from '../services/ApiClient.generated'
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MeetingsComponent } from './meetings/meetings.component';
import { DirectorsComponent } from './directors/directors.component';
import { RatesComponent } from './rates/rates.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { ManualComponent } from './manual/manual.component';
import { AuthGuardService } from 'src/services/auth-guard/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { NavAppComponent } from './nav-app/nav-app.component';
import { DatePipe } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ErrorHandlerService } from 'src/services/error-handler/error-handler.service';
import { LoggingService } from 'src/services/logging/logging.service';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        HomeComponent,
        CustomersComponent,
        VotersComponent,
        DevelopersComponent,
        ContactUsComponent,
        AboutUsComponent,
        FAQsComponent,
        FooterComponent,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent,
        MapComponent,
        MeetingsComponent,
        DirectorsComponent,
        RatesComponent,
        NewAccountComponent,
        ManualComponent,
        LogoutComponent,
        NavAppComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        // NgsRevealModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        BwControlsModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        AccountModule,
        AccordionModule,
        TabsModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptorService,
            multi: true
        },
        {
            provide: API_BASE_URL,
            useValue: environment.apiBase
        },
        { 
            provide: ErrorHandler, 
            useClass: ErrorHandlerService 
        },
        HttpClient,
        // NgsRevealConfig,
        AuthService,
        GlobalService,
        JwtInterceptorService,
        UserService,
        ArticleService,
        SettingService,
        AuthGuardService,
        DatePipe,
        LoggingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    // constructor(config: NgsRevealConfig) {
    //     config.origin = 'bottom';
    //     config.distance = '120px';
    //     config.opacity = 0;
    //     config.duration = 500;
    //     config.viewFactor = 0.3;
    // }
    constructor() {}
}
