import { Component, OnInit } from '@angular/core';
import { AuthService, RoleEnum, RoleGroupEnum } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-nav-app',
  templateUrl: './nav-app.component.html',
  styleUrls: ['./nav-app.component.scss']
})
export class NavAppComponent implements OnInit {
  RoleEnum = RoleEnum;
  RoleGroupEnum = RoleGroupEnum;
  date: Date = new Date();

  constructor(
    public auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  doNothing() {
    return false;
  }
}
