<section class="section bg-white">
    <div class="container">
        <h1 class="mb-4 text-center">
            Welcome to RWCD Irrigation!
        </h1>
        <p>Thank you for considering Roosevelt Water Conservation District for your irrigation needs.</p>
        <p class="text-danger">If repairs are needed to the irrigation system or if you are not ready for irrigation, consider waiting to submit your new water account.</p>
        <p>
            To open a water account, please complete the New Water Account Forms by filling in the information on you and your property and clicking the submit button. 
            Once we receive your submittal, the process could take up to five (5) business days. We will contact you regarding payment information and to schedule a 
            time to sign forms and provide an orientation specific to your private irrigation system.
        </p>
        <p>
            Please note upon activation, a one-time $7.00 set up fee will be charged to the account and $7.00 monthly service fee will be applied monthly. 
            If you are reactivating a previously closed account, other fees may apply. For more information, please see our Rates and Fees.
        </p>
        <p>
            Additionally, RWCD is a special tax district and property tax assessments must be paid for the account to open. If you have any questions or concerns, 
            please call us at (480) 988-9581.
        </p>
        <p>
            Happy Irrigating!<br>
            The RWCD Team
        </p>
    </div>
</section>

<section class="section bg-gray">
    <h4 class="container text-center" *ngIf="dataSaved">
        Your data has been saved. We'll be in touch with you soon.
    </h4>
    <div class="container" *ngIf="!dataSaved">
        <form [formGroup]="registerForm" (ngSubmit)="openWaterAccount()">
            <div class="row">
                <!-- <div class="col-12 col-md-6" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                <div class="col-12 col-md-6">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="text-left login">
                        <h3>Personal</h3>
                        <hr>

                        <h4>First Name</h4>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                            <div *ngIf="firstName.errors.required">
                                First Name is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="firstName" />

                        <h4 class="mt-4">Last Name</h4>
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                            <div *ngIf="lastName.errors.required">
                                Last Name is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="lastName" />

                        <h3 class="mt-4">Mailing Address</h3>
                        <hr>

                        <h4>Address</h4>
                        <div *ngIf="address.invalid && (address.dirty || address.touched)" class="text-danger">
                            <div *ngIf="address.errors.required">
                                Address is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="address" />

                        <h4 class="mt-4">City</h4>
                        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="text-danger">
                            <div *ngIf="city.errors.required">
                                City is required.
                            </div>
                            <div *ngIf="city.errors.pattern">
                                City must be numeric.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="city" />

                        <h4 class="mt-4">State</h4>
                        <select class="w-100" formControlName="state">
                            <option *ngFor="let s of states" [value]="s.stateId">{{s.name}}</option>
                        </select>

                        <h4 class="mt-4">Zip Code</h4>
                        <div *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="text-danger">
                            <div *ngIf="zip.errors.required">
                                Zip Code is required.
                            </div>
                            <div *ngIf="zip.errors.pattern">
                                Zip Code must be numeric.
                            </div>
                            <div *ngIf="zip.errors.minlength">
                                Zip Code must be 5 numbers.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="zip" />

                        <h3 class="mt-4">Property Address</h3>
                        <hr>

                        <h4 class="mt-4">Address</h4>
                        <div *ngIf="propertyAddress.invalid && (propertyAddress.dirty || propertyAddress.touched)" class="text-danger">
                            <div *ngIf="propertyAddress.errors.required">
                                Address is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="propertyAddress" />

                        <h4 class="mt-4">City</h4>
                        <div *ngIf="propertyCity.invalid && (propertyCity.dirty || propertyCity.touched)" class="text-danger">
                            <div *ngIf="propertyCity.errors.required">
                                City is required.
                            </div>
                            <div *ngIf="propertyCity.errors.pattern">
                                City must be numeric.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="propertyCity" />

                        <h4 class="mt-4">State</h4>
                        <select class="w-100" formControlName="propertyState">
                            <option *ngFor="let s of states" [value]="s.stateId">{{s.name}}</option>
                        </select>

                        <h4 class="mt-4">Zip Code</h4>
                        <div *ngIf="propertyZip.invalid && (propertyZip.dirty || propertyZip.touched)" class="text-danger">
                            <div *ngIf="propertyZip.errors.required">
                                Zip Code is required.
                            </div>
                            <div *ngIf="propertyZip.errors.pattern">
                                Zip Code must be numeric.
                            </div>
                            <div *ngIf="propertyZip.errors.minlength">
                                Zip Code must be 5 numbers.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="propertyZip" />
                    </div>
                </div>
                <!-- <div class="col-12 col-md-6" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                <div class="col-12 col-md-6">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="text-left login">
                        <h3>Account</h3>
                        <hr>

                        <h4>Account Name</h4>
                        <div *ngIf="accountName.invalid && (accountName.dirty || accountName.touched)" class="text-danger">
                            <div *ngIf="accountName.errors.required">
                                Account Name is required.
                            </div>
                        </div>    
                        <input type="text" maxlength="50" class="w-100" required formControlName="accountName" />

                        <h3 class="mt-4">Login</h3>
                        <hr>

                        <h4>User Name</h4>
                        <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
                            <div *ngIf="username.errors.required">
                                Username is required.
                            </div>
                            <div *ngIf="username.errors.minlength">
                                Username must be at least 4 characters long.
                            </div>
                            <div *ngIf="username.errors.pattern">
                                Username can only contain letters and numbers.
                            </div>
                        </div>    
                        <input type="text" maxlength="50" class="w-100" required formControlName="username" autocomplete="new-password" />

                        <h4 class="mt-4">Password</h4>
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                            <div *ngIf="password.errors.required">
                                Password is required.
                            </div>
                            <div *ngIf="password.errors.minlength">
                                Password must be at least 4 characters long.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="w-100" required formControlName="password" autocomplete="new-password" />

                        <h4 class="mt-4">Confirm Password</h4>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="text-danger">
                            <div *ngIf="confirmPassword.errors.BwMatchOtherValidator">
                                Password must match.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="w-100 mb-4" required formControlName="confirmPassword" autocomplete="new-password" />

                        <h3>Contact</h3>
                        <p>You must enter at least one phone number and one email address.</p>
                        <hr>

                        <h4>Email Addresses</h4>
                        <div *ngIf="emailName1.invalid && (emailName1.dirty || emailName1.touched)" class="text-danger">
                            <div *ngIf="emailName1.errors.required">
                                Email Address is required.
                            </div>
                        </div>

                        <div *ngIf="email1.invalid && (email1.dirty || email1.touched)" class="text-danger">
                            You must enter a valid email address.
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" required formControlName="emailName1" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" required formControlName="email1" placeholder="john@email.com" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="emailName2" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="email2" placeholder="john@email.com" />
                            </div>
                        </div>

                        <h4 class="mt-4">Phone Numbers</h4>

                        <div *ngIf="phoneName1.invalid && (phoneName1.dirty || phoneName1.touched)" class="text-danger">
                            You must enter a phone name.
                        </div>

                        <div *ngIf="phone1.invalid && (phone1.dirty || phone1.touched)" class="text-danger">
                            You must enter a valid phone number.
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" required formControlName="phoneName1" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" required formControlName="phone1" placeholder="999-999-9999" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="phoneName2" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="phone2" placeholder="999-999-9999" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="phoneName3" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="phone3" placeholder="999-999-9999" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="phoneName4" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="phone4" placeholder="999-999-9999" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="phoneName5" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="phone5" placeholder="999-999-9999" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="phoneName6" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="phone6" placeholder="999-999-9999" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-md-6 mb-2">
                                <input type="text" class="w-100" formControlName="phoneName7" placeholder="Name" />
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="w-100" formControlName="phone7" placeholder="999-999-9999" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mt-4">
                        <div class="text-danger mb-3">{{message}}</div>
                        <div class="d-none d-sm-block">
                            <button type="submit" class="btn btn-success btn-round" [disabled]="!registerForm.valid">Open Water Account</button>
                            <button class="btn btn-secondary btn-round ml-2" routerLink="/">Cancel</button>
                        </div>
                        <div class="d-sm-none">
                            <button type="submit" class="btn btn-success btn-round w-100 mb-1" [disabled]="!registerForm.valid">Open Water Account</button>
                            <button class="btn btn-secondary btn-round w-100" routerLink="/">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>