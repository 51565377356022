import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { GlobalService } from 'src/services/global/global.service';
import { BwDialogService } from '../bw-controls/bw-dialog.service';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
  RoleEnum = RoleEnum;
  @ViewChild('file')
  file: ElementRef;

  constructor(
    public auth: AuthService,
    public http: HttpClient,
    public bwDialogService: BwDialogService,
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  updateManual() {
    this.file.nativeElement.click();
  }

  fileChanged(event) {
    const uploadedFile = event.target.files[0];

    const url = this.auth.baseUrl + '/setting/updateManual';

    const formData = new FormData();

    if (uploadedFile) {
      formData.append('file', uploadedFile, uploadedFile.name);

      this.http.post(url, formData)
        .subscribe(event => {
          this.bwDialogService.showAlert('Update', 'Manual has been successfully updated');
        }, error => {
          console.log(error);
          this.bwDialogService.showAlert('Update', 'An error occurred while updating the manual.');
        });
    }

    event.target.value = '';
  }

  openManual() {
    this.globalService.openUrl(`${this.auth.baseUrl}/asset/other/Manual.pdf`, true);
    return false;
  }
}
