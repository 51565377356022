import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'app-add-contact',
    templateUrl: './add-contact.component.html',
    styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
    contactForm = this.fb.group({
        contactId: 0,
        accountId: 0,
        contactType: 0,
        name: [
            '', [
                Validators.required
            ]
        ],
        value: [
            ''
        ]
    });
        
    refresh;
    contact: any = {};
    message;

    constructor(
        public bsModalRef: BsModalRef,
        public auth: AuthService,
        public http: HttpClient,
        public fb: UntypedFormBuilder
    ) { }

    ngOnInit() {
        this.contactForm.get('contactType').setValue(this.contact.contactType);
        this.contactForm.get('contactId').setValue(this.contact.contactId);
        this.contactForm.get('accountId').setValue(this.contact.accountId);
        this.contactForm.get('name').setValue(this.contact.name);
        this.contactForm.get('value').setValue(this.contact.value);

        if(this.contact.contactType == 1) {
            this.contactForm.get('value').setValidators([
                Validators.required,
                Validators.pattern('(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^[0-9]{3}[0-9]{3}[0-9]{4}$)')
            ]);
        }
        else {
            this.contactForm.get('value').setValidators([
                Validators.required,
                Validators.email
            ]);
        }
    }

    get dialogTitle() {
        return `${this.contact.contactId ? 'Edit' : 'Add'} ${this.contactType.value === 1 ? 'Phone Number' : 'Email Address'}`;
    }

    saveContact() {
        if(this.contact.contactId) {
            this.updateContact();
        }
        else {
            this.addContact();
        }
    }

    addContact() {
        const url = this.auth.baseUrl + '/contact';

        this.http.post(url, this.contactForm.value).subscribe(() => {
            this.refresh();
            this.bsModalRef.hide();                
        }, err => {
            this.message = err.error;
            console.log(err);
        });
    }

    updateContact() {
        const url = this.auth.baseUrl + '/contact';

        this.http.put(url, this.contactForm.value).subscribe(() => {
            this.refresh();
            this.bsModalRef.hide();                
        }, err => {
            this.message = err.error;
            console.log(err);
        });
    }

    get contactType() { return this.contactForm.get('contactType'); }
    get name() { return this.contactForm.get('name'); }
    get value() { return this.contactForm.get('value'); }
}
