import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'bw-alert-dialog',
    templateUrl: './bw-alert-dialog.component.html',
    styleUrls: ['./bw-alert-dialog.component.scss']
})
export class BwAlertDialogComponent implements OnInit {

    title: string;
    message: string;

    constructor(
        public bsModalRef: BsModalRef
    ) { }

    ngOnInit() {
    }

}
