<fieldset>
  <legend>Push Notifications</legend>
<div
  class="row pb-4"
  *ngIf="
    auth.checkRole(RoleEnum.Administrator) ||
    (auth.checkRoleGroup(RoleGroupEnum.FieldReports))
  "
>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <p>I want to receive notification from the RWCD field reports.
          Enabling notifications will only apply to the browser you're currently using.
          To receive notifications on other browers, <strong>Enable Notifcations</strong> will need to be
          clicked again.

        Notifications can be disabled in the browser settings for this site.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button type="button" role="button" class="btn btn-primary" id="enable-notifications" (click)="askNotificationsPermission()">Enable Notifications</button>
      </div>
    </div>
  </div>
</div>
</fieldset>