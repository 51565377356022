<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{message}}
</div>
<div class="modal-footer">
    <button class="btn btn-outline-secondary btn-round float-right" type="button" (click)="cancel()">No</button>
    <button class="btn btn-success btn-round float-right" type="button" (click)="confirm()">Yes</button>
</div>