import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { BwDialogService } from '../bw-controls/bw-dialog.service';

@Component({
    selector: 'app-voters',
    templateUrl: './voters.component.html',
    styleUrls: ['./voters.component.scss']
})
export class VotersComponent implements OnInit {

    constructor(
        public auth: AuthService,
        public bwDialogService: BwDialogService,
        public http: HttpClient
    ) { }

    ngOnInit() {
    }
}
