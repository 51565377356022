<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Order Water</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <div class="row no-gutters py-3 sticky-header" style="background: #fff;" *ngIf="!auth.isLarge && allowOrders">
            <button (click)="openModal(bulkEditDialog)" class="btn btn-success btn-round">Bulk Order</button>
            <button class="ml-auto btn btn-success btn-round" (click)="save()" [disabled]="saving || auth.impersonated">Save Orders</button>
        </div>
        <div class="row no-gutters py-3 sticky-header" style="background: #fff;" *ngIf="auth.isLarge && allowOrders">
            <button (click)="openModal(largeOrderDialog)" class="btn btn-success btn-round">Create Order</button>
        </div>
        <article class="col-sm list" *ngIf="!allowOrders">
            <div class="alert alert-danger">
                <h4 *ngIf="!auth.isLarge" class="text-danger text-center">Your account is currently suspended</h4>
                <h4 *ngIf="auth.isLarge" class="text-danger text-center">Your account is currently negative and you cannot place an order</h4>
                <div class="text-danger">
                    Please call the office at 480-988-9581 during normal business hours (Monday - Thursday, 8am - 5pm or Friday, 8am - 12pm)
                    to discuss the status of your account.  Thank you.
                </div>
            </div>
        </article>

        <article class="order list" *ngFor="let orderSchedule of orderSchedules">
            <div class="row order details no-gutters">
                <div class="col-12 col-lg-5">
                    <h5>{{orderSchedule.postDate | date:'longDate'}}</h5>
                    <div *ngIf="orderSchedule.orderId">
                        <p class="text-success">{{orderSchedule.hours}} hour{{orderSchedule.hours !== 1 ? 's' : ''}} {{orderSchedule.minutes}} minutes</p>
                        <p *ngIf="orderSchedule.startDate">{{orderSchedule.startDate | date:'short'}} - {{orderSchedule.stopDate | date:'short'}}</p>
                        <p *ngIf="!orderSchedule.startDate">Schedule will be posted on {{orderSchedule.postDate | date:'longDate'}}</p>
                    </div>
                    <div *ngIf="!orderSchedule.orderId">
                        <p class="text-danger">Not ordered</p>
                        <p *ngIf="orderSchedule.canChangeOrder">Place order by {{orderSchedule.pickUpDate | date:'longDate'}} &#64; 7pm</p>
                        <p *ngIf="!orderSchedule.canChangeOrder">Order date has passed</p>
                    </div>
                </div>

                <div class="col-12 col-lg-2 text-lg-right mt-lg-4">
                    <h3 *ngIf="orderSchedule.amount > 0">{{orderSchedule.amount | currency}}</h3>
                </div>

                <div *ngIf="orderSchedule.canChangeOrder" class="col-12 col-lg-5 d-flex align-items-center m-2 m-lg-0">
                    <button class="btn btn-circle btn-outline-success mr-1 ml-lg-auto" (click)="resetTime(orderSchedule)"><span class="fa fa-trash"></span></button>
                    <div class="btn-group" dropdown #hourDropdown="bs-dropdown" onclick="event.stopPropagation();" >
                        <button class="btn btn-round dropdown-toggle time-button" type="button" [ngClass]="{ 'btn-outline-success': orderSchedule.originalHours == orderSchedule.hours, 'btn-outline-danger': orderSchedule.originalHours != orderSchedule.hours }"
                            dropdownToggle>{{orderSchedule.hours || 0}} Hour{{orderSchedule.hours !== 1 ? 's' : ''}}<span class="caret"></span></button>
                        <ul class="dropdown-menu time-menu" *dropdownMenu role="menu">
                            <li *ngFor="let i of hours"><a href (click)="selectHours(orderSchedule, i); hourDropdown.isOpen = false;" class="dropdown-item">{{i}} {{i == 1 ? 'hour' : 'hours'}}</a></li>
                        </ul>
                    </div>
                    <div class="ml-1 btn-group" dropdown #minuteDropdown="bs-dropdown" onclick="event.stopPropagation();">
                        <button class="btn btn-round dropdown-toggle time-button" type="button" [ngClass]="{ 'btn-outline-success': orderSchedule.originalMinutes == orderSchedule.minutes, 'btn-outline-danger': orderSchedule.originalMinutes != orderSchedule.minutes }"
                            dropdownToggle>{{orderSchedule.minutes || 0}} Minutes<span class="caret"></span></button>
                        <ul class="dropdown-menu" *dropdownMenu role="menu">
                            <li><a href (click)="selectMinutes(orderSchedule, 0); minuteDropdown.isOpen = false;" class="dropdown-item">0 minutes</a>
                            </li>
                            <li><a href (click)="selectMinutes(orderSchedule, 15); minuteDropdown.isOpen = false;" class="dropdown-item">15 minutes</a>
                            </li>
                            <li><a href (click)="selectMinutes(orderSchedule, 30); minuteDropdown.isOpen = false;" class="dropdown-item">30 minutes</a>
                            </li>
                            <li><a href (click)="selectMinutes(orderSchedule, 45); minuteDropdown.isOpen = false;" class="dropdown-item">45 minutes</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row order no-gutters">
                <div class="col-12 show-orders" (click)="toggleOrders(orderSchedule)">
                    <i [ngClass]="orderSchedule.showOrders ? 'fas fa-caret-down' : 'fas fa-caret-right'"></i> 
                    {{orderSchedule.showOrders ? 'Hide Neighborhood Orders' : 'Show Neighborhood Orders'}}
                </div>
                <div class="col-12 orders" *ngIf="orderSchedule.showOrders">
                    <div class="d-none d-lg-block sticky-header" style="top: 0px;">
                        <div class="row no-gutters header sticky-header">
                            <div class="col-2">Account</div>
                            <div class="col-2">Hours</div>
                            <div class="col-4">Name</div>
                        </div>
                    </div>
                    <div class="row no-gutters" [ngClass]="{ 'highlight': order.accountId == auth.accountId }" *ngFor="let order of orderSchedule.orders">
                        <div class="d-none d-lg-block col-2">{{order.accountId}}</div>
                        <div class="d-none d-lg-block col-2">{{order.duration | number:'1.2-2'}}</div>
                        <div class="d-none d-lg-block col-4">{{order.accountName | uppercase}}</div>
                        <div class="col-12 d-lg-none"><h5>{{order.accountName | uppercase}} ({{order.accountId}})</h5></div>
                        <div class="col-12 d-lg-none" *ngIf="order.duration > 0">{{order.duration}} {{order.duration === 1 ? 'hour' : 'hours'}}</div>
                        <div class="col-12 d-lg-none" *ngIf="order.duration == 0">Not Scheduled</div>
                    </div>
                </div>
            </div>
        </article>

        <div *ngFor="let largeOrderSchedule of largeOrderSchedules">
            <h5>{{largeOrderSchedule.orderDate | date:'longDate'}}</h5>
            <p *ngIf="largeOrderSchedule.allowChanges"><i>Changes can be made until {{largeOrderSchedule.changeDate | date:'longDate'}} &#64; 7am</i></p>
            <p *ngIf="!largeOrderSchedule.allowChanges"><i>Changes can no longer be made to these orders</i></p>

            <article class="order list" *ngFor="let order of largeOrderSchedule.orders">
                <div class="row order details no-gutters">
                    <div class="col-12 col-lg-5">
                        <h5>{{order.largeCardId}}: {{order.lateralText}}</h5>
                        <p><b>Head Size:</b> {{order.head}}</p>
                        <p>
                            <b>Duration: </b> 
                            <span *ngIf="order.continuous">Continuous</span>
                            <span *ngIf="!order.continuous">{{order.hours}} hour{{order.hours !== 1 ? 's' : ''}} {{order.minutes}} minutes</span>
                        </p>
                        <p *ngIf="order.comments"><b>Comments:</b> {{order.comments}}</p>
                    </div>

                    <div class="col-12 col-lg-6 text-lg-right mt-lg-4" *ngIf="order.continuous">
                        <h3>{{agricultrualRate | currency}}/AF</h3>
                    </div>
                    
                    <div class="col-12 col-lg-6 text-lg-right mt-lg-4" *ngIf="!order.continuous">
                        <h3 *ngIf="order.amount > 0">{{order.amount | currency}}</h3>
                    </div>

                    <div class="col-12 col-lg-1 text-center">
                        <button [disabled]="!largeOrderSchedule.allowChanges" class="btn btn-circle btn-success" (click)="editLargeOrder(order)">
                            <span class="fa fa-edit"></span>
                        </button><br>
                        <button [disabled]="!largeOrderSchedule.allowChanges" class="btn btn-circle btn-danger mt-1" (click)="deleteLargeOrder(order)">
                            <span class="fa fa-trash"></span>
                        </button>                        
                    </div>
                </div>
            </article>
        </div>

        <div *ngIf="auth.isLarge && largeOrderSchedules.length === 0">
            You do not have any orders.
        </div>
    </div>
</section>

<ng-template #confirmSave>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Unsaved Changes</h4>
    </div>
    <div class="modal-body">
        You have unsaved changes. Are you sure you want to leave without saving?
        <hr>
        <button class="btn btn-outline-secondary btn-round float-right ml-1" (click)="chooseConfirmSave(true)" type="button">Yes</button>
        <button class="btn btn-success btn-round float-right" type="button" (click)="chooseConfirmSave(false)">No</button>
    </div>

</ng-template>

<ng-template #largeOrderDialog>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Order Water</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row no-gutters pt-4">
            <h4 class="col-4">Location</h4>
            <div class="col-8 btn-group" dropdown>
                <button class="btn btn-outline-success btn-round dropdown-toggle" type="button"
                    dropdownToggle>{{largeOrderData.lateralText}}<span class="caret"></span></button>
                <ul class="dropdown-menu" *dropdownMenu role="menu">
                    <li *ngFor="let largeCard of largeCards"><a href (click)="selectLocation(largeCard)" class="dropdown-item">{{largeCard.lateralText}}</a></li>
                </ul>
            </div>
        </div>
        <div class="row no-gutters pt-4">
            <h4 class="col-4">Head Size</h4>
            <div class="col-8 btn-group" dropdown>
                <button class="btn btn-outline-success btn-round dropdown-toggle" type="button"
                    dropdownToggle>{{largeOrderData.head}}<span class="caret"></span></button>
                <ul class="dropdown-menu" *dropdownMenu role="menu">
                    <li *ngFor="let headSize of headSizes"><a href (click)="selectHeadSize(headSize.name)" class="dropdown-item">{{headSize.name}}</a></li>
                </ul>
            </div>
        </div>
        <div class="row no-gutters pt-4">
            <h4 class="col-4">Duration</h4>
            <div class="col-8 form">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="largeOrderData.continuous">
                    <label class="form-check-label">
                        Continuous
                    </label>
                </div>
                <div class="row no-gutters pt-2" *ngIf="!largeOrderData.continuous">
                    <div class="col-2">Hours</div>
                    <div class="col-3"><input type="text" maxlength="3" class="form-control form-control-input" [(ngModel)]="largeOrderData.hours"></div>
                    <div class="col-7">
                        <div class="ml-1 btn-group" dropdown>
                            <button class="btn btn-outline-success btn-round dropdown-toggle time-button" type="button"
                                dropdownToggle>{{largeOrderData.minutes}} Minutes<span class="caret"></span></button>
                            <ul class="dropdown-menu" *dropdownMenu role="menu">
                                <li><a href (click)="selectLargeMinutes(0)" class="dropdown-item">0 minutes</a></li>
                                <li><a href (click)="selectLargeMinutes(15)" class="dropdown-item">15 minutes</a></li>
                                <li><a href (click)="selectLargeMinutes(30)" class="dropdown-item">30 minutes</a></li>
                                <li><a href (click)="selectLargeMinutes(45)" class="dropdown-item">45 minutes</a></li>
                            </ul>
                        </div>        
                    </div>
                    <div class="text-danger ml-3" *ngIf="largeOrderData.hoursError()">{{largeOrderData.hoursError()}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="pt-4 col-12">
                <h4>Comments</h4>
                <textarea class="w-100" [(ngModel)]="largeOrderData.comments"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="pt-4 col-12">
                <h4>Confirmation</h4>
                <p>
                    Place order for water at {{largeOrderData.lateralText}}
                    with a head size of {{largeOrderData.head}}
                    <span *ngIf="largeOrderData.continuous">continuously</span>
                    <span *ngIf="!largeOrderData.continuous">
                        for {{largeOrderData.hours}} hour{{largeOrderData.hours != 1 ? 's' : ''}}
                        <span *ngIf="largeOrderData.minutes > 0">and {{largeOrderData.minutes}} minute{{largeOrderData.minutes != 1 ? 's' : ''}}</span>
                    </span>
                </p>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="largeOrderData.confirmed">
                    <label class="form-check-label">
                        I have confirmed this information is accurate.
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <button class="btn btn-success btn-round float-right ml-1" (click)="saveLargeOrder()" [disabled]="!largeOrderData.isValid()" type="button">Submit</button>
        <button class="btn btn-outline-secondary btn-round float-right" type="button"
            (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>

<ng-template #bulkEditDialog>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulk Update</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Select hours and minutes to apply to each cycle.
        <div class="row">
            <div class="mt-1 col-12 d-flex">
                <div class="btn-group" dropdown>
                    <button class="btn btn-outline-success btn-round dropdown-toggle time-button" type="button"
                        dropdownToggle>{{bulkEditData.hours}} Hour{{bulkEditData.hours !== 1 ? 's' : ''}}<span class="caret"></span></button>
                    <ul class="dropdown-menu time-menu" *dropdownMenu role="menu">
                        <li *ngFor="let i of hours"><a href (click)="selectHours(bulkEditData, i)" class="dropdown-item">{{i}} {{i == 1 ? 'hour' : 'hours'}}</a></li>
                    </ul>
                </div>
                <div class="ml-1 btn-group" dropdown>
                    <button class="btn btn-outline-success btn-round dropdown-toggle time-button" type="button"
                        dropdownToggle>{{bulkEditData.minutes}} Minutes<span class="caret"></span></button>
                    <ul class="dropdown-menu" *dropdownMenu role="menu">
                        <li><a href (click)="selectMinutes(bulkEditData, 0)" class="dropdown-item">0 minutes</a></li>
                        <li><a href (click)="selectMinutes(bulkEditData, 15)" class="dropdown-item">15 minutes</a></li>
                        <li><a href (click)="selectMinutes(bulkEditData, 30)" class="dropdown-item">30 minutes</a></li>
                        <li><a href (click)="selectMinutes(bulkEditData, 45)" class="dropdown-item">45 minutes</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <hr>
        <button class="btn btn-success btn-round float-right ml-1" (click)="bulkUpdate()" type="button">Apply</button>
        <button class="btn btn-outline-secondary btn-round float-right" type="button"
            (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>

<ng-template #saved>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Saved!</h4>
    </div>
    <div class="modal-body">
        <p>Your orders were successfully saved.</p>
        <div class="text-center">
            <button type="button" class="btn btn-success" (click)="dismissMessage()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #largeSaved>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Saved!</h4>
    </div>
    <div class="modal-body">
        <p>Your order was successfully saved.</p>
        <div class="text-center">
            <button type="button" class="btn btn-success" (click)="dismissMessage()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #noChanges>
    <div class="modal-header">
        <h4 class="modal-title pull-left">No Changes!</h4>
    </div>
    <div class="modal-body">
        <p>There are no changes to save.</p>
        <div class="text-center">
            <button type="button" class="btn btn-success" (click)="dismissMessage()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #error>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Error!</h4>
    </div>
    <div class="modal-body">
        <p>An error occurred while updating your orders.</p>
        <div class="text-center">
            <button type="button" class="btn btn-danger" (click)="dismissMessage()">Ok</button>
        </div>
    </div>
</ng-template>