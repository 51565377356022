import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CustomersComponent } from './customers/customers.component';
import { DevelopersComponent } from './developers/developers.component';
import { VotersComponent } from './voters/voters.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FAQsComponent } from './faqs/faqs.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MapComponent } from './map/map.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { DirectorsComponent } from './directors/directors.component';
import { RatesComponent } from './rates/rates.component';
import { ManualComponent } from './manual/manual.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { AuthGuardService } from 'src/services/auth-guard/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'customers', component: CustomersComponent },
    { path: 'meetings', component: MeetingsComponent },
    { path: 'directors', component: DirectorsComponent },
    { path: 'voters', component: VotersComponent },
    { path: 'developers', component: DevelopersComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'faqs', component: FAQsComponent },
    { path: 'rates', component: RatesComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'manual', component: ManualComponent },
    { path: 'new-account', component: NewAccountComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'map', component: MapComponent },
    { path: 'account', loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule), canActivate: [AuthGuardService], data: { role: 'Staff' } },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
      scrollPositionRestoration: 'enabled', 
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100]
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
