<!-- <section class="section bg-gray" [ngsRevealSet]="{ delay: 100, viewFactor: 0 }" [ngsSelector]="'article'" [ngsInterval]="200" -->
<section class="section bg-gray" *ngFor="let year of historyItems">
    <div class="container">
        <div *ngIf="auth.checkRole(RoleEnum.Administrator)">
            <button (click)="add()" class="btn btn-round btn-success">
                <span class="fa fa-plus mr-1"></span>
                Add Entry
            </button>
        </div>
        <!-- <div class="heading" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->
        <div class="heading">
            <h1>{{year.year}}'s</h1>
        </div>
        <article class="history" *ngFor="let h of year.historyItems; let i = index">
            <div class="row">
                <div (click)="showImage(h.imagePath)" class="d-none d-md-block col-12 col-md-4 col-lg-2 img-fluid photo" [ngStyle]="{ 'background-image': 'url(' + auth.baseUrl + '/asset/about-us/' + h.imagePath + ')'}"></div>
                <div (click)="showImage(h.imagePath)" class="d-md-none col-12 img-fluid photo photo-xs mb-2" [ngStyle]="{ 'background-image': 'url(' + auth.baseUrl + '/asset/about-us/' + h.imagePath + ')'}"></div>
                <div class="col-12 col-md-8 col-lg-10">
                    {{h.message}}
                </div>
            </div>
            <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="move">
                <button (click)="moveUp(h)" [disabled]="i == 0" class="btn btn-round btn-sm btn-success mr-1"><span
                        class="fas fa-arrow-up"></span></button>
                <button (click)="moveDown(h)" [disabled]="i == year.historyItems.length - 1"
                    class="btn btn-round btn-sm btn-success"><span class="fas fa-arrow-down"></span></button>
            </div>
            <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                <button (click)="edit(h)" class="btn btn-round btn-sm btn-success mr-1"><span
                        class="fa fa-edit mr-1"></span> Edit</button>
                <button (click)="delete(h)" class="btn btn-round btn-sm btn-danger"><span
                        class="far fa-trash-alt mr-1"></span> Delete</button>
            </div>
        </article>
    </div>
</section>

<ng-template #editItem>
    <form [formGroup]="historyItemForm" (ngSubmit)="saveHistoryItem()">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{dialogTitle}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="historyItemModal.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <h4>Image</h4>
                <input type="file" #file class="w-100" formControlName="image" (change)="fileChanged($event)">
            </div>
            <div>
                <h4>Decade</h4>
                <div *ngIf="year.invalid && (year.dirty || year.touched)" class="text-danger">
                    <div *ngIf="year.errors.required">
                        Year is required.
                    </div>
                    <div *ngIf="year.errors.pattern">
                        Year must be numeric.
                    </div>
                </div>
                <input type="text" class="w-100" formControlName="year">
            </div>
            <div class="pt-4">
                <h4>Message</h4>
                <textarea cols="55" rows="5" formControlName="message"></textarea>
            </div>
            <div class="mt-4">
                <div class="text-danger mb-3">{{message}}</div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-round float-right ml-1" type="submit" [disabled]="!historyItemForm.valid">Save</button>
            <button class="btn btn-outline-secondary btn-round float-right" type="button"
                (click)="historyItemModal.hide()">Cancel</button>
        </div>
    </form>
</ng-template>

<div class="light-box" *ngIf="showLightbox" (click)="hideImage()">
    <button class="btn btn-success btn-sm btn-round image-close" (click)="hideImage()">
        <span class="fa fa-times"></span> Close
    </button>
    <div class="image-frame">
        <img src="{{lightboxImageUrl}}" class="img-fluid" />
    </div>
</div>