import { UntypedFormControl } from '@angular/forms';

export function BwBlankOrMinLengthValidator(minLength: number) {

    let thisControl: UntypedFormControl;

    return function BwBlankOrMinLengthValidate(control: UntypedFormControl) {

        if (!control.parent) {
            return null;
        }

        // Initializing the validator.
        if (!thisControl) {
            thisControl = control;
        }

        if (thisControl.value && thisControl.value.length > 0 && thisControl.value.length < minLength) {
            return {
                BwBlankOrMinLengthValidator: true
            };
        }

        return null;
    }
}