<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Account History</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <!-- <div class="row no-gutters py-3" style="position: relative; z-index: 1;" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->
        <div class="row no-gutters py-3" style="position: relative; z-index: 1;">
            <button class="btn btn-success btn-round" (click)="changeOrder()">{{ascendingOrder ? 'Sort: Ascending' : 'Sort: Descending'}}</button>
            <div class="ml-auto btn-group" dropdown>
                <button class="btn btn-success btn-round dropdown-toggle" type="button" dropdownToggle>{{year}}<span class="caret"></span></button>
                <ul class="dropdown-menu time-menu" *dropdownMenu role="menu">
                    <li *ngFor="let i of years"><a href (click)="selectYear(i)" class="dropdown-item">{{i}}</a></li>
                </ul>
            </div>
        </div>
        <!-- <article class="col-sm list mt-1" *ngIf="!auth.isLarge" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->
        <article class="col-sm list mt-1" *ngIf="!auth.isLarge" style="z-index: 0;">
            <div class="d-none d-lg-block">
                <div class="row no-gutters header sticky-header">
                    <div class="col-2">Date</div>
                    <div class="col-sm">Description</div>
                    <div class="col-2 text-right">Credit</div>
                    <div class="col-2 text-right">Debit</div>
                    <div class="col-2 text-right">Balance</div>
                </div>
            </div>
            <div class="row no-gutters" *ngFor="let transaction of transactions" [ngClass]="{ 'text-success': transaction.amount > 0 && transaction.transactionType !== 'BB', 'text-danger': transaction.amount < 0 }">
                <div class="d-none d-lg-block col-2">{{transaction.transactionDate | date:'shortDate'}}</div>
                <div class="d-none d-lg-block col-sm">
                    {{transaction.description | uppercase}}
                    <div *ngIf="transaction.transactionType === 'WD'" class="water-details">
                        {{transaction.startTime | date:'shortTime'}} - {{transaction.stopTime | date:'shortTime'}} ({{transaction.hours}} hours)<br>
                        Volume: {{transaction.volume}}
                    </div>
                </div>
                <div class="d-none d-lg-block col-2 text-right"><span *ngIf="transaction.amount > 0 && transaction.transactionType !== 'BB'">{{transaction.amount | currency}}</span></div>
                <div class="d-none d-lg-block col-2 text-right"><span *ngIf="transaction.amount < 0">{{transaction.amount | currency}}</span></div>
                <div class="d-none d-lg-block col-2 text-right balance">{{transaction.balance | currency}}</div>

                <div class="col-6 d-lg-none">{{transaction.transactionDate | date:'shortDate'}}</div>
                <div class="col-3 d-lg-none text-right">
                    <span *ngIf="transaction.amount > 0 && transaction.transactionType !== 'BB'">{{transaction.amount | currency}}</span>
                    <span *ngIf="transaction.amount < 0">{{transaction.amount | currency}}</span>
                </div>
                <div class="col-3 d-lg-none text-right balance">{{transaction.balance | currency}}</div>
                <div class="col-12 d-lg-none">
                    {{transaction.description | uppercase}}
                    <div *ngIf="transaction.transactionType === 'WD'" class="water-details">
                        {{transaction.startTime | date:'shortTime'}} - {{transaction.stopTime | date:'shortTime'}} ({{transaction.hours}} hours)<br>
                        Volume: {{transaction.volume}}
                    </div>
                </div>
            </div>
        </article>
        <div *ngIf="auth.isLarge">
            <h4 class="text-right py-2 px-3">Balance: {{balance | currency}}</h4>
            <div *ngFor="let transactionGroup of largeTransactionGroups; first as isFirst">
                <accordion [isAnimated]="false">
                    <accordion-group [isOpen]="isFirst" class="mb-2" #group>
                        <h4 class="d-flex mb-0" accordion-heading>
                            <i [ngClass]="group.isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right'" class="mr-1"></i>
                            <div class="col-sm">{{transactionGroup.title | uppercase }}</div>
                            <div class="col-sm text-right"> {{transactionGroup.acreFeetTotal > 0 ? transactionGroup.acreFeetTotal + '-AF' : '' |  number:'1.2-2'}} </div>
                            <div class="col-sm text-right">{{transactionGroup.subTotal | currency}}</div>
                        </h4>                        
                        <article class="col-sm list mt-1">
                            <div class="d-none d-lg-block">
                                <div class="row no-gutters header">
                                    <div class="col-2">Date</div>
                                    <div class="col-sm">Description</div>
                                    <div class="col-2 text-right">Volume</div>
                                    <div class="col-2 text-right">Rate</div>
                                    <div class="col-2 text-right">Amount</div>
                                </div>
                            </div>
                            <div class="row no-gutters" *ngFor="let transaction of transactionGroup.transactions">
                                <div class="d-none d-lg-block col-2">{{transaction.transactionDate | date:'shortDate'}}</div>
                                <div class="d-none d-lg-block col-sm">
                                    <div *ngIf="transaction.transactionType !== 'WD'">
                                        {{transaction.description | uppercase}}
                                    </div>
                                    <div *ngIf="transaction.transactionType === 'WD'">
                                        {{transaction.startTime | date:'shortTime'}} - {{transaction.stopTime | date:'shortTime'}} ({{transaction.hours}} hours)<br>
                                        Head: {{transaction.head}}
                                    </div>
                                </div>
                                <div class="d-none d-lg-block col-2 text-right">{{transaction.volume |  number:'1.2-2'}}</div>
                                <div class="d-none d-lg-block col-2 text-right">{{transaction.rate | currency}}</div>
                                <div class="d-none d-lg-block col-2 text-right balance">{{transaction.amount | currency}}</div>
                                <div class="col-6 d-lg-none">{{transaction.transactionDate | date:'shortDate'}}</div>
                                <div class="col-3 d-lg-none text-right">{{transaction.volume | number:'1.2-2'}}</div>
                                <div class="col-3 d-lg-none text-right">{{transaction.rate}}</div>
                                <div class="col-3 d-lg-none text-right balance">{{transaction.amount | currency}}</div>
                                <div class="col-12 d-lg-none">
                                    <div *ngIf="transaction.transactionType !== 'WD'">
                                        {{transaction.description | uppercase}}
                                    </div>
                                    <div *ngIf="transaction.transactionType === 'WD'">
                                        {{transaction.startTime | date:'shortTime'}} - {{transaction.stopTime | date:'shortTime'}} ({{transaction.hours}} hours)<br>
                                        Head: {{transaction.head}}
                                    </div>
                                </div>
                            </div>
                        </article>
                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div>
</section>