import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService  {

	constructor(private auth: AuthService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

		if (this.auth.isAuthenticated.value) {
			let roles: string[] = [];
			
			if (Array.isArray(route.data.role)) {
				roles = route.data.role;
			}
			else {
				roles = [route.data.role];
			}

			if (this.auth.isStaff) {
				if (roles.indexOf('Staff') > -1) {
					return true;
				}
				else {
					this.router.navigate(['/admin/dashboard']);
				}
			}
			else {
				if (roles.indexOf('Customer') > -1) {
					return true;
				}
				else {
					this.router.navigate(['/account/dashboard']);
				}
			}

			return false;
		}
		else {
			this.router.navigate(['/login'], { queryParams: { 'returnUrl': state.url } });
			return false;
		}
	}
}
