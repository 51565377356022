import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ScheduleService, SchedulesRunningDto } from 'src/services/ApiClient.generated';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
    boardSchedule = [];

    @ViewChildren('highlights')
    highlights: QueryList<any>;
    schedulesRunning: SchedulesRunningDto[];

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public scheduleService: ScheduleService
    ) { }

    getBoardSchedule(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/board/getScheduleForAccount';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }
    ngOnInit() {
        if (this.auth.isLarge) {
            this.scheduleService.getSchedulesRunning().subscribe(data => this.schedulesRunning = data);
        }
        else {
            this.getBoardSchedule().then((boardSchedule) => this.boardSchedule = boardSchedule);
        }
    }

    showNextRunTime() {
        const targetElement = this.highlights.toArray()[0].nativeElement;
        targetElement.scrollIntoView({ behavior: 'smooth' });
    }
}
