<section class="section bg-white">
    <div class="container text-center">
        <h1 class="pb-5">Videos</h1>

        <div class="row d-flex text-center">
            <div class="col-xl-4 col-md-6 col-sm-12">
                <article class="flex-fill pb-3">
                    <a target="_blank" href="" (click)="openUrl('https://www.youtube.com/watch?v=vwv45NIseIs')">
                        <img width="280" src="https://i.ytimg.com/vi/vwv45NIseIs/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCG-YK1AqkwoU_IchTjUYJX0mqT5Q" />
                    </a>
                    <h4 class="mt-4">Scheduling Water</h4>
                </article>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12">
                <article class="flex-fill pb-3">
                    <a target="_blank" href="" (click)="openUrl('https://www.youtube.com/watch?v=Q3qQQhuTRhM&t=6s')">
                        <img width="280" src="https://i.ytimg.com/vi/Q3qQQhuTRhM/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBpQJUOMm8-wo3WK0WuNtyYcGv1DQ" />
                    </a>
                    <h4 class="mt-4">Ordering Water</h4>
                </article>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12">
                <article class="flex-fill pb-3">
                    <a target="_blank" href="" (click)="openUrl('https://www.youtube.com/watch?v=DVgXZtTf3X8&t=2s')">
                        <img width="280" src="https://i.ytimg.com/vi/DVgXZtTf3X8/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLB_O_CXUHfgDYvuWI1hrBxMBlY_Ug" />
                    </a>
                    <h4 class="mt-4">Setting Up An Account</h4>
                </article>
            </div>
        </div>
    </div>
</section>

<section class="section bg-gray" *ngFor="let faqSection of faqs">
    <div class="container">
        <div *ngIf="auth.checkRole(RoleEnum.Administrator)">
            <button (click)="add()" class="btn btn-round btn-success">
                <span class="fa fa-plus mr-1"></span>
                Add FAQ
            </button>
        </div>
        <div class="heading">
            <h1>{{faqSection.name}}</h1>
        </div>
        <article class="faq" *ngFor="let question of faqSection.questions; let i = index" style="position: relative;">
            <a #questions [attr.id]="question.faqId" style="position: relative; top: -160px;"></a>
            <h3>{{question.title}}</h3>
            <p>{{question.message}}</p>
            <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="move">
                <button (click)="moveUp(question)" [disabled]="i == 0" class="btn btn-round btn-sm btn-success mr-1"><span class="fas fa-arrow-up"></span></button>
                <button (click)="moveDown(question)" [disabled]="i == faqSection.questions.length - 1" class="btn btn-round btn-sm btn-success"><span class="fas fa-arrow-down"></span></button>
            </div>
            <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                <button (click)="edit(question)" class="btn btn-round btn-sm btn-success mr-1"><span class="fa fa-edit mr-1"></span> Edit</button>
                <button (click)="delete(question)" class="btn btn-round btn-sm btn-danger"><span class="far fa-trash-alt mr-1"></span> Delete</button>
            </div>
        </article>
    </div>
</section>

<ng-template #editFaq>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{dialogTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="faqModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <h4>Category</h4>
            <div class="mt-1 col-12 d-flex">
                <div class="btn-group" dropdown>
                    <button class="btn btn-outline-success btn-round dropdown-toggle" type="button"
                        dropdownToggle>{{editQuestion.categoryName}}<span class="caret"></span></button>
                    <ul class="dropdown-menu" *dropdownMenu role="menu">
                        <li><a href="javascript:void(0);" (click)="selectCategory(2)" class="dropdown-item">Billing</a></li>
                        <li><a href="javascript:void(0);" (click)="selectCategory(3)" class="dropdown-item">Scheduling</a></li>
                        <li><a href="javascript:void(0);" (click)="selectCategory(6)" class="dropdown-item">Delivery</a></li>
                        <li><a href="javascript:void(0);" (click)="selectCategory(7)" class="dropdown-item">Miscellaneous</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <h4>Question</h4>
            <input type="text" class="w-100" [(ngModel)]="editQuestion.title">
        </div>
        <div class="pt-4">
            <h4>Answer</h4>
            <textarea cols="55" rows="5" [(ngModel)]="editQuestion.message"></textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-round float-right ml-1" (click)="saveFaq()" type="button">Save</button>
        <button class="btn btn-outline-secondary btn-round float-right" type="button"
            (click)="faqModal.hide()">Cancel</button>
    </div>
</ng-template>