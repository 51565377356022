<section
  class="hero-text d-flex justify-content-center align-items-center"
  [ngStyle]="{ padding: (auth.isAuthenticated | async) ? '20px 0' : '50px 0' }"
  [ngClass]="{ 'hero-text-background': auth.menuOpen }"
>
  <div>
    <p>
      <img src="assets/WaterTank.png" style="width: 200px; height: 195px" />
    </p>
    <p>
      <img
        src="assets/RwcdText.png"
        style="width: 80%; max-width: 750px; margin-top: 10px"
      />
    </p>
    <h3>est. 1920</h3>
  </div>
</section>

<section class="section">
  <div class="container carousel">
    <carousel *ngIf="homeData.slideshows">
      <slide ngbSlide *ngFor="let slideshow of homeData.slideshows">
        <figure
          [ngStyle]="{
            'background-image':
              'url(' +
              auth.baseUrl +
              '/asset/slideshow/' +
              slideshow.imagePath +
              ')'
          }"
        ></figure>
        <div
          class="carousel-caption"
          *ngIf="slideshow.title || slideshow.subtitle"
        >
          <h2>{{ slideshow.title }}</h2>
          {{ slideshow.subtitle }}
        </div>
      </slide>
    </carousel>
  </div>
</section>

<section class="section bg-white">
  <div class="container">
    <div class="heading">
      <h1>
        What's New?
        <button
          (click)="add()"
          class="btn btn-circle btn-success"
          *ngIf="auth.checkRole(RoleEnum.Administrator)"
        >
          <span class="fa fa-plus"></span>
        </button>
      </h1>
      Check back regularly to learn more about upcoming events and
      announcements.
    </div>

    <div class="row">
      <div
        class="col-lg-4 mb-4 d-flex"
        *ngFor="let article of homeData.articles"
      >
        <article class="d-flex flex-column flex-fill">
          <h3>{{ article.subject }}</h3>
          <p class="flex-fill mt-2">{{ article.body }}</p>
          <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
            <button
              (click)="edit(article)"
              class="btn btn-circle btn-sm btn-success mr-1"
            >
              <span class="fa fa-edit"></span>
            </button>
            <button
              (click)="delete(article)"
              class="btn btn-circle btn-sm btn-danger"
            >
              <span class="far fa-trash-alt"></span>
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>

<!-- <section class="section bg-blue quote">
    <div class="container">
        <div class="heading">
            <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                <button (click)="editQuoteText()" class="btn btn-circle btn-sm btn-success mr-1"><span
                        class="fa fa-edit"></span> Edit</button>
            </div>
            <h3 class="text-left">{{homeQuoteTextPrint}}</h3>
            <h3 class="text-right mt-5" *ngIf="homeQuoteAuthorPrint">
                {{homeQuoteAuthorPrint}}
            </h3>
        </div>
    </div>
</section> -->

<section class="section bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 text-center" id="fb-root">
        <!-- <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frwcdaz&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=314716318637722"
                    width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                    allowTransparency="true" allow="encrypted-media"></iframe> -->
            <div class="fb-page" data-href="https://www.facebook.com/rwcdaz" data-tabs="timeline" data-width="300" data-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/rwcdaz" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/rwcdaz">Roosevelt Water Conservation District - RWCD</a></blockquote></div>
      </div>
      <div class="col-xs-12 col-md-6 mt-4 mt-md-0">
        <h1>Follow Us On Facebook</h1>

        Keep in touch with what's happening by following us on Facebook. You'll
        receive regular information on things such as:
        <ul>
          <li>Outages</li>
          <li>Community Events</li>
          <li>Historical Information</li>
          <li>Fun Trivia</li>
          <li>And much, much more!</li>
        </ul>
        <a
          class="btn btn-success btn-round"
          href="http://www.facebook.com/rwcdaz"
          target="_blank"
          >Open Facebook Page</a
        >
      </div>
    </div>
  </div>
</section>

<ng-template #articleTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ dialogTitle }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="articleModal.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <h4>Post Date</h4>
        <input
          type="text"
          class="w-100"
          [(ngModel)]="editArticle.postDate"
          bsDatepicker
        />
      </div>
      <div class="col-6">
        <timepicker
          class="w-100"
          [(ngModel)]="editArticle.postTime"
        ></timepicker>
      </div>
    </div>
    <div class="pt-4">
      <h4>Subject</h4>
      <input type="text" class="w-100" [(ngModel)]="editArticle.subject" />
    </div>
    <div class="pt-4">
      <h4>Body</h4>
      <textarea cols="55" rows="5" [(ngModel)]="editArticle.body"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-success btn-round float-right ml-1"
      (click)="saveArticle()"
      type="button"
    >
      Save
    </button>
    <button
      class="btn btn-outline-secondary btn-round float-right"
      type="button"
      (click)="articleModal.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #quoteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Quote</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="quoteModal.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="pt-4">
      <h4>Quote</h4>
      <textarea cols="55" rows="5" [(ngModel)]="editQuote.text"></textarea>
    </div>
    <div class="pt-4">
      <h4>Author</h4>
      <input type="text" class="w-100" [(ngModel)]="editQuote.author" />
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-success btn-round float-right ml-1"
      (click)="saveQuoteText()"
      type="button"
    >
      Save
    </button>
    <button
      class="btn btn-outline-secondary btn-round float-right"
      type="button"
      (click)="quoteModal.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>
