<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>{{auth.isLarge ? "Heads Running" : "Current Schedule"}}</h2>
</section>

<section class="section bg-white">
    <div class="container" *ngIf="!auth.isLarge">
        <button class="btn btn-round btn-success mb-2" (click)="showNextRunTime()">Show Next Run Time</button>
        <!-- <article class="col-sm list" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
            <article class="col-sm list">
            <div class="d-none d-lg-block">
                <div class="row no-gutters header sticky-header">
                    <div class="col-2">Account</div>
                    <div class="col-2">Hours</div>
                    <div class="col-4">Name</div>
                    <div class="col-2">Start</div>
                    <div class="col-2">Stop</div>
                </div>
            </div>
            <div class="row no-gutters" [ngClass]="{ 'highlight': schedule.accountId == auth.accountId }" *ngFor="let schedule of boardSchedule">
                <a #highlights *ngIf="schedule.accountId == auth.accountId" style="position: relative; top: -205px;"></a>
                <div class="d-none d-lg-block col-2">{{schedule.accountId}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.duration | number:'1.2-2'}}</div>
                <div class="d-none d-lg-block col-4">{{schedule.accountName | uppercase}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.startTime | date:'short'}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.stopTime | date:'short'}}</div>
                <div class="col-12 d-lg-none"><h5>{{schedule.accountName | uppercase}} ({{schedule.accountId}})</h5></div>
                <div class="col-12 d-lg-none" *ngIf="schedule.startTime">{{schedule.startTime | date:'short'}} to {{schedule.stopTime | date:'short'}}</div>
                <div class="col-12 d-lg-none" *ngIf="schedule.startTime">{{schedule.duration}} {{schedule.duration === 1 ? 'hour' : 'hours'}}</div>
                <div class="col-12 d-lg-none" *ngIf="!schedule.startTime">Not Scheduled</div>
            </div>
        </article>
    </div>
    <div class="container" *ngIf="auth.isLarge">
        <!-- <article class="col-sm list" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
        <article class="col-sm list">
            <div class="d-none d-lg-block">
                <div class="row no-gutters header sticky-header">
                    <div class="col-4">Location</div>
                    <div class="col-2">Head</div>
                    <div class="col-2">Hours</div>
                    <div class="col-2">Start</div>
                    <div class="col-2">Stop</div>
                </div>
            </div>
            <div class="row no-gutters" *ngFor="let schedule of schedulesRunning">
                <div class="d-none d-lg-block col-4">{{schedule.largeCardId}}: {{schedule.lateralText}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.head}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.duration}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.startTime | date:'shortTime'}}</div>
                <div class="d-none d-lg-block col-2">{{schedule.stopTime | date:'shortTime'}}</div>
            </div>
            <div *ngIf="schedulesRunning && schedulesRunning.length === 0" class="p-2">
                No heads are currently running
            </div>
        </article>
    </div>
</section>