import { Component, OnInit, HostListener, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    RoleEnum = RoleEnum;
    sticky = false;

    isCollapsed = true;
    isAccountCollapsed = true;
    isInAccount = new BehaviorSubject<boolean>(false);
    modalRef: BsModalRef;

    constructor(
        public router: Router,
        public modalService: BsModalService,
        public auth: AuthService
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isCollapsed = true;
                this.isAccountCollapsed = true;

                this.isInAccount.next(event.url.indexOf('account') > -1 || event.url.indexOf('admin') > -1);
            }
        });
    }

    ngOnInit() {
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        if (window.pageYOffset > 0) {
            this.sticky = true;
        } else {
            this.sticky = false;
        }
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    dismissMessage() {
        this.modalRef.hide();
    }

    doNothing() {
        return false;
    }

    toggleMenu() {
        this.isCollapsed = !this.isCollapsed;
        this.auth.menuOpen = !this.auth.menuOpen;
    }
}