import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject = new BehaviorSubject<any>(null);

    constructor(public auth: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(this.addAuthToken(request)).pipe(
            catchError((error) => {
                // We don't want to refresh token for some requests like login or refresh token itself
                // So we verify url and we throw an error if it's the case
                if (
                    request.url.includes("refreshAuthToken") ||
                    request.url.includes("login") ||
                    request.url.includes("register")
                ) {
                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page

                    if (request.url.includes("refreshAuthToken")) {
                        this.auth.logout();
                    }

                    return throwError(error);
                }

                // If error status is different than 401 we want to skip refresh token
                // So we check that and throw the error if it's the case
                if (error.status !== 401) {
                    return throwError(error);
                }

                if (this.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthToken(request)))
                    );
                } else {
                    this.refreshTokenInProgress = true;

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    // Call auth.refreshAccessToken(this is an Observable that will be returned)
                    return this.auth.refreshAuthToken().pipe(
                        switchMap((token: any) => {
                            //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                            // for the next time the token needs to be refreshed
                            this.refreshTokenInProgress = false;
                            this.refreshTokenSubject.next(token);

                            return next.handle(this.addAuthToken(request));
                        }),
                        catchError((err: any) => {
                            this.refreshTokenInProgress = false;

                            this.auth.logout();
                            return throwError(error);
                        })
                    );
                }
            })
        );
    }

    addAuthToken(request) {
        if (!this.auth.authToken) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.authToken}`
            }
        });
    }
}
