<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Dashboard</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <!-- <div class="row" [ngsRevealSet]="{ delay: 200, viewFactor: 0 }" [ngsSelector]="'article'" [ngsInterval]="200"> -->
        <div class="row">
            <div class="col-12 col-xl-8">
                <div class="row">
                    <div class="col-12 col-md-6 mb-4">
                        <article class="photo">
                            <div class="image">
                                <img class="img-fluid" src="assets/Order.jpg">
                                <h4>{{auth.isLarge ? "Order Water" : "Next Order Deadline"}}</h4>
                            </div>
                            <h4 class="mt-4" *ngIf="!auth.isLarge">{{dashboardInfo.nextOrderDeadline | date:'mediumDate'}} &#64; 7pm</h4>
                            <div class="text-center mt-4">
                                <button class="btn btn-round btn-outline-success" routerLink="/account/order"><i class="fas fa-shopping-cart mr-1"></i> Order Water</button>
                            </div>
                        </article>
                    </div>
                    <!--<div class="col-12 col-md-6 mb-4">-->
                        <div class="col-12 col-md-6 mb-4" *ngIf="!auth.isLarge">
                        <article class="photo">
                            <div class="image">
                                <img class="img-fluid" src="assets/Balance.jpg">
                                <h4>Estimated Account Balance</h4>
                            </div>
                            <h4 class="mt-4">{{dashboardInfo.accountBalance | currency}}</h4>
                            <div class="text-center mt-4">
                                <button class="btn btn-round btn-outline-success" routerLink="/account/payment"><i class="fas fa-credit-card mr-1"></i> Make a Payment</button>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <article class="photo">
                            <div class="image">
                                <img class="img-fluid" src="assets/Account.jpg">
                                <h4>Account History</h4>
                            </div>
                            <div class="text-center mt-4">
                                <button class="btn btn-round btn-outline-success" routerLink="/account/history"><i class="fas fa-history mr-1"></i> Account History</button>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <article class="photo">
                            <div class="image">
                                <img class="img-fluid" src="assets/Profile.jpg">
                                <h4>Update Profile</h4>
                            </div>
                            <div class="text-center mt-4">
                                <button class="btn btn-round btn-outline-success" routerLink="/account/profile"><i class="fas fa-user mr-1"></i> Update Profile</button>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-4">
                <article class="cycles">
                    <h4>{{auth.isLarge ? "Heads Running" : "Upcoming Cycles"}}</h4>

                    <div *ngIf="auth.isLarge">
                        <div style="line-height: 1.4rem;" class="mt-2 mb-4" *ngFor="let schedule of schedulesRunning">
                            <span style="font-weight: 900;">{{schedule.largeCardId}}: {{schedule.lateralText}}</span><br>
                            Head:  {{schedule.head}}<br>
                            Hours: {{schedule.duration}}<br>  
                            Start:  {{schedule.startTime | date:'shortTime'}}<br> 
                            Stop:  {{schedule.stopTime | date:'shortTime'}}<br>         
                        </div>
                        <div *ngIf="schedulesRunning && schedulesRunning.length === 0" class="py-2">
                            No heads are currently running
                        </div>            
                    </div>

                    <div class="info" *ngFor="let upcomingCycle of dashboardInfo.upcomingCycles">
                        <h6>{{upcomingCycle.postDate | date:'longDate'}}</h6>
                        <div *ngIf="upcomingCycle.orderId">
                            <p class="text-success">{{upcomingCycle.hours}} hours {{upcomingCycle.minutes}} minutes</p>
                            <p *ngIf="upcomingCycle.startDate">{{upcomingCycle.startDate | date:'short'}} - {{upcomingCycle.stopDate | date:'short'}}</p>
                            <p *ngIf="!upcomingCycle.startDate">Schedule posted on {{upcomingCycle.postDate | date:'mediumDate'}}</p>
                            </div>
                        <div *ngIf="!upcomingCycle.orderId">
                            <p class="text-danger">Not ordered</p>
                            <p *ngIf="upcomingCycle.canChangeOrder">Place order by {{upcomingCycle.pickUpDate | date:'mediumDate'}} &#64; 7pm</p>
                            <p *ngIf="!upcomingCycle.canChangeOrder">Order date has passed</p>
                            </div>
                    </div>

                    <div class="info">
                        <button class="btn btn-outline-success btn-round mb-2 mr-2" routerLink="/account/schedule">{{auth.isLarge ? "More Info" : "View Current Schedule"}}</button>
                        <button class="btn btn-outline-success btn-round mb-2" routerLink="/account/order" *ngIf="!auth.isLarge">Order Water</button>
                    </div>
                </article>
            </div>
        </div>
    </div>
</section>