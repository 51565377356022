import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

interface LogItem {
    message: string,
    code: number,
    url: string
}

export const LOADING_MESSAGE = 'Loading...';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
    apiUrl = environment.apiUrl;
    messages: LogItem[] = [];

    constructor(
    ) { }

    handleError(error: string): void {
        this.log(error);
        this.showMessage('Error occurred', error);
    }

    log(message: string, code?: number, url?: string): void {
        this.messages.push({
            message,
            code,
            url
        });
    }

    showMessage(title: string, message: string) {
        console.log(title, message);
    }

    startLoading() {
        console.log('Loading...');
    }

    stopLoading() {
        console.log('Done loading');
    }

    openUrl(url: string, newWindow: boolean = false) {
        if ((window as any).invokeCSharpAction) {
            location.href = url;
            // const invokeData = {
            //     invokeFunction: 'OpenWindow',
            //     data: {
            //         url: url
            //     }
            // };
            // (window as any).invokeCSharpAction(JSON.stringify(invokeData));
        }
        else {
            if(newWindow) {
                window.open(url, '_blank');
            }
            else {
                location.href = url;
            }
        }
    }

    openMap(address, city, state, zip, newWindow)
    {
        if ((window as any).invokeCSharpAction) {
            const url = `https://maps.google.com?address=${address.toUpperCase()}&city=${city.toUpperCase()}&state=${state.toUpperCase()}&zip=${zip.toUpperCase()}`;

            location.href = url;
        }
        else {
            const encodedAddress = encodeURIComponent(`${address.toUpperCase()}, ${city.toUpperCase()}, ${state.toUpperCase()} ${zip.toUpperCase()}`);
            const url = 'https://maps.google.com/maps?q=' + encodedAddress;

            if(newWindow) {
                window.open(url, '_blank');
            }
            else {
                location.href = url;
            }
        }

    }
}