<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Board Information</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <!-- <article class="col-sm photo-info cycles" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
        <article class="col-sm photo-info cycles">
            <div class="row no-gutters">
                <div class="col-xs-12 col-sm-6 col-lg-4 px-4">
                    <div class="info">
                        <h5>{{boardInfo.name | uppercase}}</h5>
                        <p>{{boardInfo.number | uppercase}}</p>
                    </div>
                    <div class="info">
                        <h5>HEAD SIZE*</h5>
                        <div>{{boardInfo.head}} M.I. ({{boardInfo.hourlyRate | currency}}/hr)</div>
                    </div>
                    <div class="fine-print mt-4">
                        <p>*If you intend to modify your private irrigation system, please call us.</p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-4 px-4 pb-4">
                    <div class="info">
                        <h5>ACCOUNT #</h5>
                        <p>{{boardInfo.accountId}}</p>
                    </div>
                    <div class="info">
                        <h5>GATE NUMBER</h5>
                        <p>{{boardInfo.gate | uppercase}}</p>
                    </div>
                    <div class="info">
                        <a class="btn btn-outline-success btn-round" href="" (click)="openMap(boardInfo)">View Map</a>
                        <a class="btn btn-outline-success btn-round ml-2" routerLink="/directory">Directory</a>
                    </div>
                </div>
                <div class="col-xs-12 col-lg-4 d-none d-lg-block" style="background-image: url('assets/BoardBox.jpg'); background-size: cover;">
                </div>
            </div>
        </article>
    </div>
</section>

<section class="section bg-gray">
    <!-- <div class="container" [ngsReveal]="{ delay: 400, viewFactor: 0 }"> -->
    <div class="container">
        <div class="heading">
            <h1>Board Calendar</h1>
        </div>
        <div class="row no-gutters">
            <button class="btn btn-round btn-success mb-2" (click)="toggle()">{{ hidden ? 'Show' : 'Hide' }} Previous</button>
            <button class="ml-auto btn btn-circle btn-success mb-2 d-none" (click)="print()"><span class="fa fa-print"></span></button>
        </div>
        <article class="col-sm list">
            <div class="row no-gutters header sticky-header">
                <div class="col-4 text-center">Pick Up Sheets</div>
                <div class="col-4 text-center">Post Schedule</div>
                <div class="col-4 text-center">First Run</div>
            </div>
            <div class="row no-gutters" [ngClass]="{ 'highlight': schedule.current, 'd-none': hidden && !schedule.current && !schedule.future }" *ngFor="let schedule of boardInfo.schedules">
                <div class="col-4 text-center">{{schedule.pickUpDate | date:'shortDate'}}</div>
                <div class="col-4 text-center">{{schedule.postDate | date:'shortDate'}}</div>
                <div class="col-4 text-center">{{schedule.firstRunDate | date:'shortDate'}}</div>
            </div>
        </article>
    </div>
</section>

<textarea class="d-none" #calendarCss>
    table {
        font-family: Cambria;
        font-size: 12pt;
        font-weight: bold;
        width: 100%;
    }

    .heading1 {
        font-size: 13pt;
    }

    .heading2 {
        font-size: 12pt;
    }

    .heading3 {
        font-size: 11pt;
        text-decoration: underline;
    }

    img {
        width: 75px;
        height: 75px;
    }

    .center {
        text-align: center;
    }

    .top {
        vertical-align: top;
    }

    .bottom {
        vertical-align: bottom;
    }

    .notes {
        font-size: 10pt;
    }

    @page {
        size: auto; /* auto is the initial value */
        margin: 0mm; /* this affects the margin in the printer settings */
    }

    html {
        margin: 0px; /* this affects the margin on the HTML before sending to printer */
    }

    body {
        margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
    }

    .footer {
        position: fixed;
        bottom: 8mm;
        left: 15mm;
        width: 88%;
    }

</textarea>

<div class="d-none" #calendarHtml>
    <table>
        <tr>
            <td style="width: 40%;" class="notes top">
                ROOSEVELT WATER CONSERVATION DISTRICT<br>
                PO BOX 100<br>
                HIGLEY, AZ 85236-0100
            </td>
            <td style="width: 20%;" class="center top">
                <img src="assets/WaterTank.png">
            </td>
            <td style="width: 40%;" class="center notes">
                <span class="heading1">ROOSEVELT WATER CONSERVATION DISTRICT</span><br>
                Main Office located at 6937 East Elliot Road (East of Power Road on the South Side of Elliot Road) Office Hours: 8:00 a.m. ‐ 5:00 p.m., Monday ‐ Friday
                <hr>
                Please call the Water Department at (480) 988‐9581 during normal office hours for account information.
                <hr>
            </td>
        </tr>
        <tr>
            <td class="notes bottom">
                GORDON E & CHRISTEE A RAY<br>
                2243 E CLAXTON AVE<br>
                GILBERT AZ 85297‐1125<br>
                &nbsp;
            </td>
            <td></td>
            <td class="center notes">
                Please call the Zanjero Department at (480) 988‐9581 to report water delivery problems or property flooding. RWCD employees answer this telephone number 24 hours per day, 365 days per year to assist users.
                <hr>
            </td>
        </tr>
        <tr>
            <td colspan="3" class="center">
                <span class="heading1">** DECEMBER IRRIGATION DATES MAY CHANGE BASED UPON THE START OF THE 2020 ‐ 2021 MAINTENANCE DRY‐UP **</span>
                <hr>
                <span class="heading2">** CALENDAR OF 2020 PICK‐UP, POST, AND FIRST RUN DATES **</span>
                <hr>
            </td>
        </tr>
        <tr>
            <td class="center">ACCOUNT: 81</td>
            <td class="center">BOARD: 404-A-1</td>
            <td class="center">CYCLE: 11</td>
        </tr>
        <tr>
            <td colspan="3">
                <hr>
            </td>
        </tr>
        <tr>
            <td class="center heading3">Pick-Up Sheets</td>
            <td class="center heading3">Post Schedule</td>
            <td class="center heading3">First Run</td>
        </tr>
        <tr *ngFor="let schedule of boardInfo.schedules">
            <td class="center notes">{{schedule.pickUpDate | date:'MM/dd/yy'}}</td>
            <td class="center notes">{{schedule.postDate | date:'MM/dd/yy'}}</td>
            <td class="center notes">{{schedule.firstRunDate | date:'MM/dd/yy'}}</td>
        </tr>
    </table>
    <table class="footer notes">
        <tr>
            <td colspan="3">
                <hr>
            </td>
        </tr>
        <tr>
            <td class="center">{{currentDate | date:'short'}}</td>
            <td class="center">YOU MAY ORDER WATER ONLINE AT WWW.RWCD.NET</td>
            <td class="center">IMPORTANT NOTICE</td>
        </tr>            
    </table>
</div>

<iframe class="d-none" #printCalendar>
</iframe>