import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BwAlertDialogComponent } from './bw-alert-dialog/bw-alert-dialog.component';
import { BwConfirmDialogComponent } from './bw-confirm-dialog/bw-confirm-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BwDialogService {

    constructor(
        public bsModalService: BsModalService
    ) { }

    showAlert(title: string, message: string) {
        this.bsModalService.show(BwAlertDialogComponent, { initialState: { title, message } });
    }

    showConfirm(title: string, message: string) : Subject<boolean> {
        const onClose = new Subject<boolean>();
        
        const bsModalRef = this.bsModalService.show(BwConfirmDialogComponent, { initialState: { title, message } });
        (bsModalRef.content as BwConfirmDialogComponent).onClose.subscribe(result => {
            onClose.next(result);
        });

        return onClose;
    }
}
