import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { SwPush } from "@angular/service-worker";
import { environment } from "@env/environment";
import {
  AuthService,
  RoleEnum,
  RoleGroupEnum,
} from "src/services/auth/auth.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  RoleEnum = RoleEnum;
  RoleGroupEnum = RoleGroupEnum;
  private readonly VapidPublicKey: string =
    "BKtiYJco2eM-UYmlqHIty7TkkIwA-LsZTIhS86w1sp5rz5yvAmMHEBmfTVvvZC-bcIUsLGMygu_4jFi11Lwa5XQ";
  readonly NotificationStorageKey: string = "NOTIFICATION_REGISTRATION";
  //@Output() notifyPermissionGranted = new EventEmitter<boolean>();

  constructor(
    readonly auth: AuthService,
    readonly http: HttpClient,
    private swPush: SwPush
  ) {  }

  ngOnInit() {}

  // Ask for permission when the
  askNotificationsPermission = () => {
    const self = this;
    switch(Notification.permission) {
      case 'default':
        Notification.requestPermission()
        .then(() => {
          if (self.swPush && self.swPush.isEnabled) {
            self.swPush
              .requestSubscription({ serverPublicKey: self.VapidPublicKey })
              .then(sub => {
                console.log("register with server");
                self.saveRegistrationToLocalStore(sub.toJSON());
                const httpOptions = {
                  headers: new HttpHeaders({
                    "Content-Type": "application/json",
                  }),
                };
                const jsonObj = JSON.stringify({ subscription: sub });
                console.log(`posting to server to register...`);
                self.http
                  .post(`${environment.apiUrl}/webpush/register`, jsonObj, httpOptions)
                  .subscribe((response) => {
                    console.log(`server registration response... ${response}`);
                  });
              })
              .catch((err) =>
                console.error("requestSubscription failed due to: ", err)
              );
          } else {
            console.error("SwPush is not enabled or already subscribed");
          }
        })
        .catch(err => console.error('Request Permission failed due to ', err));
        break;
      case 'denied':
        console.log('Notification permission denied.');
        break;
      default: // already granted
        const notification = new Notification("RWCD", { body: "You're already subscribed!", icon: "/assets/icons/icon-72x72.png"});
        // register with server again
        this.swPush.subscription.subscribe(sub => this.sendRegistrationToServer(sub));
        break;
    }
  };

  requestSubscription = () => {
    if (this.swPush && this.swPush.isEnabled) {
      this.swPush
        .requestSubscription({ serverPublicKey: this.VapidPublicKey })
        .then()
        .catch((err) =>
          console.error("requestSubscription failed due to: ", err)
        );
    } else {
      console.error("SwPush is not enabled or already subscribed");
    }
  };

  sendRegistrationToServer = (sub: PushSubscription) => {
    console.log("register with server");
    this.saveRegistrationToLocalStore(sub.toJSON());
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    const jsonObj = JSON.stringify({ subscription: sub });
    console.log(`posting to server to register...`);
    this.http
      .post(`${environment.apiUrl}/webpush/register`, jsonObj, httpOptions)
      .subscribe((response) => {
        console.log(`server registration response... ${response}`);
      });
  };

  saveRegistrationToLocalStore = (sub: PushSubscriptionJSON) => {
    console.log(`saving registration to local store...`);
    window.localStorage.setItem(this.NotificationStorageKey, sub.keys.p256dh);
  };
}