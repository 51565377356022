import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BwMatchOtherValidator } from '../bw-controls/bw-match-other-validator';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user/user.service';
import { catchError, first } from 'rxjs/operators';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    message: string;

    registerForm = this.fb.group({
        username: [
            '', [
                Validators.required,
                Validators.minLength(4)
            ]
        ],
        password: [
            '', {
                validators: [
                    Validators.required,
                    Validators.minLength(4)
                ],
            }
        ],
        confirmPassword: [
            '', {
                validators: [
                    Validators.required,
                    Validators.minLength(4),
                    BwMatchOtherValidator('password')
                ],
            }
        ],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', Validators.required],
        streetNumber: [
            '', [
                Validators.required,
                Validators.pattern('[0-9]*')
            ]
        ],
        zipCode: [
            '', [
                Validators.required,
                Validators.pattern('[0-9]*'),
                Validators.minLength(5)
            ]
        ],
        accountNumber: [
            '', [
                Validators.required,
                Validators.pattern('[0-9]*')
            ]
        ],
    });

    constructor(
        public fb: UntypedFormBuilder,
        public auth: AuthService,
        public userService: UserService,
        public router: Router
    ) { }

    ngOnInit() {
    }
    
    get username() { return this.registerForm.get('username'); }
    get password() { return this.registerForm.get('password'); }
    get confirmPassword() { return this.registerForm.get('confirmPassword'); }
    get firstName() { return this.registerForm.get('firstName'); }
    get lastName() { return this.registerForm.get('lastName'); }
    get email() { return this.registerForm.get('email'); }
    get streetNumber() { return this.registerForm.get('streetNumber'); }
    get zipCode() { return this.registerForm.get('zipCode'); }
    get accountNumber() { return this.registerForm.get('accountNumber'); }

    createAccount() {
        this.userService.registerUser(this.registerForm.value)
            .subscribe(data => {
                this.auth.saveAuthData(data);
                this.router.navigate(['/account', 'dashboard']);
            }, err => {
                if(err.error) {
                    this.message = err.error;
                }
                else {
                    this.message = 'An unknown error has occurred.';
                }
            });
    }
}
