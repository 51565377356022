import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/services/global/global.service';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas'

@Component({
    selector: 'app-board-info',
    templateUrl: './board-info.component.html',
    styleUrls: ['./board-info.component.scss']
})
export class BoardInfoComponent implements OnInit {

    boardInfo = {
        name: '',
        number: '',
        head: '',
        accountId: '',
        gate: '',
        schedules: [],
        hourlyRate: 0.0
    };
    hidden = true;

    @ViewChild('calendarHtml')
    calendarHtml: any;

    @ViewChild('calendarCss')
    calendarCss: any;

    @ViewChild('printCalendar')
    printCalendar: any;

    currentDate = new Date();

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public globalService: GlobalService
    ) { }

    print() {
        this.printCalendar.nativeElement.contentDocument.body.innerHTML = `<style>${this.calendarCss.nativeElement.value}</style>${this.calendarHtml.nativeElement.innerHTML}`;
        this.printCalendar.nativeElement.contentWindow.print();

        // html2canvas(this.calendarHtml.nativeElement).then(canvas => {
        //     var doc = new jsPDF('p', 'mm', 'a4');
        //     doc.html(canvas, {
        //         callback: () => doc.save('Calendar.pdf')
        //     });
        // });
    }

    getBoardInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/board/getInfoForAccount';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }
    ngOnInit() {
        this.getBoardInfo().then((boardInfo) => this.boardInfo = boardInfo);
    }

    toggle() {
        this.hidden = !this.hidden;
    }

    openMap(boardInfo) {
		this.globalService.openUrl(`${this.auth.baseUrl}/asset/maps/${boardInfo.number}.pdf`, true);

		return false;
    }
}
