<footer>
    <div class="container">
        <div class="info row">
            <div class="col-md-6 col-lg">
                <div class="title">About RWCD</div>
                <p>Roosevelt Water Conservation District originated in 1917, encompassing 40,000 acres situated in the
                south east corner of Maricopa County.</p>
            </div>
            <div class="col-md-6 col-lg-2">
                <div class="title">Visit Us</div>
                <p>
                    6937 E Elliot Rd.<br>
                    Mesa, AZ 85212
                </p>
                <div class="title">Monday-Thursday</div>
                <div class="mb-4">8am-5pm</div>
                <div class="title d-block d-lg-none">Friday</div>
                <div class="mb-4 d-block d-lg-none">8am-12pm</div>
            </div>
            <div class="col-md-6 col-lg-2">
                <div class="title">Contact Us</div>
                <p>
                    Phone: <span class="text-nowrap">480-988-9581</span><br>
                    Fax: <span class="text-nowrap">480-988-9589</span><br>
                </p>
                <div class="title d-none d-lg-block">Friday</div>
                <div class="mb-4 d-none d-lg-block">8am-12pm</div>
            </div>
            <div class="col-md-6 col-lg-2">
                <div class="title">Social Media</div>
                <p>
                    <i class="fa-brands fa-facebook"></i>
                    <a rel="noopener" href="http://www.facebook.com/rwcdaz" target="_blank">Facebook</a><br>

                    <i class="fa-brands fa-x-twitter"></i>
                    <a rel="noopener" href="http://www.twitter.com/rwcdaz" target="_blank">X</a><br>
                    
                    <i class="fa-brands fa-instagram"></i>
                    <a rel="noopener" href="http://www.instagram.com/rwcdaz" target="_blank">Instagram</a><br>
                </p>
            </div>
        </div>
    </div>
    <div class="copyright">
        Copyright &copy; {{year}}. All Rights Reserved.
    </div>
</footer>