import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL } from '../ApiClient.generated';

@Injectable({
  providedIn: 'root'
})
export class DirectorsService {
  // private http: HttpClient;
  private url: string;
  private headers: any = {
    headers: new HttpHeaders({
      "Accept": "application/json"
    })
  }

  constructor(private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.url = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:5000";
    this.url = this.url + "/api/directors";
    this.url = this.url.replace(/[?&]$/, "");
  }

  getDirectors(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.url , this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

  addDirector(boardOfDirector: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.url , boardOfDirector, this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

  addCommittees(committees: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.url + `/committees`, committees, this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

  updateBoardOfDirector(boardOfDirector: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.url + `/boardOfDirectors`, boardOfDirector, this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

  updateCommittees(committees: any): Promise<any> {
    console.log(committees);
    return new Promise((resolve, reject) => {
      this.http.put(this.url + `/committees`, committees, this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

  updateOfficer(officer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.url + `/officers`, officer, this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

  deleteCommittee(committeeIds: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      committeeIds.forEach(committeeId => {
        this.http.delete(this.url + `/committees/${committeeId}`, this.headers).subscribe((data) => {
          resolve(data);
        }, err => {
          reject(err);
        })
      })
    })
  }

  deleteDirector(directorId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete(this.url + `/${directorId}`, this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }
}



