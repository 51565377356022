<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Profile</h2>
</section>

<section class="section bg-white">
    <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
        <div class="container">
            <div class="row">
                <!-- <div class="col-12 col-md-6" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                <div class="col-12 col-md-6">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="text-left login">
                        <div class="row">
                            <div>
                                <h3>Account</h3>
                            </div>
                        </div>

                        <hr class="mt-2">
                        <h4>Username</h4>
                        <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
                            <div *ngIf="username.errors.required">
                                Username is required.
                            </div>
                            <div *ngIf="username.errors.minlength">
                                Username must be at least 4 characters long.
                            </div>
                            <div *ngIf="username.errors.pattern">
                                Username can only contain letters and numbers.
                            </div>
                        </div>
                        <input type="text" maxlength="50" class="w-100" required formControlName="username" autocomplete="new-password" />

                        <h4 class="mt-4">Password</h4>
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                            <div *ngIf="password.errors.BwBlankOrMinLengthValidator">
                                Password must be at least 4 characters long.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="w-100" formControlName="password" autocomplete="new-password" />

                        <h4 class="mt-4">Confirm Password</h4>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="text-danger">
                            <div *ngIf="confirmPassword.errors.BwMatchOtherValidator">
                                Password must match.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="w-100 mb-4" formControlName="confirmPassword" autocomplete="new-password" />

                        <h4 class="mt-4">First Name</h4>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                            <div *ngIf="firstName.errors.required">
                                First Name is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="firstName" />

                        <h4 class="mt-4">Last Name</h4>
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                            <div *ngIf="lastName.errors.required">
                                Last Name is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="lastName" />

                        <div class="text-danger mt-4">{{message}}</div>
                        <p class="d-none d-md-block mt-4">
                            <button type="submit" class="btn btn-success btn-round" [disabled]="!profileForm.valid || auth.impersonated" 
                                >Update Profile</button>
                            <button class="btn btn-secondary btn-round ml-2"
                                [routerLink]="auth.checkRole(RoleEnum.FrontOffice) || auth.checkRole(RoleEnum.Administrator) ? '/admin/dashboard' : '/account/dashboard'">Cancel</button>
                        </p>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-6" *ngIf="!auth.isFrontOffice && !auth.isAdministrator" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                <div class="col-12 col-md-6" *ngIf="!auth.checkRole(RoleEnum.FrontOffice) && !auth.checkRole(RoleEnum.Administrator)">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="text-left login">
                        <div class="clearfix">
                            <h3>Settings</h3>
                            <hr class="mt-2">
                            <h4>
                                Paperless
                                <div class="btn-group float-right" btnRadioGroup formControlName="paperless">
                                    <label btnRadio="Yes" class="btn btn-success" role="button">Yes</label>
                                    <label btnRadio="No" class="btn btn-success" role="button">No</label>
                                </div>
                            </h4>
                        </div>

                        <div class="mt-5">
                            <button type="button" class="btn btn-circle btn-sm btn-success float-right" (click)="addContact(1)" [disabled]="auth.impersonated">
                                <span class="fa fa-plus"></span>
                            </button>
                            <h3 class="mt-3">Phone Numbers</h3>
                            <hr class="mt-2">
                            <div class="contact" *ngFor="let contact of phoneNumbers">
                                <h5>
                                    {{contact.name | uppercase}}
                                    <div class="actions float-right">
                                        <button type="button" class="btn btn-success btn-sm btn-circle mr-1"
                                            (click)="editContact(contact)" [disabled]="auth.impersonated">
                                            <span class="fa fa-edit"></span>
                                        </button>
                                        <button type="button" class="btn btn-danger btn-sm btn-circle"
                                            (click)="deleteContact(contact)" [disabled]="auth.impersonated">
                                            <span class="fa fa-trash"></span>
                                        </button>
                                    </div>
                                </h5>
                                <div>{{contact.value}}</div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" (change)="toggleDirectory(contact)" [checked]="contact.inDirectory">
                                    <label class="form-check-label">
                                        Include in Directory<br>
                                        <i>*Checking this box will share this contact information to others on your Board</i>
                                    </label>
                                </div>
                                <hr>
                            </div>
                        </div>
                        <div>
                            <button type="button" class="btn btn-circle btn-sm btn-success float-right" (click)="addContact(2)" [disabled]="auth.impersonated">
                                <span class="fa fa-plus"></span>
                            </button>
                            <h3 class="mt-5">Email Addresses</h3>
                            <hr class="mt-2">
                            <div class="contact" *ngFor="let contact of emails">
                                <h5>
                                    {{contact.name | uppercase}}
                                    <div class="actions float-right">
                                        <button type="button" class="btn btn-success btn-sm btn-circle mr-1"
                                            (click)="editContact(contact)" [disabled]="auth.impersonated">
                                            <span class="fa fa-edit"></span>
                                        </button>
                                        <button type="button" class="btn btn-danger btn-sm btn-circle"
                                            (click)="deleteContact(contact)" [disabled]="auth.impersonated">
                                            <span class="fa fa-trash"></span>
                                        </button>
                                    </div>
                                </h5>
                                <div>{{contact.value}}</div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" (change)="toggleDirectory(contact)" [checked]="contact.inDirectory">
                                    <label class="form-check-label">
                                        Include in Directory<br>
                                        <i>*Checking this box will share this contact information to others on your Board</i>
                                    </label>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="col-12 d-md-none mt-4">
                    <button type="submit" class="btn btn-success btn-round w-100" [disabled]="!profileForm.valid || auth.impersonated">Update
                        Profile</button>
                    <button class="btn btn-secondary btn-round mt-1 w-100"
                        [routerLink]="auth.checkRole(RoleEnum.FrontOffice) || auth.checkRole(RoleEnum.Administrator) ? '/admin/dashboard' : '/account/dashboard'">Cancel</button>
                </p>
            </div>
            <div class="row" [hidden]="granted">
                <div class="col-md-12 mt-2">
                    <app-notifications></app-notifications>
                </div>
            </div>
        </div>
    </form>
</section>