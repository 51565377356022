import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StateDto, UserService } from 'src/services/ApiClient.generated';
import { AuthService } from 'src/services/auth/auth.service';
import { GlobalService } from 'src/services/global/global.service';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { BwMatchOtherValidator } from '../bw-controls/bw-match-other-validator';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit {

  message: string;
  dataSaved: boolean;
  states: StateDto[];

  registerForm = this.fb.group({
      username: [
          '', [
              Validators.required,
              Validators.minLength(4)
          ]
      ],
      password: [
          '', {
              validators: [
                  Validators.required,
                  Validators.minLength(4)
              ],
          }
      ],
      confirmPassword: [
          '', {
              validators: [
                  Validators.required,
                  Validators.minLength(4),
                  BwMatchOtherValidator('password')
              ],
          }
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      accountName: ['', Validators.required],
      emailName1: ['', Validators.required],
      email1: [
          '', {
            validators: [
            Validators.required,
            Validators.email,
          ],
        }
      ],
      emailName2: [''],
      email2: [''],
      phoneName1: ['', Validators.required],
      phone1: [
        '', {
          validators: [
            Validators.required,
            Validators.pattern('(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^[0-9]{3}[0-9]{3}[0-9]{4}$)')
          ],
        }
      ],
      phoneName2: [''],
      phone2: [''],
      phoneName3: [''],
      phone3: [''],
      phoneName4: [''],
      phone4: [''],
      phoneName5: [''],
      phone5: [''],
      phoneName6: [''],
      phone6: [''],
      phoneName7: [''],
      phone7: [''],
      address: [
          '', [
              Validators.required,
          ]
      ],
      city: ['', Validators.required],
      state: ['AZ'],
      zip: [
          '', [
              Validators.required,
              Validators.pattern('[0-9]*'),
              Validators.minLength(5)
          ]
      ],
      propertyAddress: [
          '', [
              Validators.required,
          ]
      ],
      propertyCity: [
          '', [
              Validators.required,
          ]
      ],
      propertyState: ['AZ'],
      propertyZip: [
          '', [
              Validators.required,
              Validators.pattern('[0-9]*'),
              Validators.minLength(5)
          ]
      ],
  });

  constructor(
    public fb: UntypedFormBuilder,
    public auth: AuthService,
    public http: HttpClient,
    public bwDialogService: BwDialogService,
    public userService: UserService,
    public router: Router
) { }

  ngOnInit(): void {
      this.userService.getStates().subscribe(result => this.states = result);;
  }

  get username() { return this.registerForm.get('username'); }
  get password() { return this.registerForm.get('password'); }
  get confirmPassword() { return this.registerForm.get('confirmPassword'); }
  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get accountName() { return this.registerForm.get('accountName'); }
  get emailName1() { return this.registerForm.get('emailName1'); }
  get email1() { return this.registerForm.get('email1'); }
  get emailName2() { return this.registerForm.get('emailName2'); }
  get email2() { return this.registerForm.get('email2'); }
  get phoneName1() { return this.registerForm.get('phoneName1'); }
  get phone1() { return this.registerForm.get('phone1'); }
  get phoneName2() { return this.registerForm.get('phoneName2'); }
  get phone2() { return this.registerForm.get('phone2'); }
  get phoneName3() { return this.registerForm.get('phoneName3'); }
  get phone3() { return this.registerForm.get('phone3'); }
  get phoneName4() { return this.registerForm.get('phoneName4'); }
  get phone4() { return this.registerForm.get('phone4'); }
  get phoneName5() { return this.registerForm.get('phoneName5'); }
  get phone5() { return this.registerForm.get('phone5'); }
  get phoneName6() { return this.registerForm.get('phoneName6'); }
  get phone6() { return this.registerForm.get('phone6'); }
  get phoneName7() { return this.registerForm.get('phoneName7'); }
  get phone7() { return this.registerForm.get('phone7'); }
  get address() { return this.registerForm.get('address'); }
  get city() { return this.registerForm.get('city'); }
  get state() { return this.registerForm.get('state'); }
  get zip() { return this.registerForm.get('zip'); }
  get propertyAddress() { return this.registerForm.get('propertyAddress'); }
  get propertyCity() { return this.registerForm.get('propertyCity'); }
  get propertyState() { return this.registerForm.get('propertyState'); }
  get propertyZip() { return this.registerForm.get('propertyZip'); }

  openWaterAccount() {
      this.userService.createAccount(this.registerForm.value)
          .subscribe(data => {
              this.dataSaved = true;
          }, err => {
              if(err.error) {
                  this.message = err.error;
              }
              else {
                  this.message = err.response;
              }
          });
  }
}
