import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { GlobalService } from 'src/services/global/global.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor(
		public auth: AuthService,
		public globalService: GlobalService
  ) { }

  ngOnInit() {
  }

	openMap() {
		this.globalService.openUrl(`${this.auth.baseUrl}/asset/other/facilities-map.pdf`, true);

		return false;
    }
}
