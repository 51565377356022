import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'bw-confirm-dialog',
    templateUrl: './bw-confirm-dialog.component.html',
    styleUrls: ['./bw-confirm-dialog.component.scss']
})
export class BwConfirmDialogComponent implements OnInit {

    title: string;
    message: string;
    onClose = new Subject<boolean>();

    constructor(
        public bsModalRef: BsModalRef
    ) { }

    ngOnInit() {
    }

    confirm() {
        this.bsModalRef.hide();
        this.onClose.next(true);
    }

    cancel() {
        this.bsModalRef.hide();
        this.onClose.next(false);
    }
}
