<form [formGroup]="contactForm" (ngSubmit)="saveContact()">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{dialogTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-danger" *ngIf="message">{{message}}</div>
        <div class="pt-4">
            <h4>Name</h4>
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                <div *ngIf="name.errors.required">
                    Name is required.
                </div>
            </div>
            <input type="text" class="w-100" required formControlName="name" />
        </div>
        <div class="pt-4" *ngIf="contactType.value === 1">
            <h4>Phone Number</h4>
            <div *ngIf="value.invalid && (value.dirty || value.touched)" class="text-danger">
                <div *ngIf="value.errors.required">
                    Phone Number is required.
                </div>
                <div *ngIf="value.errors.pattern">
                    You must enter a valid Phone Number.
                </div>
            </div>
            <input type="text" class="w-100" required formControlName="value" placeholder="999-999-9999" />
        </div>
        <div class="pt-4" *ngIf="contactType.value === 2">
            <h4>Email Address</h4>
            <div *ngIf="value.invalid && (value.dirty || value.touched)" class="text-danger">
                <div *ngIf="value.errors.required">
                    Email Address is required.
                </div>
                <div *ngIf="value.errors.email">
                    You must enter a valid Email Address.
                </div>
            </div>
            <input type="text" class="w-100" required formControlName="value" />
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-round float-right ml-1" [disabled]="!contactForm.valid" type="submit">Save</button>
        <button class="btn btn-outline-secondary btn-round float-right" type="button"
            (click)="bsModalRef.hide()">Cancel</button>
    </div>
</form>
