import { Component, OnInit, ViewChildren, QueryList, ViewChild, TemplateRef } from '@angular/core';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { GlobalService } from 'src/services/global/global.service';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.scss']
})
export class FAQsComponent implements OnInit {
    RoleEnum = RoleEnum;
    faqs = [];

    faqModal: BsModalRef;
    dialogTitle = '';

    editQuestion = {
        faqId: 0,
        faqCategoryId: 0,
        categoryName: '',
        sequence: 0,
        title: '',
        message: ''
    };

    @ViewChild('editFaq')
    editFaq: TemplateRef<any>;

    @ViewChildren('questions')
    questions: QueryList<any>;
    fragment: string;

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public route: ActivatedRoute,
        public modalService: BsModalService,
        public bwDialogService: BwDialogService,
        public globalService: GlobalService
    ) { }

    getFaqs(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/faq';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    ngOnInit() {
        this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    }

    ngAfterContentInit() {
        this.loadFaqs();
    }

    loadFaqs() {
        this.getFaqs().then((faqs) => {
            this.faqs = faqs;

            if (this.fragment) {
                setTimeout(() => {
                    const targetElement = this.questions.toArray().filter((x) => x.nativeElement.id === this.fragment)[0].nativeElement;
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                    this.fragment = null;
                }, 0);
            }
        });
    }

    openModal(template: TemplateRef<any>) {
        this.selectCategory(this.editQuestion.faqCategoryId);
        this.faqModal = this.modalService.show(template, {
            ignoreBackdropClick: true
        });
    }

    add() {
        this.dialogTitle = 'Add FAQ';
        this.editQuestion.faqId = 0;
        this.editQuestion.faqCategoryId = 2;
        this.editQuestion.title = '';
        this.editQuestion.message = '';
        this.openModal(this.editFaq);
    }

    edit(question) {
        this.dialogTitle = 'Edit FAQ';
        this.editQuestion.faqId = question.faqId;
        this.editQuestion.faqCategoryId = question.faqCategoryId;
        this.editQuestion.sequence = question.sequence;
        this.editQuestion.title = question.title;
        this.editQuestion.message = question.message;
        this.openModal(this.editFaq);
    }

    moveUp(question) {
        const url = this.auth.baseUrl + '/faq/moveUp';

        this.http.put(url, { faqId: question.faqId }).subscribe((data) => {
            this.loadFaqs();
        }, err => {
        });
    }

    moveDown(question) {
        const url = this.auth.baseUrl + '/faq/moveDown';

        this.http.put(url, { faqId: question.faqId }).subscribe((data) => {
            this.loadFaqs();
        }, err => {
        });
    }

    delete(question) {
        this.bwDialogService.showConfirm('Delete FAQ?', 'Are you sure you want to delete this FAQ?')
            .subscribe(result => {
                if (result) {
                    const url = this.auth.baseUrl + '/faq';

                    this.http.delete(url + `/${question.faqId}`).subscribe((data) => {
                        this.loadFaqs();
                    }, err => {
                    });
                }
            });
    }

    saveFaq() {
        const url = this.auth.baseUrl + '/faq';

        if (this.editQuestion.faqId > 0) {
            this.http.put(url, this.editQuestion).subscribe((data) => {
                this.loadFaqs();
                this.faqModal.hide();
            }, err => {
            });
        }
        else {
            this.http.post(url, this.editQuestion).subscribe((data) => {
                this.loadFaqs();
                this.faqModal.hide();
            }, err => {
            });
        }
    }

    selectCategory(faqCategoryId) {
        this.editQuestion.faqCategoryId = faqCategoryId;
        
        switch (faqCategoryId) {
            case 2:
                this.editQuestion.categoryName = 'Billing';
                break;
            case 3:
                this.editQuestion.categoryName = 'Scheduling';
                break;
            case 6:
                this.editQuestion.categoryName = 'Delivery';
                break;
            case 7:
                this.editQuestion.categoryName = 'Miscellaneous';
                break;
        }
    }

    openUrl(url: string) {
        this.globalService.openUrl(url, true);
        return false;
    }
}
