<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Make Payment</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <div class="alert alert-danger" *ngIf="!paymentInfo.allowPayments">
            <h4 class="text-danger text-center">Your account is currently suspended</h4>
            <div class="text-danger">
                Please call the office at 480-988-9581 during normal business hours (Monday - Thursday, 8am - 5pm or Friday, 8am - 12pm)
                to discuss the status of your account.  Thank you.
            </div>
        </div>
        <!-- <div class="row"  *ngIf="paymentInfo.allowPayments" [ngsRevealSet]="{ delay: 200, viewFactor: 0 }" [ngsSelector]="'article'" [ngsInterval]="200"> -->
        <div class="row"  *ngIf="paymentInfo.allowPayments">
            <div class="col-12 col-lg-6 col-xl-5 mb-4">
                <article class="payment">
                    <h4>Estimated Account Balance</h4>
                    <p class="amount">{{paymentInfo.accountBalance | currency}}</p>
                    <h4 class="mt-4">Pending Orders*</h4>
                    <p class="amount">{{paymentInfo.placedOrders | currency}}</p>
                    <h4 class="mt-4">Select Payment Amount</h4>
                    <div class="ml-md-4">
                        <div class="form-check">
                            <input class="form-check-input" id="pending" name="payment" type="radio" (change)="updateTotal()" [(ngModel)]="paymentType" value="pending" />
                            <label class="form-check-label" for="pending">
                                &nbsp;Pending Orders Amount: {{paymentInfo.placedOrders | currency}}*
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="average" name="payment" type="radio" (change)="updateTotal()" [(ngModel)]="paymentType" value="average" />
                            <label class="form-check-label" for="average">
                                &nbsp;One Month Average Usage: {{paymentInfo.monthlyAverageUsage | currency}}*
                            </label>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input id="enterAmount" id="payment" name="payment" type="radio" (change)="updateTotal()" [(ngModel)]="paymentType" value="enter" />
                                <label for="enterAmount">
                                    &nbsp;Enter Amount
                                </label>
                            </div>
                            <div class="col-12">
                                <div class="input-group w-50 mb-2 ml-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="number" class="form-control" (input)="updateTotal()" [(ngModel)]="amount" id="amount">
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4 class="mt-4">Total Payment Amount**</h4>
                    <p class="amount">{{totalAmount | currency}}</p>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="agree" id="agree">
                        <label id="agreeText" class="form-check-label" for="agree">
                            I agree to a non-refundable processing fee.
                        </label>
                    </div>

                    <div id="error" class="d-none text-danger">{{message}}</div>

                    <div class="mt-2" id="paypal-button-container"></div>

                    <div class="text-center"><a routerLink="/faqs" fragment="1045">View Other Payment Methods</a></div>

                    <div class="fine-print mt-2"><a routerLink="/faqs" fragment="1006">*Does not include montly service charge</a></div>
                    <div class="fine-print"><a routerLink="/faqs" fragment="3">**Includes PayPal Fee</a></div>
                </article>
            </div>
            <div class="col-12 col-lg-6 col-xl-7">
                <article class="payment">
                    <h4 class="mb-5 text-center">Water Usage (Hours)</h4>
                    <canvas baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [legend]="barChartLegend"
                        [type]="barChartType">
                    </canvas>
                </article>
            </div>
        </div>
    </div>
</section>

<a name="Payments"></a>
<section class="section bg-gray">
    <!-- <div class="container" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
    <div class="container">
        <div class="heading">
            <h1>Previous Transactions</h1>
        </div>
        <div class="text-center text-danger">Please note: It may take a few minutes for your payment to appear on your account.</div>
        <div class="d-flex mb-1">
            <div class="ml-auto">
                <button class="btn btn-round btn-success" (click)="loadTransactions()">Refresh</button>
            </div>
        </div>
        <article class="col-sm list">
            <div class="row no-gutters" *ngFor="let transaction of paymentInfo.previousTransactions">
                <div class="col-6">
                    <h5>{{transaction.transactionDate | date:'longDate'}} {{transaction.pending ? '(PENDING)' : ''}}</h5>
                </div>
                <div class="col-6">
                    <h5 class="text-right">{{transaction.amount | currency}}</h5>
                </div>
            </div>
        </article>
    </div>
</section>