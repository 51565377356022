import { UntypedFormControl } from '@angular/forms';

export function BwMatchOtherValidator(otherControlName: string) {

    let thisControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return function BwMatchOtherValidate(control: UntypedFormControl) {

        if (!control.parent) {
            return null;
        }

        // Initializing the validator.
        if (!thisControl) {
            thisControl = control;
            otherControl = control.parent.get(otherControlName) as UntypedFormControl;
            if (!otherControl) {
                throw new Error('BwMatchOtherValidator(): other control is not found in parent group');
            }
            otherControl.valueChanges.subscribe(() => {
                thisControl.updateValueAndValidity();
            });
        }

        if (!otherControl) {
            return null;
        }

        if (otherControl.value !== thisControl.value) {
            return {
                BwMatchOtherValidator: true
            };
        }

        return null;
    }
}