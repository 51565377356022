import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GlobalService } from 'src/services/global/global.service';

@Component({
	selector: 'app-meetings',
	templateUrl: './meetings.component.html',
	styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
	RoleEnum = RoleEnum;
	meetings = [];

	meetingModal: BsModalRef;
	dialogTitle = '';

	meetingForm = new UntypedFormGroup({
		meetingId: new UntypedFormControl(0),
		name: new UntypedFormControl(''),
		image: new UntypedFormControl(''),
	});

	file: File;

	@ViewChild('editItem')
	editItem: TemplateRef<any>;

	constructor(
		public auth: AuthService,
		public http: HttpClient,
		public globalService: GlobalService,
		public bwDialogService: BwDialogService,
		public modalService: BsModalService
	) { }

	getMeetings(): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this.auth.baseUrl + '/meeting';

			this.http.get(url).subscribe((data) => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}
	ngOnInit() {
		this.loadMeetings();
	}

	loadMeetings() {
		this.getMeetings().then((meetings) => {
			this.meetings = meetings;
		});
	}

	openModal(template: TemplateRef<any>) {
		this.meetingModal = this.modalService.show(template, {
			ignoreBackdropClick: true
		});
	}

	add() {
		this.dialogTitle = 'Add Entry';
		this.meetingForm.setValue({
			meetingId: 0,
			name: '',
			image: ''
		});
		this.openModal(this.editItem);
	}

	edit(meeting) {
		this.dialogTitle = 'Edit Entry';
		this.meetingForm.setValue({
			meetingId: meeting.meetingId,
			message: meeting.name,
			image: ''
		});
		this.openModal(this.editItem);
	}

	moveUp(meeting) {
		const url = this.auth.baseUrl + '/meeting/moveUp';

		this.http.put(url, { meetingId: meeting.meetingId }).subscribe((data) => {
			this.loadMeetings();
		}, err => {
		});
	}

	moveDown(meeting) {
		const url = this.auth.baseUrl + '/meeting/moveDown';

		this.http.put(url, { meetingId: meeting.meetingId }).subscribe((data) => {
			this.loadMeetings();
		}, err => {
		});
	}

	delete(meeting) {
		this.bwDialogService.showConfirm('Delete Entry?', 'Are you sure you want to delete this entry?')
			.subscribe(result => {
				if (result) {
					const url = this.auth.baseUrl + '/meeting';

					this.http.delete(url + `/${meeting.meetingId}`).subscribe((data) => {
						this.loadMeetings();
					}, err => {
					});
				}
			});
	}

	fileChanged(event) {
		this.file = event.target.files[0];
	}

	saveMeeting() {
		const url = this.auth.baseUrl + '/meeting';

		if (this.meetingForm.get('meetingId').value > 0) {
			const formData = new FormData();

			if (this.file) {
				formData.append('file', this.file, this.file.name);
			}
			formData.append('meetingId', this.meetingForm.get('meetingId').value);
			formData.append('name', this.meetingForm.get('name').value);

			this.http.put(url, formData)
				.subscribe(event => {
					this.loadMeetings();
					this.meetingModal.hide();
				});
		}
		else {
			const formData = new FormData();

			formData.append('file', this.file, this.file.name);
			formData.append('name', this.meetingForm.get('name').value);

			this.http.post(url, formData)
				.subscribe(event => {
					this.loadMeetings();
					this.meetingModal.hide();
				});
		}
	}

	openFile(meeting) {
		this.globalService.openUrl(`${this.auth.baseUrl}/asset/meetings/${meeting.path}`, true);

		return false;
    }
}
