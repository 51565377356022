import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService, RoleEnum } from '../../services/auth/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { GlobalService } from 'src/services/global/global.service';

@Component({
  selector: 'app-developers',
  templateUrl: './developers.component.html',
  styleUrls: ['./developers.component.scss']
})
export class DevelopersComponent implements OnInit {
    RoleEnum = RoleEnum;
    generalItems = [];
    detailBookItems = [];

    developerItemModal: BsModalRef;
    dialogTitle = '';

    developerItemForm = new UntypedFormGroup({
        developerItemId: new UntypedFormControl(0),
        developerCategory: new UntypedFormControl(''),
        name: new UntypedFormControl(''),
        image: new UntypedFormControl(''),
    });

    file: File;

    @ViewChild('editItem')
    editItem: TemplateRef<any>;

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public bwDialogService: BwDialogService,
        public modalService: BsModalService,
		public globalService: GlobalService
    ) { }

    getDeveloperItems(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/developerItem/GetGroupedByDeveloperCategory';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }
    ngOnInit() {
        this.loadDeveloperItems();
    }

    loadDeveloperItems() {
        this.getDeveloperItems().then((developerItems) => {
            this.generalItems = developerItems.generalItems;
            this.detailBookItems = developerItems.detailBookItems;
        });
    }

    openModal(template: TemplateRef<any>) {
        this.developerItemModal = this.modalService.show(template, {
            ignoreBackdropClick: true
        });
    }

    add() {
        this.dialogTitle = 'Add Entry';
        this.developerItemForm.setValue({
            developerItemId: 0,
            developerCategory: 1,
            name: '',
            image: ''
        });
        this.openModal(this.editItem);
    }

    edit(developerItem) {
        this.dialogTitle = 'Edit Entry';
        this.developerItemForm.setValue({
            developerItemId: developerItem.developerItemId,
            developerCategory: developerItem.developerCategory,
            message: developerItem.name,
            image: ''
        });
        this.openModal(this.editItem);
    }

    moveUp(developerItem) {
        const url = this.auth.baseUrl + '/developerItem/moveUp';

        this.http.put(url, { developerItemId: developerItem.developerItemId }).subscribe((data) => {
            this.loadDeveloperItems();
        }, err => {
        });
    }

    moveDown(developerItem) {
        const url = this.auth.baseUrl + '/developerItem/moveDown';

        this.http.put(url, { developerItemId: developerItem.developerItemId }).subscribe((data) => {
            this.loadDeveloperItems();
        }, err => {
        });
    }

    delete(developerItem) {
        this.bwDialogService.showConfirm('Delete Entry?', 'Are you sure you want to delete this entry?')
            .subscribe(result => {
                if (result) {
                    const url = this.auth.baseUrl + '/developerItem';

                    this.http.delete(url + `/${developerItem.developerItemId}`).subscribe((data) => {
                        this.loadDeveloperItems();
                    }, err => {
                    });
                }
            });
    }

    fileChanged(event) {
        this.file = event.target.files[0];
    }

    saveDeveloperItem() {
        const url = this.auth.baseUrl + '/developerItem';

        if (this.developerItemForm.get('developerItemId').value > 0) {
            const formData = new FormData();

            if(this.file) {
                formData.append('file', this.file, this.file.name);
            }
            formData.append('developerItemId', this.developerItemForm.get('developerItemId').value);
            formData.append('developerCategory', this.developerItemForm.get('developerCategory').value);
            formData.append('name', this.developerItemForm.get('name').value);

            this.http.put(url, formData)
                .subscribe(event => {
                    this.loadDeveloperItems();
                    this.developerItemModal.hide();
                });
        }
        else {
            const formData = new FormData();

            formData.append('file', this.file, this.file.name);
            formData.append('developerCategory', this.developerItemForm.get('developerCategory').value);
            formData.append('name', this.developerItemForm.get('name').value);

            this.http.post(url, formData)
                .subscribe(event => {
                    this.loadDeveloperItems();
                    this.developerItemModal.hide();
                });
        }
    }

	openFile(item) {
		this.globalService.openUrl(`${this.auth.baseUrl}/asset/developers/${item.path}`, true);

		return false;
    }
}
