import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UserService, DirectoryDto } from 'src/services/ApiClient.generated';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {

  directory: DirectoryDto[];

  @ViewChildren('highlights')
  highlights: QueryList<any>;

  constructor(
      public userService: UserService
  ) { }

  ngOnInit() {
      this.userService.getDirectory().subscribe(result => this.directory = result);
  }
}
