<!-- <section class="section bg-white" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->
<section class="section bg-white">
    <div class="container">
        <div class="heading" ngsReveal>
            <h1>Board of Directors</h1>
            The District is governed by a Board comprised of nine Directors. The Directors represent landowners located
            within the District’s boundaries including portions of the Cities of Chandler and Mesa, and the Town of
            Gilbert. The nine Directors are elected from three voting divisions. One Director from each voting division
            is elected each year. Each Director serves for a term of three consecutive calendar years.
        </div>
    </div>
</section>

<section class="section bg-gray">
    <h4 class="text-center text-danger font-weight-bold" *ngIf="errorMessage != ''">{{errorMessage}}</h4>
</section>

<!-- <section class="section bg-gray" [ngsRevealSet]="{ delay: 100, viewFactor: 0 }" [ngsSelector]="'article'" [ngsInterval]="200" -->
<section class="section bg-gray"
     *ngFor="let division of divisionsList">
    <div class="container" ngsReveal>
        <div *ngIf="auth.checkRole(RoleEnum.Administrator)">
            <button (click)="add(division.divisionNum)" class="btn btn-round btn-success"
                [disabled]="division.directorList.length >= 3">
                <span class="fa fa-plus mr-1"></span>
                Add Entry
            </button>
        </div>
        <div class="heading" ngsReveal>
            <h1>{{division.division}}</h1>
            <p>{{division.location}}</p>
        </div>
        <!-- <div class="row" ngsRevealSet [ngsSelector]="'article'" [ngsInterval]="100"> -->
        <div class="row">
            <div class="col-lg  col-xl-4 d-flex" *ngFor="let director of division.directorList; let i = index">
                <article class="p-4 flex-fill director mb-4">
                    <h3>{{director.name}}{{director.officer.position != 'None' ? ', ' +
                        director.officer.position :
                        '' }}</h3>
                    <p class="sub-heading" *ngFor="let committee of director.committeesList; let i = index;">
                        {{committee.position}} {{committee.isChair ? '(Chair)' : ''}} {{i ===
                        director.committeesList.length -1 ? '' : '/ ' }}
                    </p>
                    <h6>Term Expires</h6>
                    <p>{{director.termExpireDate | date:'mediumDate'}}</p>
                    <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                        <div class="row mb-1 mr-1 d-flex justify-content-end">
                            <button (click)="edit(director)" class="btn btn-round btn-sm btn-success  mr-1"><span
                                    class="fa fa-edit mr-1"></span> Edit</button>
                        </div>
                        <div class="row mr-1 d-flex justify-content-end">
                            <button (click)="delete(director)" class="btn btn-round btn-sm  btn-danger"><span
                                    class="far fa-trash-alt mr-1"></span> Delete</button>
                        </div>
                    </div>

                </article>

            </div>
        </div>
    </div>
</section>

<ng-template #editItem>
    <form [formGroup]="directorForm" (ngSubmit)="saveDirector()">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{dialogTitle}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="directorModal.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <h4>Name</h4>
                <input type="text" class="w-100" formControlName="directorName">
            </div>
            <div class="pt-4">
                <h4>Committees</h4>
                <div class="col-12 col-md-6 text-left issues" formArrayName="committees">
                    <div *ngFor="let committee of committeesForm.controls; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-8">
                                <input type="checkbox" class="form-check-input"
                                    [attr.disabled]="isAll && committee.value.positionName != ALL  ? true : null"
                                    (change)="onChange($event.target.checked, committee.value.positionName)"
                                    formControlName="isApartOf">
                                <label class="form-check-label">{{committee.value.positionName}}</label>
                            </div>
                            <div class="col-4" *ngIf="committee.value.isApartOf && !isAll">
                                <input type="checkbox" class="form-check-input" [attr.disabled]="isAll ? true : null"
                                    formControlName="isChair">
                                <label class="form-check-label">Chair</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-4">
                <h4>Officer</h4>
                <div class="col">
                    <div class="row " *ngFor="let position of officerPositionList">
                        <label>
                            <input type="radio" value="{{position.positionName}}" formControlName="officer"><span>
                                {{position.positionName}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="pt-4">
                <h4>Term Expire Date</h4>
                <input type="text" class="w-100 form-control" [bsConfig]="{ adaptivePosition: true }"
                    formControlName="termExpireDate" bsDatepicker>
            </div>
            <div class="mt-4">
                <div class="text-danger mb-3 font-weight-bold" *ngFor="let message of messages">{{message}}</div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-round float-right ml-1" type="submit"
                [disabled]="!directorForm.valid">Save</button>
            <button class="btn btn-outline-secondary btn-round float-right" type="button"
                (click)="directorModal.hide()">Cancel</button>
        </div>
    </form>
</ng-template>