import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { HttpClient } from '@angular/common/http';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SettingService, HomeQuoteDto } from 'src/services/ApiClient.generated';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [
        { provide: CarouselConfig, useValue: { interval: 5000, showIndicators: true } }
    ]
})
export class HomeComponent implements OnInit {
    RoleEnum = RoleEnum;
    homeData = {
        slideshows: [],
        articles: [],
        homeQuoteText: '',
        homeQuoteAuthor: '',
        userCount: 0
    };

    articleModal: BsModalRef;
    dialogTitle = '';
    
    quoteModal: BsModalRef;

    editArticle = {
        articleId: 0,
        postDate: null,
        postTime: null,
        subject: '',
        body: ''
    };

    editQuote = {
        text: '',
        author: ''
    };

    @ViewChild('articleTemplate')
    articleTemplate: TemplateRef<any>;

    @ViewChild('quoteTemplate')
    quoteTemplate: TemplateRef<any>;

    constructor(
        public http: HttpClient,
        public auth: AuthService,
        public modalService: BsModalService,
        public bwDialogService: BwDialogService,
        public settingService: SettingService
    ) { }

    get homeQuoteTextPrint() {
        return this.homeData.homeQuoteText ? `"${this.homeData.homeQuoteText}"` : '';
    }

    get homeQuoteAuthorPrint() {
        return this.homeData.homeQuoteAuthor ? `- ${this.homeData.homeQuoteAuthor}` : '';
    }

    loadHomeData() {
        const url = this.auth.baseUrl + '/home';

        this.http.get(url).subscribe((data: any) => {
            this.homeData = data;
        }, err => {
        });
    }

    ngOnInit() {
        this.loadHomeData();
    }


    openModal(template: TemplateRef<any>) {
        this.articleModal = this.modalService.show(template, {
            ignoreBackdropClick: true
        });
    }

    add() {
        this.dialogTitle = 'Add Article';
        this.editArticle.articleId = 0;
        this.editArticle.postDate = new Date();
        this.editArticle.postTime = new Date();
        this.editArticle.subject = '';
        this.editArticle.body = '';
        this.openModal(this.articleTemplate);
    }

    edit(article) {
        this.dialogTitle = 'Edit Article';
        this.editArticle.articleId = article.articleId;
        this.editArticle.postDate = new Date(article.postDate);
        this.editArticle.postTime = new Date(article.postDate);
        this.editArticle.subject = article.subject;
        this.editArticle.body = article.body;
        this.openModal(this.articleTemplate);
    }

    saveArticle() {
        const url = this.auth.baseUrl + '/article';

        this.editArticle.postDate = new Date(this.editArticle.postDate.toLocaleDateString() + ' ' + this.editArticle.postTime.toLocaleTimeString());

        if (this.editArticle.articleId > 0) {
            this.http.put(url, this.editArticle).subscribe((data) => {
                this.loadHomeData();
                this.articleModal.hide();
            }, err => {
            });
        }
        else {
            this.http.post(url, this.editArticle).subscribe((data) => {
                this.loadHomeData();
                this.articleModal.hide();
            }, err => {
            });
        }
    }

    delete(article) {
        this.bwDialogService.showConfirm('Delete Article?', 'Are you sure you want to delete this Article?')
            .subscribe(result => {
                if (result) {
                    const url = this.auth.baseUrl + '/article';

                    this.http.delete(url + `/${article.articleId}`).subscribe((data) => {
                        this.loadHomeData();
                    }, err => {
                    });
                }
            });
    }

    editQuoteText() {
        this.editQuote.text = this.homeData.homeQuoteText;
        this.editQuote.author = this.homeData.homeQuoteAuthor;

        this.quoteModal = this.modalService.show(this.quoteTemplate, {
            ignoreBackdropClick: true
        });
    }

    saveQuoteText() {
        this.settingService.updateHomeQuote(new HomeQuoteDto({
            homeQuoteText: this.editQuote.text,
            homeQuoteAuthor: this.editQuote.author
        })).subscribe(result => {
            this.homeData.homeQuoteText = this.editQuote.text;
            this.homeData.homeQuoteAuthor = this.editQuote.author;
            this.quoteModal.hide();
        });
    }
}
