<section class="section bg-white">
    <div class="container">
        <h1 class="mb-4 text-center">
            Customer Information Manual
        </h1>
        <p>
            Here is the latest revision of the Customer Information Manual.  RWCD's Customer Information Manual describes the ins and outs of flood irrigation in RWCD's service area.  
            If you have any questions about flood irrigation, please check the Frequently Asked Questions section of the manual.  If you can't find the answer, please contact anytime 
            24 hours a day 7 days a week 365 days a year.
        </p>
        <p class="text-center">
            <a href="tel:480-988-9581"><i class="fas fa-phone mr-2"></i> 480-988-9581</a><br>
            <a href="mailto:office@rwcd.net"><i class="fas fa-envelope mr-2"></i> office&#64;rwcd.net</a> 
        </p>
    </div>
</section>

<section class="section bg-gray">
    <div class="container text-center">

        <article class="download col-3 mx-auto">
            <a href="" (click)="openManual()" target="_blank"><i class="icon far fa-file-pdf"></i></a>
            <h3 class="pb-0"><a href="" (click)="openManual()" target="_blank">Download Manual</a></h3>
            <button (click)="updateManual()" class="btn btn-circle btn-success edit" *ngIf="auth.checkRole(RoleEnum.Administrator)">
                <span class="fa fa-edit"></span>
            </button>
        </article>
        
    </div>
</section>

<form class="d-none">
    <input type="file" #file (change)="fileChanged($event)" />
</form>
