<section class="section bg-white">
    <div class="container text-center">
        <h1 class="mb-4">
            Meetings
            <button (click)="add()" class="btn btn-circle btn-success" *ngIf="auth.checkRole(RoleEnum.Administrator)">
                <span class="fa fa-plus"></span>
            </button>
        </h1>
        <p>Below are the agendas for the upcoming and past board meetings.</p>
    </div>
</section>
<section class="section bg-gray">
    <div class="container">
        <div class="meeting-list">
            <div class="meetings" *ngFor="let d of meetings; let i = index">
                <i class="far fa-file-pdf pdf-icon"></i><a href="" (click)="openFile(d)">{{d.name}}</a>
                <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                    <button (click)="moveUp(d)" [disabled]="i == 0" class="btn btn-circle btn-sm btn-success mr-1"><span class="fas fa-arrow-up"></span></button>
                    <button (click)="moveDown(d)" [disabled]="i == meetings.length - 1" class="btn btn-circle btn-sm btn-success mr-1"><span class="fas fa-arrow-down"></span></button>
                    <button (click)="delete(d)" class="btn btn-circle btn-sm btn-danger"><span class="far fa-trash-alt"></span></button>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #editItem>
    <form [formGroup]="meetingForm" (ngSubmit)="saveMeeting()">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{dialogTitle}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="meetingModal.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <h4>File</h4>
                <input type="file" #file class="w-100" formControlName="file" (change)="fileChanged($event)">
            </div>
            <div class="pt-4">
                <h4>Name</h4>
                <input type="text" class="w-100" formControlName="name">
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-round float-right ml-1" type="submit">Save</button>
            <button class="btn btn-outline-secondary btn-round float-right" type="button"
                (click)="meetingModal.hide()">Cancel</button>
        </div>
    </form>
</ng-template>