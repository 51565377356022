import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { DirectorsService } from 'src/services/directors/directors.service';
import { BwDialogService } from '../bw-controls/bw-dialog.service';

@Component({
  selector: 'app-directors',
  templateUrl: './directors.component.html',
  styleUrls: ['./directors.component.scss']
})

export class DirectorsComponent implements OnInit {
  RoleEnum = RoleEnum;
  directorModal: BsModalRef;
  dialogTitle = '';
  messages = [];
  showLightbox = false;

  boardOfDirectorsList: BoardOfDirector[];
  committeesList: Committee[];
  officersList: Officer[];
  divisionsList: DivisionInfo[];
  committeePositionList: PositionInfo[];
  officerPositionList: PositionInfo[];
  errorMessage: string;
  isAll: boolean;
  DIVISION1: string = 'Division 1';
  DIVISION2: string = 'Division 2';
  DIVISION3: string = 'Division 3';
  DIVISION1_LOCATION: string = 'SRP South Canal to the North and Elliot Road to the South';
  DIVISION2_LOCATION: string = 'Elliot Road to the North and Germann Road to the South';
  DIVISION3_LOCATION: string = 'Germann Road to the North and the Gila River Indian Reservation to the South';
  AUDIT: string = 'Audit';
  LABOR: string = 'Labor';
  WATER: string = 'Water';
  WAYSMEANS: string = 'Ways & Means';
  ALL: string = 'All';
  PRESIDENT: string = 'President';
  VICEPRESIDENT: string = 'Vice President';
  SECRETARY: string = 'Secretary';

  @ViewChild('editItem')
  editItem: TemplateRef<any>;

  directorForm: UntypedFormGroup;

  constructor(
    public auth: AuthService,
    public bwDialogService: BwDialogService,
    public directorsService: DirectorsService,
    public modalService: BsModalService,
    public datePipe: DatePipe
  ) { }

  async ngOnInit() {

    await this.getDirectorInfo();
    if (this.errorMessage == '') {
      this.setDivisions();
      this.setForm();
    }
  }

  async getDirectorInfo() {
    this.boardOfDirectorsList = [];
    this.committeesList = [];
    this.officersList = [];
    this.divisionsList = [];
    this.committeePositionList = [];
    this.officerPositionList = [];
    this.errorMessage = "";
    await this.directorsService.getDirectors().then((data) => {
      this.boardOfDirectorsList = data.boardOfDirectorList;
      this.committeesList = data.committeeList;
      this.officersList = data.officerList;
      this.committeePositionList = data.committeePositionList;
      this.officerPositionList = data.officerPositionList;
    }).catch(err => {
      console.error('Request failed with error')
      this.errorMessage = 'Error with retrieving Board of Director information.';
    });
  }

  setForm(): void {
    this.directorForm = new UntypedFormGroup({
      directorName: new UntypedFormControl('', [Validators.required]),
      directorId: new UntypedFormControl(0, [Validators.required]),
      division: new UntypedFormControl(0, [Validators.required]),
      committees: new UntypedFormArray([]),
      officer: new UntypedFormControl('', [Validators.required]),
      termExpireDate: new UntypedFormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'), [Validators.required])
    })
  }

  get committeesForm() {
    return this.directorForm.controls.committees as UntypedFormArray;
  }

  setDivisions(): void {
    var division1: DirectorInfo[] = [];
    var division2: DirectorInfo[] = [];
    var division3: DirectorInfo[] = [];
    this.boardOfDirectorsList.forEach(director => {
      var committeesList: Committee[] = this.committeesList.filter(committee =>
        director.id == committee.boardOfDirectorId);
      var officersList: Officer[] = this.officersList.filter(officer =>
        director.id == officer.boardOfDirectorId);
      var directorInfo: DirectorInfo = {
        id: director.id,
        name: director.name,
        division: director.division,
        termExpireDate: new Date(director.termExpireDate),
        officer: officersList[0],
        committeesList: committeesList
      }
      if (director.division == 1 && directorInfo.officer) {
        division1.push(directorInfo)
      }
      else if (director.division == 2 && directorInfo.officer) {
        division2.push(directorInfo)
      }
      else if (director.division == 3 && directorInfo.officer) {
        division3.push(directorInfo)
      }
    });
    this.divisionsList.push({
      division: this.DIVISION1,
      divisionNum: 1,
      location: this.DIVISION1_LOCATION,
      directorList: this.setOfficersAsFirst(division1)
    })
    this.divisionsList.push({
      division: this.DIVISION2,
      divisionNum: 2,
      location: this.DIVISION2_LOCATION,
      directorList: this.setOfficersAsFirst(division2)
    })
    this.divisionsList.push({
      division: this.DIVISION3,
      divisionNum: 3,
      location: this.DIVISION3_LOCATION,
      directorList: this.setOfficersAsFirst(division3)
    })
  }

  setOfficersAsFirst(list: DirectorInfo[]): DirectorInfo[] {
    var secretary = list.filter(item => item.officer.position == this.SECRETARY);
    list = list.filter(item => item.officer.position !== this.SECRETARY);
    if (secretary.length > 0) {
      secretary.forEach(director => {
        list.unshift(director);
      })
    }
    var vicePresident = list.filter(item => item.officer.position == this.VICEPRESIDENT);
    list = list.filter(item => item.officer.position !== this.VICEPRESIDENT);
    if (vicePresident.length > 0) {
      vicePresident.forEach(director => {
        list.unshift(director);
      })
    }
    var president = list.filter(item => item.officer.position == this.PRESIDENT);
    list = list.filter(item => item.officer.position !== this.PRESIDENT);
    if (president.length > 0) {
      president.forEach(director => {
        list.unshift(director);
      })
    }
    return list;
  }

  openModal(template: TemplateRef<any>) {
    this.directorModal = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
  }

  add(division: number): void {
    this.messages = [];
    this.isAll = false;
    this.dialogTitle = 'Add Division ' + division + ' Director';
    this.setForm();
    var futureDate: Date = new Date (this.directorForm.value.termExpireDate);
    futureDate.setMonth(11, 31);
    futureDate.setFullYear(futureDate.getFullYear() + 3);
    this.directorForm.patchValue({
      division: division,
      termExpireDate: futureDate
    });
    this.committeesForm.push(new UntypedFormGroup({
      positionName: new UntypedFormControl(this.AUDIT, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    }));
    this.committeesForm.push(new UntypedFormGroup({
      positionName: new UntypedFormControl(this.LABOR, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    }));
    this.committeesForm.push(new UntypedFormGroup({
      positionName: new UntypedFormControl(this.WATER, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    }));
    this.committeesForm.push(new UntypedFormGroup({
      positionName: new UntypedFormControl(this.WAYSMEANS, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    }));
    this.committeesForm.push(new UntypedFormGroup({
      positionName: new UntypedFormControl(this.ALL, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    }));
    this.openModal(this.editItem);
  }

  edit(director: DirectorInfo): void {
    this.messages = [];
    this.isAll = false;
    this.setForm();
    this.dialogTitle = 'Edit Division ' + director.division + ' Director';
    this.directorForm.patchValue({
      directorName: director.name,
      directorId: director.id,
      division: director.division,
      officer: director.officer.position,
      termExpireDate: director.termExpireDate
    })
    this.setCommitteeForm(director.committeesList);
    this.openModal(this.editItem);
  }

  setCommitteeForm(committees: Committee[]): void {

    // AUDIT
    var committeeAuditForm = new UntypedFormGroup({
      positionName: new UntypedFormControl(this.AUDIT, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    })
    var committeeAudit = committees.filter(committee => committee.position == this.AUDIT);
    if (committeeAudit.length > 0) {
      committeeAuditForm.patchValue({
        isApartOf: true,
        isChair: committeeAudit[0].isChair
      })
    }
    this.committeesForm.push(committeeAuditForm);

    // LABOR
    var committeeLaborForm = new UntypedFormGroup({
      positionName: new UntypedFormControl(this.LABOR, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    })
    var committeeLabor = committees.filter(committee => committee.position == this.LABOR);
    if (committeeLabor.length > 0) {
      committeeLaborForm.patchValue({
        isApartOf: true,
        isChair: committeeLabor[0].isChair
      })
    }
    this.committeesForm.push(committeeLaborForm);

    // WATER
    var committeeWaterForm = new UntypedFormGroup({
      positionName: new UntypedFormControl(this.WATER, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    })
    var committeeWater = committees.filter(committee => committee.position == this.WATER);
    if (committeeWater.length > 0) {
      committeeWaterForm.patchValue({
        isApartOf: true,
        isChair: committeeWater[0].isChair
      })
    }
    this.committeesForm.push(committeeWaterForm);

    // WAYSMEANS
    var committeeWaysMeansForm = new UntypedFormGroup({
      positionName: new UntypedFormControl(this.WAYSMEANS, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    })
    var committeeWaysMeans = committees.filter(committee => committee.position == this.WAYSMEANS);
    if (committeeWaysMeans.length > 0) {
      committeeWaysMeansForm.patchValue({
        isApartOf: true,
        isChair: committeeWaysMeans[0].isChair
      })
    }
    this.committeesForm.push(committeeWaysMeansForm);

    // ALL 
    var committeeAllForm = new UntypedFormGroup({
      positionName: new UntypedFormControl(this.ALL, [Validators.required]),
      isApartOf: new UntypedFormControl(false, [Validators.required]),
      isChair: new UntypedFormControl(false, [Validators.required])
    })
    var committeeAll = committees.filter(committee => committee.position == this.ALL);
    if (committeeAll.length > 0) {
      this.isAll = true;
      this.committeesForm.controls.forEach(committee => {
        committee.patchValue({
          isApartOf: true,
          isChair: false
        })
      })
      committeeAllForm.patchValue({
        isApartOf: true,
        isChair: false
      })
    }
    this.committeesForm.push(committeeAllForm);
  }

  onChange(checked: boolean, committeeName: string): void {
    if (committeeName != this.ALL) {
      this.isAll = false;
      if (!checked) {
        this.committeesForm.controls.map(committee => {
          if (committee.value.positionName == committeeName) {
            committee.patchValue({
              isChair: false
            })
          }
        });
      }
    }
    else {
      if (checked) {
        this.isAll = true;
        this.committeesForm.controls.map(committee => {
          if (committee.value.positionName != committeeName) {
            committee.patchValue({
              isApartOf: true,
              isChair: false
            })
          }
        });
      }
      else {
        this.isAll = false;
        this.committeesForm.controls.map(committee => {
          if (committee.value.positionName != committeeName) {
            committee.patchValue({
              isApartOf: false,
              isChair: false
            })
          }
        });
      }
    }
    this.checkValidity();
  }

  checkValidity(): void {
    this.committeesForm.setErrors(null);
    let valid = this.committeesForm.controls.some(committee => committee.value.isApartOf === true);
    if (!valid) {
      this.committeesForm.setErrors({ notValid: true });
    }
  }

  async delete(director: DirectorInfo) {
    this.bwDialogService.showConfirm('Delete Director?', 'Are you sure you want to delete this director?')
      .subscribe(async result => {
        if (result) {
          await this.directorsService.deleteDirector(director.id);
          await this.getDirectorInfo();
          this.setDivisions();
        }
      });

  }

  async saveDirector() {
    if (this.directorForm.get('directorId').value > 0) {
      //put
      await this.update();
    }
    else {
      //post
      await this.create();
    }
    if (this.messages.length <= 0) {
      await this.getDirectorInfo();
      this.setDivisions();
      this.directorModal.hide();
    }

  }

  async update() {
    this.messages = [];
    if (this.directorForm.get('officer').touched) {
      const updateOfficer: Officer = {
        id: this.officersList.filter(data => data.boardOfDirectorId == this.directorForm.value.directorId)[0].id,
        boardOfDirectorId: this.directorForm.value.directorId,
        boardOfDirectorName: this.directorForm.value.directorName,
        position: this.directorForm.value.officer
      };
      await this.directorsService.updateOfficer(updateOfficer);
    }
    if (this.directorForm.get('directorName').touched ||
      this.directorForm.get('termExpireDate').touched) {
      const updateBoardOfDirector: BoardOfDirector = {
        id: this.directorForm.value.directorId,
        name: this.directorForm.value.directorName,
        division: this.directorForm.value.division,
        termExpireDate: this.directorForm.get('termExpireDate').value
      };
      await this.directorsService.updateBoardOfDirector(updateBoardOfDirector);
    }
    if (this.committeesForm.dirty) {
      var updateCommittees: Committee[] = [];
      var createCommittees: Committee[] = [];
      var deleteCommittees: number[] = [];
      this.committeesForm.controls.forEach(committee => {
        var committeeInfo = this.committeesList.filter(committeeItem => committeeItem.boardOfDirectorId == this.directorForm.value.directorId && committeeItem.position == committee.value.positionName);
        if (committeeInfo.length > 0 && !committee.get("isApartOf").dirty && committee.get("isChair").dirty) {
          const updateCommittee: Committee = {
            id: committeeInfo[0].id,
            isChair: committee.value.isChair,
            boardOfDirectorId: this.directorForm.value.directorId,
            boardOfDirectorName: this.directorForm.value.directorName,
            position: committee.value.positionName
          };
          updateCommittees.push(updateCommittee);
        }
        if (committee.get("isApartOf").dirty) {
          if (committee.value.isApartOf) {
            if (committee.value.positionName == this.ALL) {
              createCommittees = [{
                id: 0,
                boardOfDirectorName: this.directorForm.value.directorName,
                boardOfDirectorId: this.directorForm.value.directorId,
                position: this.ALL,
                isChair: committee.value.isChair
              }];
              this.committeesList.map(committeeItem => {
                if (committeeItem.boardOfDirectorId == this.directorForm.value.directorId) {
                  deleteCommittees.push(committeeItem.id);
                }
              })
            }
            else {
              const newCommittee: Committee = {
                id: 0,
                isChair: committee.value.isChair,
                boardOfDirectorId: this.directorForm.value.directorId,
                boardOfDirectorName: this.directorForm.value.directorName,
                position: committee.value.positionName
              };
              createCommittees.push(newCommittee);
            }
          }
          else {
            deleteCommittees.push(committeeInfo[0].id);
          }
        }
      });

      if (deleteCommittees.length > 0) {
        await this.directorsService.deleteCommittee(deleteCommittees)
          .catch(err => this.messages.push('Error with removing Committee from Director.'));
      }
      if (updateCommittees.length > 0 && this.messages.length == 0) {
        await this.directorsService.updateCommittees(updateCommittees)
          .catch(err => this.messages.push('Error with updating Chair position for Director.'));
      }

      if (createCommittees.length > 0 && this.messages.length == 0) {
        await this.directorsService.addCommittees(createCommittees)
          .catch(err => this.messages.push('Error with adding Committee to Director.'));
      }

    }
  }

  async create() {
    var director: Director = {
      boardOfDirector: {
        id: 0,
        name: this.directorForm.value.directorName,
        division: this.directorForm.value.division,
        termExpireDate: this.directorForm.value.termExpireDate,
      },
      officer: {
        id: 0,
        boardOfDirectorId: 0,
        boardOfDirectorName: this.directorForm.value.directorName,
        position: this.directorForm.value.officer
      },
      committeeList: []
    };
    this.committeesForm.controls.forEach(committee => {
      if (committee.value.isApartOf) {
        if (committee.value.positionName == this.ALL) {
          director.committeeList = [{
            id: 0,
            boardOfDirectorName: this.directorForm.value.directorName,
            boardOfDirectorId: 0,
            position: this.ALL,
            isChair: committee.value.isChair
          }]
        }
        else {
          const newCommittee: Committee = {
            id: 0,
            boardOfDirectorName: this.directorForm.value.directorName,
            boardOfDirectorId: 0,
            position: committee.value.positionName,
            isChair: committee.value.isChair
          }
          director.committeeList.push(newCommittee)
        }
      }
    });
    await this.directorsService.addDirector(director)
      .catch(err => this.messages.push('Error with adding new Director.'));
  }

}

export interface CommitteeForm {
  positionName: string,
  isApartOf: boolean,
  isChair: boolean
}

export interface PositionInfo {
  id: number;
  positionName: string
}

export interface DivisionInfo {
  division: string,
  divisionNum: number,
  location: string,
  directorList: DirectorInfo[]
}

export interface DirectorInfo {
  id: number;
  name: string,
  division: number,
  termExpireDate: Date,
  committeesList: Committee[];
  officer: Officer;
}

export interface Director {
  boardOfDirector: BoardOfDirector;
  committeeList: Committee[];
  officer: Officer;
}

export interface BoardOfDirector {
  id: number;
  name: string;
  division: number;
  termExpireDate: string;
}

export interface Committee {
  id: number;
  boardOfDirectorId: number;
  boardOfDirectorName: string;
  position: string;
  isChair: boolean;
}

export interface Officer {
  id: number;
  boardOfDirectorId: number;
  boardOfDirectorName: string;
  position: string;
}
