<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Directory</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <p>To have your contact information included in the Directory you must indicate which phone numbers and email addresses you would like published in your profile.
        This information will only be available to other customers on the same board as you.</p>

        <button class="btn btn-round btn-success mb-2" routerLink="/account/profile">Edit Profile</button>
        <!-- <article class="col-sm list" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->
        <article class="col-sm list">
            <div class="d-none d-lg-block">
                <div class="row no-gutters header sticky-header">
                    <div class="col-4">Name</div>
                    <div class="col-4">Phone</div>
                    <div class="col-4">Email</div>
                </div>
            </div>
            <div class="row no-gutters" *ngFor="let account of directory">
                <div class="col-12 col-lg-4">
                    <h5 class="d-none d-lg-block">{{account.accountName | uppercase}}</h5>
                    <h3 class="d-block d-lg-none mb-4">{{account.accountName | uppercase}}</h3>
                </div>
                <div class="col-12 col-lg-4">
                    <div *ngFor="let contact of account.phones">
                        <h5>{{contact.name | uppercase}}</h5>
                        <div class="mb-2">{{contact.value}}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div *ngFor="let contact of account.emails">
                        <h5>{{contact.name | uppercase}}</h5>
                        <div class="mb-2">
                            <a href="mailto:{{contact.value}}">{{contact.value}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>