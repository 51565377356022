<section class="hero-photo d-flex justify-content-center align-items-center">
    <div class="overlay"></div>
    <h2 ngsReveal>Create Login</h2>
</section>

<section class="section bg-white">
    <div class="container">
        <form [formGroup]="registerForm" (ngSubmit)="createAccount()">
            <div class="row">
                <!-- <div class="col-12 col-md-6" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="col-12 col-md-6">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                        <div class="text-left login">
                        <h3>Account</h3>
                        <hr>

                        <h4>Username</h4>
                        <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
                            <div *ngIf="username.errors.required">
                                Username is required.
                            </div>
                            <div *ngIf="username.errors.minlength">
                                Username must be at least 4 characters long.
                            </div>
                            <div *ngIf="username.errors.pattern">
                                Username can only contain letters and numbers.
                            </div>
                        </div>    
                        <input type="text" maxlength="50" class="w-100" required formControlName="username" autocomplete="new-password" />

                        <h4 class="mt-4">Password</h4>
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                            <div *ngIf="password.errors.required">
                                Password is required.
                            </div>
                            <div *ngIf="password.errors.minlength">
                                Password must be at least 4 characters long.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="w-100" required formControlName="password" autocomplete="new-password" />

                        <h4 class="mt-4">Confirm Password</h4>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="text-danger">
                            <div *ngIf="confirmPassword.errors.BwMatchOtherValidator">
                                Password must match.
                            </div>
                        </div>    
                        <input type="password" maxlength="20" class="w-100 mb-4" required formControlName="confirmPassword" autocomplete="new-password" />

                        <h3>Personal</h3>
                        <hr>

                        <h4 class="mt-4">First Name</h4>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                            <div *ngIf="firstName.errors.required">
                                First Name is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="firstName" />

                        <h4 class="mt-4">Last Name</h4>
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                            <div *ngIf="lastName.errors.required">
                                Last Name is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="lastName" />
                    </div>
                </div>
                <!-- <div class="col-12 col-md-6" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                    <div class="col-12 col-md-6">
                    <!-- <div class="text-left login" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                        <div class="text-left login">
                        <h3>Contact</h3>
                        <hr>

                        <h4>Email Address</h4>
                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                            <div *ngIf="email.errors.required">
                                Email Address is required.
                            </div>
                        </div>
                        <input type="text" class="w-100" required formControlName="email" />

                        <h3 class="mt-4">Verification</h3>
                        <hr>

                        <h4>Mailing Address Number</h4>
                        <div *ngIf="streetNumber.invalid && (streetNumber.dirty || streetNumber.touched)" class="text-danger">
                            <div *ngIf="streetNumber.errors.required">
                                Mailing Address Number is required.
                            </div>
                            <div *ngIf="streetNumber.errors.pattern">
                                Mail Address Number must be numeric.
                            </div>
                        </div>
                        <input type="text" placeholder="12345" class="w-100" required formControlName="streetNumber" />

                        <h4 class="mt-4">Zip Code</h4>
                        <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="text-danger">
                            <div *ngIf="zipCode.errors.required">
                                Zip Code is required.
                            </div>
                            <div *ngIf="zipCode.errors.pattern">
                                Zip Code must be numeric.
                            </div>
                            <div *ngIf="zipCode.errors.minlength">
                                Zip Code must be 5 numbers.
                            </div>
                        </div>
                        <input type="text" placeholder="85299" class="w-100" required formControlName="zipCode" />

                        <h4 class="mt-4">Account Number</h4>
                        <div *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched)" class="text-danger">
                            <div *ngIf="accountNumber.errors.required">
                                Account Number is required.
                            </div>
                            <div *ngIf="accountNumber.errors.pattern">
                                Account Number must be numeric.
                            </div>
                        </div>
                        <input type="text" placeholder="99999" class="w-100" required formControlName="accountNumber" />

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mt-4">
                        <div class="text-danger mb-3">{{message}}</div>
                        <div class="d-none d-sm-block">
                            <button type="submit" class="btn btn-success btn-round" [disabled]="!registerForm.valid">Create Login</button>
                            <button class="btn btn-secondary btn-round ml-2" routerLink="/">Cancel</button>
                        </div>
                        <div class="d-sm-none">
                            <button type="submit" class="btn btn-success btn-round w-100 mb-1" [disabled]="!registerForm.valid">Create Login</button>
                            <button class="btn btn-secondary btn-round w-100" routerLink="/">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>