import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from 'src/services/auth/auth.service';
import { Router } from '@angular/router';
import { LoggingService } from 'src/services/logging/logging.service';
import { CheckForUpdateService } from './check-for-update.service';
import { environment } from '@env/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'rwcd';

    constructor(
        @Inject(PLATFORM_ID) public platformId: Object,
        public auth: AuthService,
        public router: Router,
        private logging: LoggingService,
        public checkForUpdateService: CheckForUpdateService
    ) { }

    ngOnInit() {
        this.auth.restoreSession();
    }

    onActivate(event: any) {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
