<div class="container" *ngIf="(auth.isAuthenticated | async) && !auth.isStaff">
    <ul class="navbar-nav justify-content-center account-menu">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/account/dashboard">Dashboard</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="./account/order">Order Water</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="!auth.isLarge">
            <a class="nav-link" routerLink="./account/payment">Make Payment</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="./account/history">Account History</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="!auth.isLarge">
            <a class="nav-link" routerLink="./account/board-info">Board Information</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="./account/schedule">{{auth.isLarge ? "Heads Running" : "Current Schedule"}}</a>
        </li>
        <li class="nav-item d-block d-lg-none" *ngIf="(auth.isAuthenticated | async)">
            <a class="nav-link" routerLink="/account/profile">Profile</a>
        </li>
        <li class="nav-item d-block d-lg-none" *ngIf="(auth.isAuthenticated | async)">
            <a class="nav-link" routerLink="/logout" *ngIf="!auth.impersonated">Logout</a>
            <a class="nav-link" routerLink="" *ngIf="auth.impersonated" (click)="auth.unimpersonate()">Unimpersonate</a>
        </li>
</ul>
</div>
<div class="container-fluid justify-content-center" *ngIf="(auth.isAuthenticated | async) && auth.isStaff">
    <ul class="navbar-nav justify-content-center">
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRole(RoleEnum.FrontOffice)">
            <a class="nav-link" routerLink="/admin/dashboard">Dashboard</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRole(RoleEnum.FrontOffice)">
            <a class="nav-link" routerLink="./admin/account">Accounts</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRole(RoleEnum.FrontOffice) || auth.checkRole(RoleEnum.GIS)">
            <a class="nav-link" routerLink="./admin/board">Boards</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRole(RoleEnum.FrontOffice)">
            <a class="nav-link" routerLink="./admin/orders">Orders</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRole(RoleEnum.FrontOffice)">
            <a class="nav-link" routerLink="/admin/payments">Payments</a>
        </li>
        <li class="nav-item d-none" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRole(RoleEnum.FrontOffice)">
            <a class="nav-link" routerLink="/admin/tickets">Tickets</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRoleGroup(RoleGroupEnum.FieldReports)">
            <a class="nav-link" routerLink="/admin/field-report">Field Reports</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRoleGroup(RoleGroupEnum.FieldReports)">
            <a class="nav-link" routerLink="/admin/running">Running</a>
        </li>
        <li class="nav-item dropdown" dropdown routerLinkActive="active" *ngIf="auth.checkRoleGroup(RoleGroupEnum.Tracking)">
            <a class="nav-link" href="" (click)="doNothing()" dropdownToggle>Tracking</a>  
            <div class="dropdown-menu" *dropdownMenu>
                <a class="dropdown-item" routerLink="/admin/pending-reviews">Pending Reviews</a>
            </div> 
        </li>
        <li class="nav-item dropdown" dropdown routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator) || auth.checkRoleGroup(RoleGroupEnum.FieldReports) || auth.checkRole(RoleEnum.FrontOffice) || auth.checkRoleGroup(RoleGroupEnum.TimeSheets)">
            <a class="nav-link" href="" (click)="doNothing()" dropdownToggle>Employee</a>  
            <div class="dropdown-menu" *dropdownMenu>
                <a class="dropdown-item" routerLink="/admin/employee">Information</a>
                <a class="dropdown-item" routerLink="/admin/timesheets/{{date | date : 'yyyy-MM-dd'}}">Time Sheets</a>
            </div> 
        </li>
        <li class="nav-item dropdown" dropdown routerLinkActive="active" *ngIf="auth.checkRole(RoleEnum.Administrator)">
            <a class="nav-link" href="" (click)="doNothing()" dropdownToggle>Admin</a>
            <div class="dropdown-menu" *dropdownMenu>
                <a class="dropdown-item" routerLink="./admin/admins">Staff</a>
                <a class="dropdown-item" routerLink="/admin/slideshows">Slideshow</a>
                <a class="dropdown-item" routerLink="/admin/settings">Settings</a>
            </div>
        </li>
        <li class="nav-item d-block d-lg-none" *ngIf="(auth.isAuthenticated | async) && !auth.checkRole(RoleEnum.GIS)">
            <a class="nav-link" routerLink="/account/profile">Profile</a>
        </li>
        <li class="nav-item d-block d-lg-none" *ngIf="(auth.isAuthenticated | async)">
            <a class="nav-link" routerLink="/logout" *ngIf="!auth.impersonated">Logout</a>
            <a class="nav-link" routerLink="" *ngIf="auth.impersonated" (click)="auth.unimpersonate()">Unimpersonate</a>
        </li>
    </ul>
</div>
