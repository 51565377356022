<section class="section bg-white">
    <div class="container text-center" ngsReveal>
        <h1>Log in to manage your account</h1>
        <button type="button" class="btn btn-success m-2 btn-round" routerLink="/login">Log in</button>
        <button type="button" class="btn btn-secondary m-2 btn-round" routerLink="/register">Create Account</button>
    </div>
</section>

<section class="section bg-gray">
    <!-- <div class="container" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
    <div class="container">
        <!-- <div class="row" [ngsRevealSet]="{ delay: 300, viewFactor: 0 }" [ngsSelector]="'article'" [ngsInterval]="200"> -->
        <div class="row">
            <div class="col-12 col-md-6 col-xl-3">
                <article class="borderless flex-fill p-0">
                    <a *ngIf="!(auth.isAuthenticated | async)" routerLink="/login" [queryParams]="{ returnUrl: '/account/order' }"><i class="fas fa-shopping-cart"></i></a>
                    <a *ngIf="(auth.isAuthenticated | async)" routerLink="/account/order"><i class="fas fa-shopping-cart"></i></a>
                    <h3>Order Water</h3>
                    <p>You can manage your water orders online. Create, update and delete orders for the
                        entire year all in the comfort of your home.</p>
                </article>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
                <article class="borderless flex-fill p-0">
                    <a *ngIf="!(auth.isAuthenticated | async)" routerLink="/login" [queryParams]="{ returnUrl: '/account/payment' }"><i class="fas fa-credit-card"></i></a>
                    <a *ngIf="(auth.isAuthenticated | async)" routerLink="/account/payment"><i class="fas fa-credit-card"></i></a>
                    <h3>Make Payments</h3>
                    <p>Don't forget to pay your bill! Make payments with your account online!</p>
                </article>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
                <article class="borderless flex-fill p-0">
                    <a *ngIf="!(auth.isAuthenticated | async)" routerLink="/login" [queryParams]="{ returnUrl: '/account/history' }"><i class="fas fa-history"></i></a>
                    <a *ngIf="(auth.isAuthenticated | async)" routerLink="/account/history"><i class="fas fa-history"></i></a>
                    <h3>Account History</h3>
                    <p>Not sure where your paper copy of your account history is? Sign up and you can view your account
                        history all online.</p>
                </article>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
                <article class="borderless flex-fill p-0">
                    <a *ngIf="!(auth.isAuthenticated | async)" routerLink="/login" [queryParams]="{ returnUrl: '/account/profile' }"><i class="fas fa-user"></i></a>
                    <a *ngIf="(auth.isAuthenticated | async)" routerLink="/account/profile"><i class="fas fa-user"></i></a>
                    <h3>Update Profile</h3>
                    <p>Need to update your phone number, email, or contacts? Our online system allows you to do all of this
                        quickly and efficiently.</p>
                </article>
            </div>

        </div>
    </div>
</section>