import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { LargeTransactionGroupDto, TransactionService } from 'src/services/ApiClient.generated';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

    transactions = [];
    largeTransactionGroups: LargeTransactionGroupDto[];
    ascendingOrder = true;

    year = new Date().getFullYear();
    years = [];
    balance = 0;
    acreFeetTotal = 0;

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public transactionService: TransactionService
    ) { }

    getTransactions(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/transaction/getTransactionsByYear';

            this.http.get(url, { params: { year: this.year.toString() }}).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    ngOnInit() {
        this.loadTransactions();
    }

    loadTransactions() {
        if (this.auth.isLarge) {
            this.transactionService.getLargeTransactionsByYear(this.year).subscribe(data => {
                this.years = data.years;
                this.largeTransactionGroups = data.transactionGroups;
                this.acreFeetTotal = this.largeTransactionGroups.reduce((acc, cur) => acc + cur.acreFeetTotal, 0);
                this.balance = this.largeTransactionGroups.reduce((acc, cur) => acc + cur.subTotal, 0);
            });
        }
        else 
        {
            this.getTransactions().then((data) => {
                this.years = data.years;
                this.transactions = data.transactions;
            });
        }
    }

    changeOrder() {
        this.ascendingOrder = !this.ascendingOrder;

        if (this.auth.isLarge) {
            for(const transactionGroup of this.largeTransactionGroups) {
                transactionGroup.transactions.reverse();
            }
        }
        else {
            this.transactions.reverse();
        }
    }

    selectYear(year: number) {
        this.year = year;
        this.loadTransactions();
        return false;
    }
}
