import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    const appIsStable$ = appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everySixSeconds$ = interval(6 * 1000);
    const everySixSecondsOnceAppIsStable$ = concat(
      appIsStable$,
      everySixSeconds$
    );

    everySixSecondsOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        if(updateFound) {
          if(confirm('New version available. Load new version?')) {
            // Reload the page to update to the latest version.
            document.location.reload();
          }
        } else {
          console.log('Already on the latest version.');
        }
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
   }
}
