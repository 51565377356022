import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '../global/global.service';

export interface ILoginResult {
    successful: boolean;
    message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isAuthenticated = new BehaviorSubject<boolean>(false);
    isMenuOpen = new BehaviorSubject<boolean>(false);
    authToken: string;
    userId: number;
    username: string;
    accountId: number;
    cardNumber: string;
    lot: string;
    firstName: string;
    lastName: string;
    userRoles: UserRole[];
    isLarge: boolean;
    previousUserId: number;

    constructor(
        public http: HttpClient,
        public global: GlobalService,
        public router: Router
    ) { }

    get impersonated() {
        return this.previousUserId !== null && this.previousUserId !== undefined;
    }

    get baseUrl() {
        return this.global.apiUrl;
    }

    get isStaff() {
        return this.userRoles.some(u => u.role.roleGroupID == RoleGroupEnum.Admin || 
            u.role.roleGroupID == RoleGroupEnum.FrontOffice ||
            u.role.roleGroupID == RoleGroupEnum.FieldReports ||
            u.role.roleGroupID == RoleGroupEnum.TimeSheets ||
            u.role.roleGroupID == RoleGroupEnum.Tracking);
            
    }

    get menuOpen() {
        return this.isMenuOpen.value;
    }

    set menuOpen(isOpen: boolean) {
        this.isMenuOpen.next(isOpen);
    }

    authenticate(router: Router): boolean {
        if (this.authToken) {
            return true;
        }
        else {
            setTimeout(() => router.navigate(['/Login']));
            return false;
        }
    }

    restoreSession(): Promise<string> {
        return new Promise((resolve) => {
            if (localStorage.getItem('authData')) {
                const authData = JSON.parse(localStorage.getItem('authData'));
                
                if (authData && (authData.profile.userRoles != undefined && authData.profile.userRoles.length > 0)) {
                    this.loadAuthData(authData);
                    const lastPage = localStorage.getItem('lastPage');
                    resolve(lastPage || '/Login');
                }
            }
        });
    }

    loadAuthData(authData) {
        this.authToken = authData.token;
        this.userId = authData.profile.userId;
        this.username = authData.profile.username;
        this.accountId = authData.profile.accountId;
        this.cardNumber = authData.profile.cardNumber;
        this.lot = authData.profile.lot;
        this.firstName = authData.profile.firstName;
        this.lastName = authData.profile.lastName;
        this.userRoles = authData.profile.userRoles;
        this.isLarge = authData.profile.isLarge;
        this.previousUserId = authData.profile.previousUserId;
        this.isAuthenticated.next(true);
    }

    setLastPage(page: string) {
        localStorage.setItem('lastPage', page);
    }

    saveAuthData(authData) {
        localStorage.setItem('authData', JSON.stringify(authData));
        this.loadAuthData(authData);
    }

    checkRole(roleEnum: RoleEnum): boolean {
        return this.userRoles == undefined || this.userRoles.length == 0 ? false : this.userRoles.some(u => u.roleId == roleEnum);
    }

    checkRoleGroup(roleGroupEnum: RoleGroupEnum): boolean {
        return this.userRoles == undefined || this.userRoles.length == 0 ? false : this.userRoles.some(u => u.role.roleGroupID == roleGroupEnum);
    }

    login(username: string, password: string): Promise<ILoginResult> {
        return new Promise((resolve, reject) => {
            const url = this.global.apiUrl + '/auth/login';
            const params = {
                username,
                password
            };
            this.http.get(url, { params }).subscribe((authData: any) => {
                this.saveAuthData(authData);

                resolve({
                    successful: true
                });
            }, err => {
                if (err.status === 401) {
                    resolve({
                        successful: false,
                        message: 'Invalid username or password.'
                    });
                }
                else {
                    this.global.log(err.title, err.status, url);
                    reject(err.title);
                }
            });
        });
    }

    refreshAuthToken(): Observable<any> {
        return new Observable((observer) => {
            const url = this.global.apiUrl + `/auth/refreshAuthToken?authToken=${this.authToken}`;

            this.http.get(url).subscribe((authToken: any) => {
                const authData = JSON.parse(localStorage.getItem('authData') || null);

                authData.token = authToken.token;
                localStorage.setItem('authData', JSON.stringify(authData));
                this.authToken = authToken.token;
                observer.next(true);
                observer.complete();
            }, err => {
                this.global.log(err.status, err.title, url);
                observer.error(err);
            });
        });
    }

    impersonate(userId) {
        return new Promise((resolve, reject) => {
            const url = this.global.apiUrl + `/auth/impersonate?userId=${userId}`;

            this.http.get(url).subscribe((authData: any) => {
                this.saveAuthData(authData);

                resolve({
                    successful: true
                });
            }, err => {
                this.global.log(err.title, err.status, url);
                reject(err.title);
            });
        });
    }

    unimpersonate() {
        return new Promise((resolve, reject) => {
            const url = this.global.apiUrl + '/auth/unimpersonate';

            this.http.get(url).subscribe((authData: any) => {
                const accountId = this.accountId;

                this.saveAuthData(authData);

                this.router.navigate(['admin', 'account', accountId])

                resolve({
                    successful: true
                });
            }, err => {
                this.global.log(err.title, err.status, url);
                reject(err.title);
            });
        });
    }

    logout() {
        this.authToken = null;
        this.userId = 0;
        this.accountId = 0;
        this.cardNumber = null;
        this.lot = null;
        this.username = null;
        this.firstName = null;
        this.lastName = null;
        this.userRoles = [];
        this.isAuthenticated.next(false);
        this.previousUserId = null;
        localStorage.removeItem('authData');
        localStorage.removeItem('lastPage');
        this.router.navigate(['/login']);
    }
}

class UserRole {
    public userRoleId: number;
    public userId: number;
    public roleId: number;
    public role: Role;
}

class Role {
    public roleId: number;
    public name: string;
    public roleGroupID: number;
    public roleGroup: RoleGroup;
}

class RoleGroup {
    public roleGroupID: number;
    public name: string;
}

export enum RoleEnum {
    Administrator = 1,
    FrontOffice,
    Customer,
    GIS,
    Viewer,
    Scheduler,
    Lead,
    Zanjero,
    FieldReportsReporter,
    FieldReportsCommenter,
    FieldReportsSupervisor,
    Scanner,
    ScanViewer,
    TimeSheetEmployee,
    Tracker,
    TrackingViewer,
    Maintenance,
    GasUser,
    WellUser,
    FieldReportsAdmin,
    TimeSheetAuthorizer,
    TimeSheetSupervisor,
    TimeSheetCloser
}

export enum RoleGroupEnum {
    Admin = 1,
    Customer,
    FieldReports,
    TimeSheets,
    FrontOffice,
    Other,
    Tracking
}
