import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { AuthService, RoleEnum } from 'src/services/auth/auth.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
    RoleEnum = RoleEnum;
    
    historyItems = [];

    historyItemModal: BsModalRef;
    dialogTitle = '';
    message = '';
    showLightbox = false;
    lightboxImageUrl = '';

    historyItemForm = new UntypedFormGroup({
        historyItemId: new UntypedFormControl(0),
        image: new UntypedFormControl('', [
            Validators.required
        ]),
        year: new UntypedFormControl('', [
            Validators.required,
            Validators.pattern('[0-9]*')
        ]),
        message: new UntypedFormControl(''),
    });

    file: File;

    @ViewChild('editItem')
    editItem: TemplateRef<any>;

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public bwDialogService: BwDialogService,
        public modalService: BsModalService
    ) { }

    getHistoryItems(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/historyItem/GetGroupedByYear';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }
    ngOnInit() {
        this.loadHistoryItems();
    }

    loadHistoryItems() {
        this.getHistoryItems().then((historyItems) => {
            this.historyItems = historyItems;
        });
    }

    openModal(template: TemplateRef<any>) {
        this.historyItemModal = this.modalService.show(template, {
            ignoreBackdropClick: true
        });
    }

    add() {
        this.dialogTitle = 'Add Entry';
        this.historyItemForm.setValue({
            historyItemId: 0,
            year: '',
            message: '',
            image: ''
        });
        this.openModal(this.editItem);
    }

    edit(historyItem) {
        this.dialogTitle = 'Edit Entry';
        this.historyItemForm.setValue({
            historyItemId: historyItem.historyItemId,
            year: historyItem.year,
            message: historyItem.message,
            image: ''
        });
        this.openModal(this.editItem);
    }

    moveUp(historyItem) {
        const url = this.auth.baseUrl + '/historyItem/moveUp';

        this.http.put(url, { historyItemId: historyItem.historyItemId }).subscribe((data) => {
            this.loadHistoryItems();
        }, err => {
        });
    }

    moveDown(historyItem) {
        const url = this.auth.baseUrl + '/historyItem/moveDown';

        this.http.put(url, { historyItemId: historyItem.historyItemId }).subscribe((data) => {
            this.loadHistoryItems();
        }, err => {
        });
    }

    delete(historyItem) {
        this.bwDialogService.showConfirm('Delete Entry?', 'Are you sure you want to delete this entry?')
            .subscribe(result => {
                if (result) {
                    const url = this.auth.baseUrl + '/historyItem';

                    this.http.delete(url + `/${historyItem.historyItemId}`).subscribe((data) => {
                        this.loadHistoryItems();
                    }, err => {
                    });
                }
            });
    }

    fileChanged(event) {
        this.file = event.target.files[0];
    }

    saveHistoryItem() {
        const url = this.auth.baseUrl + '/historyItem';

        if (this.historyItemForm.get('historyItemId').value > 0) {
            const formData = new FormData();

            if(this.file) {
                formData.append('file', this.file, this.file.name);
            }
            formData.append('historyItemId', this.historyItemForm.get('historyItemId').value);
            formData.append('year', this.historyItemForm.get('year').value);
            formData.append('message', this.historyItemForm.get('message').value);

            this.http.put(this.auth.baseUrl + '/historyItem', formData)
                .subscribe(event => {
                    this.loadHistoryItems();
                    this.historyItemModal.hide();
                }, error => {
                    this.message = error.statusText;
                });
        }
        else {
            const formData = new FormData();

            formData.append('file', this.file, this.file.name);
            formData.append('year', this.historyItemForm.get('year').value);
            formData.append('message', this.historyItemForm.get('message').value);

            this.http.post(this.auth.baseUrl + '/historyItem', formData)
                .subscribe(event => {
                    this.loadHistoryItems();
                    this.historyItemModal.hide();
                }, error => {
                    this.message = error.statusText;
                });
        }
    }

    get year() { return this.historyItemForm.get('year'); }

    showImage(fileName: string) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';

        this.showLightbox = true;
        this.lightboxImageUrl = this.auth.baseUrl + '/asset/about-us/' + fileName;
    }

    hideImage() {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        this.showLightbox = false;
    }
}
