import { Component, OnInit } from '@angular/core';
import { AuthService, RoleEnum, RoleGroupEnum } from 'src/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { BwDialogService } from '../bw-controls/bw-dialog.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    RoleEnum = RoleEnum;
    RoleGroupEnum = RoleGroupEnum;
    loginForm = this.fb.group({
        username: [
            '', [
                Validators.required,
            ]
        ],
        password: [
            '', {
                validators: [
                    Validators.required,
                ]
            }
        ],
    });

    forgotPasswordForm = this.fb.group({
        username: [
            '', [
                Validators.required,
            ]
        ]
    });

    forgotPasswordModal: BsModalRef;
    message: string;

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public router: Router,
        public route: ActivatedRoute,
        public fb: UntypedFormBuilder,
        public bsModal: BsModalService,
        public bwDialogService: BwDialogService
    ) { }

    ngOnInit() {
    }

    login() {
        this.auth.login(this.loginForm.get('username').value, this.loginForm.get('password').value)
            .then(result => {
                if(result.successful) {
                    if ((window as any).invokeCSharpAction) {
                        const invokeData = {
                            invokeFunction: 'Login',
                            data: {
                                userId: this.auth.userId,
                                isStaff: this.auth.isStaff,
                                authToken: this.auth.authToken
                            }
                        };
                        (window as any).invokeCSharpAction(JSON.stringify(invokeData));
                    }
    
                    var defaultPage = '/account/dashboard';

                    if(this.auth.checkRole(RoleEnum.GIS)) {
                        defaultPage = '/admin/board';
                    }

                    if(this.auth.checkRoleGroup(RoleGroupEnum.FieldReports)) {
                        defaultPage = '/admin/field-report';
                    }

                    if(this.auth.checkRole(RoleEnum.FrontOffice) || this.auth.checkRole(RoleEnum.Administrator)) {
                        defaultPage = '/admin/dashboard';
                    }
                    
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || defaultPage;
                        
                    this.router.navigateByUrl(returnUrl);
                }
                else {
                    this.message = result.message;
                }
            })
            .catch(errorMessage => this.message = errorMessage);
    }

    forgotPassword(forgotPasswordTemplate) {
        this.forgotPasswordModal = this.bsModal.show(forgotPasswordTemplate);
    }

    sendForgotPasswordEmail() {
        const url = this.auth.baseUrl + '/user/forgotPassword';
        this.http.get(url, { params: { username: this.forgotPasswordForm.get('username').value }}).subscribe(() => {
            this.bwDialogService.showAlert('Password Recovery', 'If we have your username in the system, you\'ll receive a message with a link to reset your password. If you don\'t see an email from us, check your spam or junk folder.');
        }, (error) => {
            this.bwDialogService.showAlert('Password Recovery', 'If we have your username in the system, you\'ll receive a message with a link to reset your password. If you don\'t see an email from us, check your spam or junk folder.');
        });
        this.forgotPasswordModal.hide();
    }

    get username() { return this.loginForm.get('username'); }
    get password() { return this.loginForm.get('password'); }

    get forgotPasswordUsername() { return this.forgotPasswordForm.get('username'); }
}
