import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL } from '../ApiClient.generated';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WaterRateService {

  private url: string;
  private headers: any = {
    headers: new HttpHeaders({
      "Accept": "application/json"
    })
  }

  constructor(public datePipe: DatePipe, private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.url = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:5000";
    this.url = this.url + "/api/waterrate";
    this.url = this.url.replace(/[?&]$/, "");
  }

  getPublicRates(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.url + "/GetPublicRates", this.headers).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
      })
    })
  }

}
