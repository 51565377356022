<section class="section bg-white">
    <div class="container text-center" ngsReveal>
        <h1>Development Services</h1>
        <i class="fas fa-phone mr-2"></i> 480-988-9586<br>
        <a href="mailto:info@rwcd.net"><i class="fas fa-envelope mr-2"></i>info&#64;rwcd.net</a><br>
    </div>
</section>

<section class="section bg-gray image-with-text">
    <div class="container">
        <div class="row">
            <!-- <div class="d-lg-none col-12 img-fluid mb-4" [ngsReveal]="{ delay: 100, viewFactor: 0 }" -->
            <div class="d-lg-none col-12 img-fluid mb-4"
                style="height: 400px; background-image: url('assets/Developer1.jpg'); background-position: center; background-size: cover;">
            </div>
            <div class="col-12 col-lg-6">
                <!-- <div class="text-left" [ngsReveal]="{ delay: 200, viewFactor: 0 }"> -->
                <div class="text-left">
                    <h1>
                        General Information
                        <button (click)="add()" class="btn btn-circle btn-success" *ngIf="auth.checkRole(RoleEnum.Administrator)">
                            <span class="fa fa-plus"></span>
                        </button>
                    </h1>
                    <div class="developers" *ngFor="let d of generalItems; let i = index">
                        <i class="far fa-file-pdf pdf-icon"></i><a href="" (click)="openFile(d)">{{d.name}}</a>
                        <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                            <button (click)="moveUp(d)" [disabled]="i == 0"
                                class="btn btn-circle btn-sm btn-success mr-1"><span
                                    class="fas fa-arrow-up"></span></button>
                            <button (click)="moveDown(d)" [disabled]="i == 0 - 1"
                                class="btn btn-circle btn-sm btn-success mr-1"><span
                                    class="fas fa-arrow-down"></span></button>
                            <button (click)="delete(d)" class="btn btn-circle btn-sm btn-danger"><span
                                    class="far fa-trash-alt"></span></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 col-lg-6 img-fluid d-none d-lg-block" [ngsReveal]="{ delay: 100, viewFactor: 0 }" -->
            <div class="col-12 col-lg-6 img-fluid d-none d-lg-block"
                style="height: 400px; background-image: url('assets/Developer1.jpg'); background-position: center; background-size: cover;">
            </div>
        </div>
    </div>
</section>

<section class="section bg-white image-with-text">
    <div class="container">
        <div class="row">
            <!-- <div class="col-12 d-lg-none img-fluid mb-4" [ngsReveal]="{ delay: 100, viewFactor: 0 }" -->
            <div class="col-12 d-lg-none img-fluid mb-4"
                style="height: 500px; background-image: url('assets/Developer2.jpg'); background-position: center;
                background-size: cover;">
            </div>
            <!-- <div class="col-12 d-none d-lg-block col-lg-6 img-fluid mb-4" [ngsReveal]="{ delay: 100, viewFactor: 0 }" -->
            <div class="col-12 d-none d-lg-block col-lg-6 img-fluid mb-4"
                style="height: 1400px; background-image: url('assets/Developer2.jpg'); background-position: center;
                background-size: cover;">
            </div>
            <div class="col-12 col-lg-6">
                <!-- <div class="text-left ml-lg-5" [ngsReveal]="{ delay: 100, viewFactor: 0 }"> -->

                <div class="text-left ml-lg-5">
                    <h1>RWCD Detail Book</h1>
                    <div class="developers" *ngFor="let d of detailBookItems; let i = index">
                        <i class="far fa-file-pdf pdf-icon"></i><a href="" (click)="openFile(d)">{{d.name}}</a>
                        <div *ngIf="auth.checkRole(RoleEnum.Administrator)" class="edit">
                            <button (click)="moveUp(d)" [disabled]="i == 0"
                                class="btn btn-circle btn-sm btn-success mr-1"><span
                                    class="fas fa-arrow-up"></span></button>
                            <button (click)="moveDown(d)" [disabled]="i == 0 - 1"
                                class="btn btn-circle btn-sm btn-success mr-1"><span
                                    class="fas fa-arrow-down"></span></button>
                            <button (click)="delete(d)" class="btn btn-circle btn-sm btn-danger"><span
                                    class="far fa-trash-alt"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #editItem>
    <form [formGroup]="developerItemForm" (ngSubmit)="saveDeveloperItem()">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{dialogTitle}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="developerItemModal.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <h4>File</h4>
                <input type="file" #file class="w-100" formControlName="file" (change)="fileChanged($event)">
            </div>
            <div>
                <h4>Category</h4>
                <select class="w-100" formControlName="developerCategory">
                    <option value="1">General Information</option>
                    <option value="2">RWCD Detail Book</option>
                </select>
            </div>
            <div class="pt-4">
                <h4>Name</h4>
                <input type="text" class="w-100" formControlName="name">
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-round float-right ml-1" type="submit">Save</button>
            <button class="btn btn-outline-secondary btn-round float-right" type="button"
                (click)="developerItemModal.hide()">Cancel</button>
        </div>
    </form>
</ng-template>