import { Component, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { BwDialogService } from 'src/app/bw-controls/bw-dialog.service';
import { ViewportScroller } from '@angular/common';
import { SettingService } from 'src/services/ApiClient.generated';

declare var paypal: any;

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements AfterViewInit {
    paymentInfo = {
        allowPayments: true,
        accountBalance: 0,
        placedOrders: 0,
        monthlyAverageUsage: 0,
        previousTransactions: []
    }

    payPalFixed: number;
    payPalPercent: number;

    actions;
    message;
    amount = '20.00';
    agree = false;
    paymentType = 'enter';
    customId;
    totalAmount = 20.00;

    barChartOptions = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return tooltipItem.yLabel + ' hours';
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    precision: 0,
                    // callback: function(value, index, values) {
                    //     return '$' + value;
                    // }
                }
            }]
        }
    };

    barChartLabels = [];
    barChartType = 'bar';
    barChartLegend = false;
    barChartData: any = [
        { data: [], label: 'Amount' },
    ];

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public bwDialogService: BwDialogService,
        public viewportScroller: ViewportScroller,
        public settingService: SettingService
    ) { }

    getPaymentInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/payment/GetPaymentInfo';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    getAmount() {
        switch (this.paymentType) {
            case 'pending':
                return this.paymentInfo.placedOrders;
            case 'average':
                return this.paymentInfo.monthlyAverageUsage;
            case 'enter':
                return parseFloat(this.amount || '0');
        }
    }

    updateTotal() {
        const amount = this.getAmount();
        this.totalAmount = (amount + this.payPalFixed) / (1 - this.payPalPercent);
    }

    validate() {
        var valid = true;

        if (!this.agree) {
            valid = false;
            this.message = 'You must agree to the processing fee.';
        }
        else if (this.auth.impersonated) {
            valid = false;
            this.message = 'Unable to submit payment while impersonated.';
        }

        if (valid) {
            document.querySelector('#error').classList.add('d-none');

            if (this.actions) {
                this.actions.enable();
            }
        } else {
            document.querySelector('#error').classList.remove('d-none');

            if (this.actions) {
                this.actions.disable();
            }
        }
    }

    ngAfterViewInit() {
        this.settingService.getSettings().subscribe(settings => {
            this.payPalFixed = settings.payPalFixed;
            this.payPalPercent = settings.payPalPercent;
            this.updateTotal();
        });

        this.loadTransactions();
        const t = this;
        paypal.Buttons({
            env: 'sandbox',
            client: {
                sandbox: 'xxxxxxxxx',
                production: 'xxxxxxxxx'
            },

            style: {
                shape: 'pill'
            },

            onInit: function (data, actions) {
                t.actions = actions;

                document.querySelector('#agree').addEventListener('change', t.validate.bind(t));
                document.querySelector('#pending').addEventListener('change', t.validate.bind(t));
                document.querySelector('#average').addEventListener('change', t.validate.bind(t));
                document.querySelector('#payment').addEventListener('change', t.validate.bind(t));
                document.querySelector('#amount').addEventListener('change', t.validate.bind(t));

                t.validate();
            },

            createOrder: function (data, actions) {
                t.customId = t.auth.accountId.toString() + ':' + Math.floor(Math.random() * 100000000000);

                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: t.totalAmount.toFixed(2)
                        },
                        custom_id: t.customId
                    }]
                });
            },

            onClick: function () {
                t.validate();
            },

            onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                    console.log(details);
                    t.addPendingPayment();
                });
            },

            onCancel: function (data) {
                t.bwDialogService.showAlert('Payment Cancelled', 'The payment was cancelled.');
            }
        }).render('#paypal-button-container');
    }

    addPendingPayment() {
        this.http.post(this.auth.baseUrl + '/payment/AddPendingPayment', { amount: this.getAmount(), customId: this.customId })
            .subscribe(() => {
                this.loadTransactions();
            });

        this.viewportScroller.scrollToAnchor('Payments');
        this.bwDialogService.showAlert('Payment Received', 'Please note: It may take a few minutes for your payment to appear on your account.');
        
    }

    loadTransactions() {
        this.getPaymentInfo().then((paymentInfo) => {
            this.paymentInfo = paymentInfo;
            this.barChartLabels = paymentInfo.history.map(x => x.monthName);
            this.barChartData[0].data = paymentInfo.history.map(x => x.hours);
        });
    }
}
