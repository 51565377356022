<div class="sticky-nav" [class.sticky]="sticky">
    <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container">
            <div class="navbar-brand mr-auto social-links">
                <a routerLink="/home">RWCD</a>
                <div class="d-inline d-lg-none d-xl-inline">
                    <a href="http://www.facebook.com/rwcdaz" target="_blank" class="ml-4 mr-2">
                        <i class="fa-brands fa-facebook"></i>
                    </a>
                    <a href="http://www.twitter.com/rwcdaz" target="_blank" class="m-2">
                        <i class="fa-brands fa-x-twitter"></i>
                    </a>
                    <a href="http://www.instagram.com/rwcdaz" target="_blank" class="m-2">
                        <i class="fa-brands fa-instagram"></i>
                    </a>
                </div>
            </div>
            <button class="navbar-toggler" type="button" (click)="toggleMenu()"
                [attr.aria-expanded]="!isCollapsed" aria-controls="navbarNavDropdown" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" [collapse]="isCollapsed">
                <div class="menus">
                    <ul class="navbar-nav ml-none ml-lg-auto">
                        <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" routerLink="/home">Home</a>
                        </li>
                        <li class="nav-item dropdown" dropdown routerLinkActive="active">
                            <a class="nav-link" href="" (click)="doNothing()" dropdownToggle>Customers</a>
                            <div class="dropdown-menu" *dropdownMenu>
                                <a *ngIf="!(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/login" [queryParams]="{ returnUrl: '/account/order' }">Order Water</a>
                                <a *ngIf="!(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/login" [queryParams]="{ returnUrl: '/account/payment' }">Make Payments</a>
                                <a *ngIf="!(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/login" [queryParams]="{ returnUrl: '/account/history' }">Account History</a>
                                <a *ngIf="!(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/login" [queryParams]="{ returnUrl: '/account/profile' }">Update Profile</a>

                                <a *ngIf="(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/account/order">Order Water</a>
                                <a *ngIf="(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/account/payment">Make Payments</a>
                                <a *ngIf="(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/account/history">Account History</a>
                                <a *ngIf="(auth.isAuthenticated | async)" class="dropdown-item" routerLink="/account/profile">Update Profile</a>
                                
                                <a class="dropdown-item" routerLink="/manual">Information Manual</a>
                                <a class="dropdown-item" routerLink="/new-account">Open Water Account</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown" dropdown routerLinkActive="active">
                            <a class="nav-link" href="" (click)="doNothing()" dropdownToggle>Directors</a>
                            <div class="dropdown-menu" *dropdownMenu>
                                <a class="dropdown-item" routerLink="/meetings">Meetings</a>
                                <a class="dropdown-item" routerLink="/directors">Board of Directors</a>
                                <a class="dropdown-item" routerLink="/voters">Election</a>
                            </div>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" routerLink="/developers">Developers</a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" routerLink="/about-us">About</a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" routerLink="/contact-us">Contact</a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" routerLink="/map">Map</a>
                        </li>
                        <li class="nav-item dropdown" dropdown routerLinkActive="active">
                            <a class="nav-link" href="" (click)="doNothing()" dropdownToggle>FAQs</a>
                            <div class="dropdown-menu" *dropdownMenu>
                                <a class="dropdown-item" routerLink="/faqs">FAQs</a>
                                <a class="dropdown-item" routerLink="/rates">Water Rates</a>
                            </div>
                        </li>
                        <li class="nav-item d-block d-lg-none">
                            <a class="nav-link" routerLink="/login"
                                *ngIf="!(auth.isAuthenticated | async)">Login</a>
                        </li>
                    </ul>
                    <app-nav-app class="ml-auto menu-right d-lg-none" *ngIf="!isCollapsed"></app-nav-app>                
                    <button class="btn btn-success btn-round ml-lg-4 d-none d-lg-block" routerLink="/login"
                        *ngIf="!(auth.isAuthenticated | async)">Login</button>
                    <div class="dropdown" dropdown *ngIf="(auth.isAuthenticated | async)">
                        <button class="btn btn-success btn-circle ml-lg-4 d-none d-lg-flex" dropdownToggle>
                            <i class="fas fa-user mr-2 login-icon"></i>
                            <div>
                                <div class="login-name text-left">
                                    {{auth.lastName}}
                                </div>
                                <div class="login-account text-left">
                                    {{auth.isStaff ? 'Staff' : '#' + auth.accountId}}
                                </div>
                            </div>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink"
                            *dropdownMenu>
                            <a class="dropdown-item" routerLink="/account"
                                *ngIf="!auth.isStaff">Dashboard</a>
                            <a class="dropdown-item" routerLink="/admin"
                                *ngIf="auth.isStaff">Dashboard</a>
                            <a class="dropdown-item" routerLink="/account/profile" *ngIf="!auth.checkRole(RoleEnum.GIS)">Profile</a>
                            <a class="dropdown-item" routerLink="/logout" *ngIf="!auth.impersonated">Logout</a>
                            <a class="dropdown-item" routerLink="" *ngIf="auth.impersonated" (click)="auth.unimpersonate()">Unimpersonate</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light sticky-account d-none d-lg-block" *ngIf="auth.isAuthenticated.value">
        <app-nav-app></app-nav-app>
    </nav>
</div>

<section class="hero-video">
    <div class="overlay">
    </div>
</section>

<button class="btn btn-danger ml-lg-4 emergency" *ngIf="!auth.checkRole(RoleEnum.FrontOffice) && !auth.checkRole(RoleEnum.Administrator)" (click)="openModal(emergency)">
    Emergency?
</button>

<ng-template #emergency>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Have a Water Emergency?</h4>
    </div>
    <div class="modal-body">
        <ul>
            <li>Flooding?</li>
            <li>Low water or no water?</li>
            <li>See something? Please let us know.</li>
        </ul>
        <hr style="height: 1px;" />
        <div class="text-center">
            <p>Call the 24 hour number below.<br>
                Please leave a message if there is no answer.</p>
            <h4><a href="tel:480-988-9581">480-988-9581</a></h4>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-round btn-success pull-right" (click)="dismissMessage()">Close</button>
    </div>
</ng-template>