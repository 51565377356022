import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderComponent, ConfirmDeactivateGuard } from './order/order.component';
import { PaymentComponent } from './payment/payment.component';
import { HistoryComponent } from './history/history.component';
import { BoardInfoComponent } from './board-info/board-info.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ProfileComponent } from './profile/profile.component';
import { DirectoryComponent } from './directory/directory.component';
import { AuthGuardService } from 'src/services/auth-guard/auth-guard.service';

const routes: Routes = [
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService], data: { role: 'Customer' } },
    { path: 'order', component: OrderComponent, canActivate: [AuthGuardService], data: { role: 'Customer' }, canDeactivate: [ConfirmDeactivateGuard] },
    { path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService], data: { role: 'Customer' } },
    { path: 'history', component: HistoryComponent, canActivate: [AuthGuardService], data: { role: 'Customer' } },
    { path: 'board-info', component: BoardInfoComponent, canActivate: [AuthGuardService], data: { role: 'Customer' } },
    { path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuardService], data: { role: 'Customer' } },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService], data: { role: ['Customer', 'Staff'] } },
    { path: 'directory', component: DirectoryComponent, canActivate: [AuthGuardService], data: { role: 'Customer' } },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    ConfirmDeactivateGuard,
    AuthGuardService
  ]
})
export class AccountRoutingModule { }
