import { Component, OnInit } from '@angular/core';
import { WaterRateService } from 'src/services/water-rate/water-rate.service';

@Component({
    selector: 'app-rates',
    templateUrl: './rates.component.html',
    styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {

    agriculturalRate: number = 0.0;
    boardRate: number = 0.0;
    turfRate: number = 0.0;
    specializedTurfRate: number = 0.0;

    constructor(
        public waterRateService: WaterRateService
    ) { }

    async ngOnInit() {
        await this.waterRateService.getPublicRates().then(waterRates => {
            this.agriculturalRate = waterRates.filter(x => x.cardType === 'F')[0].rate;
            this.boardRate = waterRates.filter(x => x.cardType === 'B')[0].rate;
            this.turfRate = waterRates.filter(x => x.cardType === 'T')[0].rate;
            this.specializedTurfRate = waterRates.filter(x => x.cardType === 'P')[0].rate;
        });
    }

}
