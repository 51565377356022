import { Component, OnInit, TemplateRef, ViewChild, Injectable, HostListener } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Subject } from 'rxjs';
import { CreateLargeOrderDto, HeadSizeDto, LargeCardDto, LargeOrderScheduleDto, LargeOrderScheduleListDto, OrderService } from 'src/services/ApiClient.generated';
import { BwDialogService } from 'src/app/bw-controls/bw-dialog.service';

@Injectable()
export class ConfirmDeactivateGuard  {
    canDeactivate(target: OrderComponent) {
        if (target.hasChanges()) {
            target.openModal(target.confirmSave);
            return target.navigateAwaySelection$;
        }
        return true;
    }
}

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

    @HostListener('window:beforeunload', ['$event'])
    handleBeforeUnload($event: any) {
        if (this.hasChanges()) {
            $event.returnValue = true;
        }
    }

    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

    hours = [...Array(31).keys()];
    saving = false;

    bulkEditData = {
        hours: 0,
        minutes: 0
    };

    agricultrualRate: number = 0.0;

    largeOrderData = {
        largeOrderId: 0,
        continuous: false,
        hours: 1,
        minutes: 0,
        largeCardId: 0,
        lateralText: '',
        head: 0,
        comments: '',
        confirmed: false,
        hoursError: function () {
            if (isNaN(this.hours)) {
                return 'Hours must be a valid number.'
            }

            if (this.hours > 250) {
                return 'Hours must be less than 250.'
            }

            return '';
        },
        isValid: function () {
            return this.hoursError() === '' && this.confirmed;
        }
    };

    allowOrders = true;
    orderSchedules = [];
    largeOrderSchedule: LargeOrderScheduleDto;
    largeOrderSchedules: LargeOrderScheduleListDto[] = [];
    boardOrders = [];
    headSize = 0;
    rate = 0.0;
    headSizes: HeadSizeDto[];
    largeCards: LargeCardDto[];

    modalRef: BsModalRef;
    messageRef: BsModalRef;

    @ViewChild('saved')
    saved: TemplateRef<any>;

    @ViewChild('largeSaved')
    largeSaved: TemplateRef<any>;

    @ViewChild('largeOrderDialog')
    largeOrderDialog: TemplateRef<any>;

    @ViewChild('error')
    error: TemplateRef<any>;

    @ViewChild('noChanges')
    noChanges: TemplateRef<any>;

    @ViewChild('confirmSave')
    confirmSave: TemplateRef<any>;

    constructor(
        public auth: AuthService,
        public http: HttpClient,
        public modalService: BsModalService,
        public orderService: OrderService, 
        public bwDialogService: BwDialogService
    ) { }

    getOrderSchedules(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.auth.baseUrl + '/order/getOrderSchedules';

            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    toggleOrders(orderSchedule: any) {
        this.boardOrders = [];
        const url = this.auth.baseUrl + '/board/getBoardOrders';

        if (orderSchedule.showOrders) {
            orderSchedule.showOrders = false;
        }
        else {
            orderSchedule.showOrders = true;

            this.http.get(url, { params: { postDate: orderSchedule.postDate.toString() } }).subscribe((data: any) => {
                orderSchedule.orders = data;
            });
        }
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        if (this.auth.isLarge) {
            this.orderService.largeCards().subscribe(data => {
                this.largeCards = data;

                if (this.largeCards.length > 0) {
                    this.largeOrderData.largeCardId = this.largeCards[0].cardId;
                    this.largeOrderData.lateralText = this.largeCards[0].lateralText;
                }
            });

            this.orderService.headSizes().subscribe(data => {
                this.headSizes = data

                if (this.headSizes.length > 0) {
                    this.largeOrderData.head = this.headSizes[0].name;
                }
            });

            this.orderService.getLargeOrderSchedules().subscribe(data => {
                this.allowOrders = data.allowOrders;
                this.agricultrualRate = data.agriculturalRate;
                this.largeOrderSchedules = data.largeOrderSchedules;
            });
        }
        else {
            this.getOrderSchedules().then((data) => {
                this.allowOrders = data.allowOrders;
                this.headSize = data.headSize;
                this.rate = data.rate;

                if (data.orderSchedules) {
                    data.orderSchedules.forEach(o => {
                        o.originalHours = o.hours;
                        o.originalMinutes = o.minutes;
                        o.showOrders = false;
                        o.orders = [];
                    });
                }

                this.orderSchedules = data.orderSchedules;
            });
        }
    }

    resetTime(orderSchedule) {
        orderSchedule.hours = 0;
        orderSchedule.minutes = 0;
        orderSchedule.amount = this.calculateAmount(orderSchedule.hours + orderSchedule.minutes / 60);

        return false;
    }

    selectHours(orderSchedule, hours) {
        orderSchedule.hours = hours;
        orderSchedule.amount = this.calculateAmount(orderSchedule.hours + orderSchedule.minutes / 60);

        return false;
    }

    selectMinutes(orderSchedule, minutes) {
        orderSchedule.minutes = minutes;
        orderSchedule.amount = this.calculateAmount(orderSchedule.hours + orderSchedule.minutes / 60);

        return false;
    }

    calculateAmount(hours: number) {
        const calc = (this.headSize / 40.0) * 1.9835 * (hours / 24.0);
        return +calc.toFixed(2) * this.rate;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    hasChanges(): boolean {
        return this.orderSchedules.filter(x => x.originalHours !== x.hours || x.originalMinutes !== x.minutes).length > 0;
    }

    bulkUpdate() {
        for (var orderSchedule of this.orderSchedules) {
            if (orderSchedule.canChangeOrder) {
                if (orderSchedule.hours !== this.bulkEditData.hours) {
                    orderSchedule.hours = this.bulkEditData.hours;
                }
                if (orderSchedule.minutes !== this.bulkEditData.minutes) {
                    orderSchedule.minutes = this.bulkEditData.minutes;
                }
            }
        }

        this.modalRef.hide();
    }

    save() {
        this.saving = true;

        let updateOrders = this.orderSchedules.filter(x => x.originalHours != x.hours || x.originalMinutes != x.minutes);

        if (updateOrders.length > 0) {
            const url = this.auth.baseUrl + '/order/updateOrderSchedules';

            this.http.put(url, updateOrders).subscribe((data) => {
                this.saving = false;
                this.messageRef = this.modalService.show(this.saved);
                this.loadData();

            }, err => {
                this.saving = false;
                this.messageRef = this.modalService.show(this.error);
            });
        }
        else {
            this.saving = false;
            this.messageRef = this.modalService.show(this.noChanges);
        }
    }

    dismissMessage() {
        this.messageRef.hide();
    }

    chooseConfirmSave(choice: boolean) {
        this.modalRef.hide();
        this.navigateAwaySelection$.next(choice);
    };

    selectLocation(largeCard: LargeCardDto) {
        this.largeOrderData.largeCardId = largeCard.cardId;
        this.largeOrderData.lateralText = largeCard.lateralText;
        return false;
    }

    selectHeadSize(headSize: number) {
        this.largeOrderData.head = headSize;
        return false;
    }

    selectLargeMinutes(minutes: number) {
        this.largeOrderData.minutes = minutes;
        return false;
    }

    saveLargeOrder() {
        this.modalRef.hide();

        const largeOrder = new CreateLargeOrderDto({
            largeOrderId: this.largeOrderData.largeOrderId,
            largeCardId: this.largeOrderData.largeCardId,
            head: this.largeOrderData.head,
            continuous: this.largeOrderData.continuous,
            hours: this.largeOrderData.hours,
            minutes: this.largeOrderData.minutes,
            comments: this.largeOrderData.comments
        });

        if (this.largeOrderData.largeOrderId == 0) {
            this.orderService.createLargeOrder(largeOrder).subscribe(x => {
                this.messageRef = this.modalService.show(this.largeSaved);
                this.loadData();
                this.largeOrderData.confirmed = false;
                this.largeOrderData.largeOrderId = 0;
            }, err => {
                this.messageRef = this.modalService.show(this.error);
            });
        }
        else {
            this.orderService.updateLargeOrder(largeOrder).subscribe(x => {
                this.messageRef = this.modalService.show(this.largeSaved);
                this.loadData();
                this.largeOrderData.confirmed = false;
                this.largeOrderData.largeOrderId = 0;
            }, err => {
                this.messageRef = this.modalService.show(this.error);
            });
        }
    }

    editLargeOrder(largeOrder) {
        this.largeOrderData.largeOrderId = largeOrder.largeOrderId;
        this.largeOrderData.largeCardId = largeOrder.largeCardId;
        this.largeOrderData.lateralText = largeOrder.lateralText;
        this.largeOrderData.head = largeOrder.head;
        this.largeOrderData.continuous = largeOrder.continuous;
        this.largeOrderData.hours = largeOrder.hours;
        this.largeOrderData.minutes = largeOrder.minutes;
        this.largeOrderData.comments = largeOrder.comments;
        this.largeOrderData.confirmed = false;

        this.openModal(this.largeOrderDialog);
    }

    deleteLargeOrder(largeOrder) {
        this.bwDialogService.showConfirm('Delete Order?', 'Are you sure you want to delete this order?')
            .subscribe(result => {
                if (result) {
                    this.orderService.deleteLargeOrder(largeOrder.largeOrderId).subscribe(x => {
                        this.loadData();
                    }, err => {
                        this.messageRef = this.modalService.show(this.error);
                    });
                }
            });
    }
}
