import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { BwMatchOtherValidator } from '../bw-controls/bw-match-other-validator';
import { UserService } from 'src/services/user/user.service';
import { BwDialogService } from '../bw-controls/bw-dialog.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  message: string;
  fragment: string;

    registerForm = this.fb.group({
        token: '',
        password: [
            '', {
                validators: [
                    Validators.required,
                    Validators.minLength(4)
                ],
            }
        ],
        confirmPassword: [
            '', {
                validators: [
                    Validators.required,
                    Validators.minLength(4),
                    BwMatchOtherValidator('password')
                ],
            }
        ],
    });

    constructor(
        public fb: UntypedFormBuilder,
        public userService: UserService,
        public route: ActivatedRoute,
        public bwDialogService: BwDialogService
    ) { }

    ngOnInit() {
      this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    }
    
    get password() { return this.registerForm.get('password'); }
    get confirmPassword() { return this.registerForm.get('confirmPassword'); }

    resetPassword() {
        this.registerForm.get('token').setValue(this.fragment);
        this.userService.resetPassword(this.registerForm.value)
            .subscribe(data => {
              this.bwDialogService.showAlert('Password Reset', 'Your password has been reset. You may now log in with your new password.');
            }, err => {
                if(err.error) {
                    this.message = err.error;
                }
                else {
                    this.message = 'An unknown error has occurred.';
                }
            });
    }
}
