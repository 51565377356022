<section class="section bg-white">
    <div class="container">
        <div class="row" ngsReveal>
            <div class="col-12 col-lg-8 mb-4">
                <h1>Visit in Person</h1>
                <iframe frameborder="0" height="560" src="https://maps.google.com/maps?q=Roosevelt%20Water%20Conservation%20District%2C%20Mesa%2C%20AZ%2085212&t=&z=13&ie=UTF8&iwloc=&output=embed" style="border: 0;" width="100%"></iframe>
            </div>
            <!-- <div class="col-12 col-lg-4" [ngsRevealSet]="{ delay: 100, viewFactor: 0 }" [ngsSelector]="'article'" [ngsInterval]="100"> -->
            <div class="col-12 col-lg-4">
                <h1>Information</h1>
                <article class="contact">
                    <h3>Office Hours</h3>
                    Monday - Thursday, 8am – 5pm<br>
                    Friday, 8am - 12pm
                    <a routerLink="/faqs" class="btn btn-round btn-success mt-4 w-100">View FAQ's</a>
                </article>
                <article class="contact">
                    <h3>Physical Address</h3>
                    6937 E Elliot Rd.<br>
                    Mesa, AZ 85212
                    
                    <h3>Mailing Address</h3>
                    PO Box 100<br>
                    Higley, AZ 85236-0100
                </article>
                <article class="contact">
                    <h3>Administration</h3>
                    480-988-9581<br>
                    <h3>Fax</h3>
                    480-988-9589<br>
                    <h3>Water Department</h3>
                    480-988-9581 (24 hours)
                </article>
            </div>
        </div>
    </div>
</section>