import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountRoutingModule } from './account-routing.module';
// import { NgsRevealModule } from 'ngx-scrollreveal';
import { OrderComponent } from '../account/order/order.component';
import { PaymentComponent } from '../account/payment/payment.component';
import { HistoryComponent } from '../account/history/history.component';
import { BoardInfoComponent } from '../account/board-info/board-info.component';
import { ScheduleComponent } from '../account/schedule/schedule.component';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgChartsModule } from 'ng2-charts';
import { AddContactComponent } from './add-contact/add-contact.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { DirectoryComponent } from './directory/directory.component';
import { UserService, ContactService, OrderService, ScheduleService, TransactionService } from 'src/services/ApiClient.generated';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
    imports: [
        CommonModule,
        AccountRoutingModule,
        // NgsRevealModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        NgChartsModule,
        ButtonsModule.forRoot(),
    ],
    declarations: [
        DashboardComponent,
        OrderComponent,
        PaymentComponent,
        HistoryComponent,
        BoardInfoComponent,
        ScheduleComponent,
        ProfileComponent,
        AddContactComponent, 
        DirectoryComponent,
        NotificationsComponent
    ],
    providers: [
        UserService,
        ContactService,
        OrderService,
        ScheduleService,
        TransactionService
    ]
})
export class AccountModule { }
