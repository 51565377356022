<section class="section bg-white">
    <div class="container text-center">
        <h1 class="pb-5">Water Rates</h1>

        <article class="col-sm list">
            <div class="row no-gutters header sticky-header">
                <div class="col-6 text-left">Service</div>
                <div class="col-6 text-right">Amount</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 text-left">New Account Setup Charge</div>
                <div class="col-6 text-right">$7.00</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 text-left">Monthly Account Service Charge</div>
                <div class="col-6 text-right">$7.00</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 text-left">Agricultural Irrigation Users</div>
                <div class="col-6 text-right">{{agriculturalRate | currency}}/AF</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 text-left">Subdivision Irrigation Users (Boards)</div>
                <div class="col-6 text-right">{{boardRate | currency}}/AF</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 text-left">Standard Turf Irrigation Users</div>
                <div class="col-6 text-right">{{turfRate | currency}}/AF</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 text-left">Specialized Turf Irrigation Users*<br>*Annual Contract Administration Charge</div>
                <div class="col-6 text-right">{{specializedTurfRate | currency}}/AF<br>$3,000.00</div>
            </div>
        </article>
    </div>
</section>